// Setup Theme MaterialUI
import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
  status: {
    error: '#942323',
    danger: '#942323',
    success: '',
  },
  palette: {
    primary: {
        main: '#239497',
        dark: '#165e61',
    },
    blue: {
        main: '#239497',
        dark: '#165e61',
        contrastText: '#fff',
    },
    neutral: {
      main: '#E9E9E9',
      contrastText: '#fff',
    },
    light: {
        main: '#DEDEDE',
        dark: '#CACACA'
    },
    white: {
      main: '#FFFFFF',
      dark: '#E9F5F5',
    },
    grey: {
      main: "#AFAFAF",
      dark: "#868686",
      light: '#DEDEDE'
    },
    darkgrey: {
      main: "#868686",
    },
    whiteBlue: {
      main: '#E9F5F5',
    },
    black: {
      main: '#000000',
    },
    dark: {
      main: "#071B2E",
    },
    error: {
      main: '#942323',
    }, 
    premium: {
      main: '#F46B45',
      dark: '#EEA849',
      disabled: '#E9E9E9'
    },
    whiteOrange:{
      main: '#F56E4630',
    },
    yellowDot: {
      main:'#F0D62B',
    },
    orangeDot: {
      main:'#F77037',
    },
    redDot: {
      main:'#F21818',
    },
    redDotDark: {
      main:'#B00000',
    }
  },
});
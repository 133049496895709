import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMutation, useQuery } from "@tanstack/react-query";

// Test dataset
import { historySearch } from "../../../misc/dataset";
import { neutralizeBack, revivalBack } from "../../../misc/navigation";

import { searchProduct, fetchProduct } from "../../../api/search";
import { getUser } from "../../../api/user";
import { fetchStatsImput, fetchTypesImput } from "../../../api/products";
import FicheProduit from "../../sharedComponents/FicheProduit";
import FicheProduitVigicare from "../../sharedComponents/FicheProduitVigicare";

const cleanType = (type) => {
  switch (type) {
    case "Dispositif médical":
      return "dispositif";
    case "Cosmétique":
      return "cosmetique";
    case "Complément alimentaire":
      return "complement";
    case "Médicament":
      return "medicament";
    default:
      return "m";
  }
};

class ProductSearch extends React.Component {
  constructor(props) {
    super(props);
    if (props.data !== undefined && Object.keys(props.data).length !== 0) {
      this.state = {
        data: {
          connected: props.data.connected,
          dateFin:
            props.data.userdata.dateFin !== undefined
              ? props.data.userdata.dateFin
              : "",
          premium:
            props.data.userdata.dateFin !== undefined &&
            props.data.userdata.dateFin !== null
              ? true
              : false,
          pro:
            props.data.userdata.pro !== undefined
              ? props.data.userdata.pro
              : false,
          historySearch: historySearch,
          productDisplay: false,
          product: null,
          productName: "",
        },
        searchText: "",
        displayText: "",
        searchResults: null,
        searchError: null,
        searchResultsVigicare: null,
        navBack: false,
        vigicare: true,
      };
    } else {
      this.state = {
        data: {
          connected: true,
          historySearch: historySearch,
          productDisplay: false,
          product: null,
          productName: "",
        },
        searchText: "",
        displayText: "",
        searchResults: null,
        searchError: null,
        searchResultsVigicare: null,
        navBack: false,
        vigicare: true,
      };
    }
    window.scrollTo(0, 0);

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchVigicare = this.handleSearchVigicare.bind(this);
    this.showProduct = this.showProduct.bind(this);
    this.showProductVigicare = this.showProductVigicare.bind(this);
    this.hideProduct = this.hideProduct.bind(this);
  }

  componentDidUpdate(pP, pS) {
    if (pP.data.userdata !== this.props.data.userdata) {
      if (
        this.props.data.userdata.pro !== true ||
        this.props.data.userdata.dateFin === null
      ) {
        this.props.navigation("/");
      } else {
        this.setState({
          data: {
            connected: this.props.data.connected,
            dateFin:
              this.props.data.userdata.dateFin !== undefined
                ? this.props.data.userdata.dateFin
                : "",
            premium:
              this.props.data.userdata.dateFin !== undefined &&
              this.props.data.userdata.dateFin !== null
                ? true
                : false,
            pro:
              this.props.data.userdata.pro !== undefined
                ? this.props.data.userdata.pro
                : false,
            historySearch: historySearch,
            productDisplay: false,
            product: null,
          },
        });
      }
    }
    if (
      this.props.mutationSearchVigicare.isSuccess &&
      this.state.searchResultsVigicare === null &&
      !this.state.navBack
    ) {
      this.setState({
        searchResultsVigicare: this.props.mutationSearchVigicare.data,
        displayText: this.state.searchText,
      });
    }
    if (
      this.props.mutationSearchVigicare.isError &&
      this.props.mutationSearch.isError
    ) {
      if (
        this.state.searchError !== "Aucun produit n'est reconnu sous ce nom."
      ) {
        this.setState({
          searchError: "Aucun produit n'est reconnu sous ce nom.",
        });
      }
    } else {
      if (
        this.props.mutationSearchVigicare.isError &&
        this.state.searchError === null &&
        !this.state.navBack
      ) {
        this.setState({
          searchError:
            "Aucun produit n'a été déclaré par un utilisateur sous ce nom.",
        });
      }
      if (
        this.props.mutationSearch.isError &&
        this.state.searchError === null &&
        !this.state.navBack
      ) {
        this.setState({
          searchError:
            "Aucun produit n'est reconnu sous ce nom dans la base de données VigiAccess.",
        });
      }
    }

    if (
      this.props.mutationSearch.isSuccess &&
      this.state.searchResults === null &&
      !this.state.navBack
    ) {
      this.setState({
        searchResults: this.props.mutationSearch.data,
      });
    }

    if (
      this.props.mutationSearchVigicare.isLoading &&
      !this.props.mutationSearch.isError &&
      (this.state.searchError !== null ||
        this.state.searchResultsVigicare !== null)
    ) {
      this.setState({
        searchError: null,
        searchResultsVigicare: null,
      });
    }
    if (
      this.props.mutationSearch.isLoading &&
      !this.props.mutationSearchVigicare.isError &&
      (this.state.searchError !== null || this.state.searchResults !== null)
    ) {
      this.setState({
        searchError: null,
        searchResults: null,
      });
    }
    if (
      !pP.mutationProduct.isSuccess &&
      this.props.mutationProduct.isSuccess &&
      this.state.data.product === null &&
      !this.state.navBack &&
      this.state.data.productName !== ""
    ) {
      this.setState(
        {
          data: {
            ...this.state.data,
            product: this.props.mutationProduct.data,
            productDisplay: true,
          },
        },
        () => neutralizeBack(this.hideProduct),
      );
    }
    if (
      !pP.mutationProductVigicare.isSuccess &&
      this.props.mutationProductVigicare.isSuccess &&
      this.state.data.product === null &&
      !this.state.navBack &&
      this.state.data.productName !== ""
    ) {
      this.setState(
        {
          data: {
            ...this.state.data,
            product: this.props.mutationProductVigicare.data,
            productDisplay: true,
          },
        },
        () => neutralizeBack(this.hideProduct),
      );
    }
    if (
      (this.props.mutationProduct.isError ||
        this.props.mutationProductVigicare.isError) &&
      this.state.data.productName !== "" &&
      this.state.searchError !== null &&
      this.state.searchError !== ""
    ) {
      this.setState(
        {
          data: {
            ...this.state.data,
            productDisplay: false,
            product: null,
            productName: "",
          },
          searchText: "",
          searchError: "Erreur de récupération des données du produit",
          searchResults: null,
          searchResultsVigicare: [],
          navBack: false,
        },
        revivalBack,
      );
    }
  }

  showProduct(prod, i) {
    const { data } = this.state;
    this.props.mutationProduct.mutate({ code: prod.Code });
    this.setState({
      data: { ...data, productName: prod.Value },
      navBack: false,
      vigicare: false,
    });
  }

  showProductVigicare(prod, i) {
    const { data } = this.state;
    this.props.mutationProductVigicare.mutate({
      type: cleanType(prod.type),
      produit: this.state.displayText,
    });
    this.setState({
      data: { ...data, productName: this.state.displayText },
      navBack: false,
      vigicare: true,
    });
  }

  hideProduct() {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          productDisplay: false,
          product: null,
          productName: "",
        },
        searchText: "",
        searchError: null,
        searchResults: null,
        searchResultsVigicare: [],
        navBack: true,
      },
      revivalBack,
    );
  }

  handleChange(ev) {
    if (ev.target.value !== undefined)
      this.setState({ searchText: ev.target.value });
  }

  handleSearch(searchText) {
    this.props.mutationSearch.mutate({ search: searchText });
    this.props.mutationSearchVigicare.mutate(searchText);
    this.setState({ navBack: false });
  }

  handleSearchVigicare(searchText) {
    this.props.mutationSearchVigicare.mutate(searchText);
    this.setState({ navBack: false });
  }
  render() {
    const {
      data,
      searchText,
      displayText,
      searchResults,
      searchError,
      searchResultsVigicare,
      vigicare,
    } = this.state;

    const { t } = this.props;

    return (
      <>
        {data.productDisplay ? (
          <>
            {!vigicare ? (
              <FicheProduit data={data} handler={this.hideProduct} />
            ) : (
              <FicheProduitVigicare data={data} handler={this.hideProduct} />
            )}
          </>
        ) : (
          <>
            <Grid className="homeRow">
              <p className="homeSecondTitle">{t("productSearch.title")}</p>
            </Grid>
            <Grid sx={{ textAlign: "end", fontSize: "12px" }}>
              {t("productSearch.dataBy")}{" "}
              {data.pro ? "WHO Global ICSR database - VigiBase" : "Vigicare"}
            </Grid>
            <Grid className="searchRow">
              <TextField
                fullWidth
                disabled={data.pro && !data.premium}
                label={t("productSearch.inputLabel")}
                id="searchText"
                type="text"
                onChange={this.handleChange}
                color="premium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="grey" />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                disabled={searchText === ""}
                onClick={() => {
                  data.pro
                    ? this.handleSearch(searchText)
                    : this.handleSearchVigicare(searchText);
                }}
                variant="contained"
                color="premium"
                sx={{
                  textTransform: "none !important",
                  marginLeft: "0.5em",
                  color: "white !important",
                  fontSize: "12px",
                  minWidth: "10%",
                }}
              >
                {t("productSearch.search")}
              </Button>
            </Grid>
            {searchError}
            {searchResults !== null && searchResults.length !== undefined ? (
              <Grid>
                <p>{t("productSearch.results.selectActiveSubstance")}</p>{" "}
                {searchResults.map((element, i) => (
                  <div key={i}>
                    <Button onClick={() => this.showProduct(element)}>
                      {element.Value}
                    </Button>
                  </div>
                ))}
              </Grid>
            ) : null}
            {searchResultsVigicare !== null &&
            searchResultsVigicare.length !== undefined &&
            searchResultsVigicare.length > 0 ? (
              <Grid>
                <p>{t("productSearch.results.title")}</p>
                {searchResultsVigicare.map((element, i) => (
                  <div key={i}>
                    <b>{t(`app:common.productTypeShort.${element.type}`)}</b>:{" "}
                    <Button onClick={() => this.showProductVigicare(element)}>
                      {displayText} - {element.count}
                    </Button>
                  </div>
                ))}
              </Grid>
            ) : null}

            {data.premium ? (
              <>
                {/** <p className='homeSubtitle'><Trans>Historique</Trans></p>
                    {data.historySearch.map((prod, i) => {
                        return <Grid key={i}>
                            <Box className="searchBox">
                                <Grid className="searchBox">
                                    <Box bgcolor={"#F4BF4E33"} className="searchIcon">
                                        <PillIcon className="searchIconPill" />
                                    </Box>
                                    <Grid>
                                        <p className="searchBoxProd">{prod.produit}</p>
                                        <p className="searchBoxVoie"><Trans>{prod.voieAdmission}</Trans></p>
                                    </Grid>
                                </Grid>
                                <NavigateNext sx={{ justifySelf : 'end' }} color='blue' onClick={() => this.showProduct(prod, i)} />
                            </Box>
                        </Grid>
                    })}*/}
              </>
            ) : (
              <>
                <Grid className="premiumBox">
                  <p className="premiumSmallText">{t("app:common.premium")}</p>
                  <p className="premiumTitle">
                    {t("productSearch.premium.title")}
                  </p>
                  <p className="searchBoxVoie">
                    {t("productSearch.premium.message")}
                  </p>
                  <Link to="/premium" className="linkButton">
                    <Button
                      sx={{
                        color: "white",
                        background:
                          "linear-gradient(111.87deg, #F46B45, #EEA849)",
                        marginTop: "24px",
                      }}
                      variant="contained"
                    >
                      {t("productSearch.premium.becomeMember")}
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(
  withQuery(withTranslation("productSearch")(ProductSearch)),
);

function withRouter(Child) {
  return (props) => {
    const navig = useNavigate();
    return <Child {...props} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (props) => {
    const queryUser = useQuery(["fetch-user"], () =>
      getUser()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        }),
    );

    const mutationSearch = useMutation({
      mutationFn: (data) => {
        return searchProduct(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });

    const mutationProduct = useMutation({
      mutationFn: (data) => {
        return fetchProduct(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });

    const mutationSearchVigicare = useMutation({
      mutationFn: (data) => {
        return fetchTypesImput(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });

    const mutationProductVigicare = useMutation({
      mutationFn: (data) => {
        return fetchStatsImput(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    return (
      <Child
        {...props}
        mutationSearch={mutationSearch}
        mutationProduct={mutationProduct}
        mutationSearchVigicare={mutationSearchVigicare}
        mutationProductVigicare={mutationProductVigicare}
        queryUser={queryUser}
      />
    );
  };
}

import React from 'react';
import { Grid, Button, Box, TextField, Radio } from '@mui/material';

    
export default class ProduitsSanguins extends React.Component {
    constructor (props) {
        super(props);

        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data: {
                    numLot: props.data.numLot,
                    hemoDecl: props.data.hemoDecl,
                }
            }
        } else {
            this.state = {
                data: {
                    numLot: '',
                    hemoDecl: null,
                }
            };
        }

        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }
    handleChange(ev) {
        switch (ev.target.id) {
            case "numLot" : this.setState({ data: { ...this.state.data, numLot: ev.target.value } }); break;
            case "true" : this.setState({ data: { ...this.state.data, hemoDecl: true } }); break;
            case "false" : this.setState({ data: { ...this.state.data, hemoDecl: false } }); break;
            default: break;
        }
    }

    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
            <p className="pageTitle">{'Produits sanguins labiles'}</p>
            <Grid className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </Grid>
            <form className='inputForm'>
                <Grid>
                    <p className='robotoFont'>En cas d'administration associée de <b>produits sanguins labiles</b> préciser leurs numéros de lot :  </p>
                    <TextField fullWidth id="numLot" type="text" value={data.numLot} onChange={this.handleChange} label="Numéro de lot" />
                </Grid>
                
                
                <Grid>
                    <p className='robotoFont'>Avez-vous déjà réalisé une déclaration d'hémovigilance ? :</p>
                    <Grid  className='inputRadioRow'>
                    
                    <label className='robotoFont'>
                        <Radio id="true" checked={data.hemoDecl === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>     
                    <label className='robotoFont'>
                        <Radio id="false" checked={data.hemoDecl === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                    </Grid>      
                </Grid>
            </form>
            <Box className="bottomBtnForm">
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{data.numLot === '' && data.hemoDecl === null ? "PASSER" : "CONFIRMER"}</Button>
            </Box>
        </>
    }
};
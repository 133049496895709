import React from 'react';
import { Button, TextField, Grid, Box } from '@mui/material';

export default class DescEffets extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && props.data.length !== 0) {
            this.state = {
                data: {
                    textEffets: props.data.textEffets !== undefined ? props.data.textEffets : '',
                }   
            }
        } else {
            this.state = {
                data: {
                    textEffets: '',
                },
            }        
        }
        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }
    
    handleChange(ev) {
        if (ev.target.value !== undefined) this.setState({ data:{ textEffets: ev.target.value } })
    }

    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
            <Grid>
                <p className="pageTitle">Description de l'effet</p>
                <Grid className="lineVectors">
                    <hr className="lineVector" style={{width}} />
                    <hr className="lineVectorFull" />
                </Grid>
                <div className='inputForm'>
                    <TextField multiline rows={6} value={data.textEffets} label="Description et délai de survenue de l'effet indésirable" onChange={this.handleChange}  />
                </div>
                <Box className="bottomBtnForm">
                    <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{data.textEffets === "" ? "PASSER" : "CONFIRMER"}</Button>
                </Box>
            </Grid>
        </>
    }
}
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Medicament from './components/medicament/Medicament';
import Cosmetique from './components/cosmetique/Cosmetique';
import Complement from './components/complement/Complement';
import Dispositif from './components/dispositif/Dispositif';
import Premium from './components/premium/Premium';
import PremiumSuccess from './components/premium/PremiumSuccess';
import Home from './components/home';
import Connexion from './components/auth/Connexion';
import ConnexionPro from './components/auth/ConnexionPro';
import Inscription from './components/auth/Inscription';
import Auth from './components/auth/Auth';
import DeclarationSelection from './components/sharedComponents/DeclarationSelection';
import NotFound from './components/sharedComponents/NotFound';
import MotdepasseOublie from './components/auth/MotdepasseOublie';
import MotdepasseNouveau from './components/auth/MotdepasseNouveau';
import Validation from './components/auth/Validation';
import { AdverseEffect } from './components/adverseEffect';

import "./i18n";
import './App.css';
import CGU from './components/sharedComponents/CGU';
import PrivacyPolicy from './components/sharedComponents/PrivacyPolicy';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

function App() {

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router>
            <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route path="/connexion" element={<Connexion />} />
              <Route path="/pro" element={<ConnexionPro />} />
              <Route path="/inscription" element={<Inscription />} />
              <Route path="/validation/:token" element={<Validation />} />
              <Route path="/recuperation/:token" element={<MotdepasseNouveau />} />
              <Route path="/mdp" element={<MotdepasseOublie />} />
              <Route path="/imputabilite" element={<AdverseEffect />} />
              <Route path="/premium" element={<Premium />} />
              <Route path="/declaration" element={<DeclarationSelection />} />
              <Route path="/declaration/medicament" element={<Medicament />} />
              <Route path="/declaration/cosmetique" element={<Cosmetique />} />
              <Route path="/declaration/complement" element={<Complement />} />
              <Route path="/declaration/dispositif" element={<Dispositif />} />
              <Route path="/" element={<Home page={0} />} />
              <Route path="/recherche" element={<Home page={1} />} />
              <Route path="/profil" element={<Home page={2} />} />
              <Route path="/premium/success" element={<Home page={0} premium={true} />} />
              <Route path="/cgu" element={<CGU />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </LocalizationProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

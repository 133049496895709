import React from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button, Checkbox, TextField, Radio, MenuItem } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default class DescEffets extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    dateDebut: props.data.dateDebut !== undefined ? props.data.dateDebut : null,
                    delai: props.data.delai !== undefined ? props.data.delai : '',
                    uniteDuree: props.data.uniteDuree !== undefined ? props.data.uniteDuree : 'Jours',
                    symptomes: props.data.symptomes !== undefined ? props.data.symptomes : '',
                    examens: props.data.examens !== undefined ? props.data.examens : '',
                    priseCharge: props.data.priseCharge !== undefined ? props.data.priseCharge : '',
                    traitement: props.data.traitement !== undefined ? props.data.traitement : null,
                    traitementText: props.data.traitementText !== undefined ? props.data.traitementText : '',
                    evolution: props.data.evolution !== undefined ? props.data.evolution : '',
                    evolutionChrono: props.data.evolutionChrono !== undefined ? props.data.evolutionChrono : '',
                },
                errorIncomplete: false,
            }
        } else {
            this.state = {
                data : {
                    dateDebut: null,
                    delai: '',
                    uniteDuree: 'Jours',
                    symptomes: '',
                    examens: '',
                    priseCharge: '',
                    traitement: null,
                    traitementText: '',
                    evolution: '',
                    evolutionChrono: '',
                },
                errorIncomplete: false,
            };
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);

        window.scrollTo(0, 0);
    }
    
    handleChangeDropdown(ev) {
        if (ev.target.value !== undefined) { 
            this.setState({ data: { ...this.state.data, uniteDuree: ev.target.value } });
        }
    }

    handleChangeDate(ev) {
        this.setState({ data: { ...this.state.data, dateDebut: ev }});
    }

    handleChange(ev) {
        const { data } = this.state;
        if (ev !== undefined) {
            switch (ev.target.id) {
                case "delai" : this.setState({ data: { ...data, delai: ev.target.value } }); break;
                case "symptomes" : this.setState({ data: { ...data, symptomes: ev.target.value }  }); break;
                case "examens" : this.setState({ data: { ...data, examens: ev.target.value } }); break;
                case "pecNon" : this.setState({ data: { ...data, priseCharge: "non" } }); break;
                case "pecMed" : this.setState({ data: { ...data, priseCharge: "medecin" } }); break;
                case "pecU" : this.setState({ data: { ...data, priseCharge: "urgences" } }); break;
                case "pecH" : this.setState({ data: { ...data, priseCharge: "hospitalisation" } }); break;
                case "traitementTrue": this.setState({ data: { ...data, traitement: true } }); break;
                case "traitementFalse": this.setState({ data: { ...data, traitement: false } }); break;
                case "traitementNSP": this.setState({ data: { ...data, traitement: null } }); break;
                case "traitementText": this.setState({ data: { ...data, traitementText: ev.target.value } }); break;
                case "evoGen": this.setState({ data: { ...data, evolution: "general" } }); break;
                case "evoEnCours": this.setState({ data: { ...data, evolution: "enCours" } }); break;
                case "evoNon": this.setState({ data: { ...data, evolution: "non" } }); break;
                case "evoAgg": this.setState({ data: { ...data, evolution: "aggravation" } }); break;
                case "evoChrono": this.setState({ data: { ...data, evolutionChrono: ev.target.value } }); break;
                default: break;
            }
        }
        
    }

    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
        <p className="pageTitle">{'Description des effets'}</p>
        <div className="lineVectors">
            <hr className="lineVector" style={{width}} />
            <hr className="lineVectorFull" />
        </div>
        <div className='inputForm'>
            <DesktopDatePicker id="dateDebut" inputFormat="DD/MM/YYYY" value={data.dateDebut} onChange={this.handleChangeDate} label="Date d'apparition des premiers effets *" renderInput={(params) => {return <TextField {...params} error={data.dateDebut !== null && (!data.dateDebut.isBefore() || !data.dateDebut.isValid())} />}} />
            <Grid className='formRowDouble'>
                <TextField fullWidth id="delai" type="number" value={data.delai} onChange={this.handleChange} label="Délai d'apparition" />
                <TextField select value={data.uniteDuree} onChange={this.handleChangeDropdown} sx={{ minWidth: '35%', textAlign: 'end' }}>
                    <MenuItem value="Jours">Jours</MenuItem>
                    <MenuItem value="Semaines">Semaines</MenuItem>
                    <MenuItem value="Années">Années</MenuItem>
                </TextField>
            </Grid>

            <TextField id="symptomes" multiline rows={6} type="text" value={data.symptomes} onChange={this.handleChange} label="Description des symptômes *" />
            <TextField id="examens" multiline rows={6} type="text" value={data.examens} onChange={this.handleChange} label="Examens réalisés *" />

            <Grid>
                <p className="robotoFontB">Prise en charge médicale *</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Checkbox id="pecNon" checked={data.priseCharge === 'non'} onChange={this.handleChange} />
                        {'  Aucune'}
                    </label> 
                </Grid> 
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Checkbox id="pecMed" checked={data.priseCharge === 'medecin'} onChange={this.handleChange} />
                        {'  Medecin'}
                    </label>
                </Grid> 
                <Grid className='inputCheckboxRow'>
  
                    <label className='robotoFont'>
                        <Checkbox id="pecU" checked={data.priseCharge === 'urgences'} onChange={this.handleChange} />
                        {'  Urgences'}
                    </label>
                </Grid>  
                <Grid className='inputCheckboxRow'>
  
                    <label className='robotoFont'>
                        <Checkbox id="pecH" checked={data.priseCharge === 'hospitalisation'} onChange={this.handleChange} />
                        {'  Hospitalisation'}
                    </label>
                </Grid> 
            </Grid>


            <Grid>
                <p className="robotoFontB">Mise en place d'un traitement</p>
                <Grid className='inputRadioRow'>
                    <label className='robotoFont'>
                        <Radio id="traitementTrue" checked={data.traitement === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>     
                    <label className='robotoFont'>
                        <Radio id="traitementFalse" checked={data.traitement === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                    <label className='robotoFont'>
                        <Radio id="traitementNSP" checked={data.traitement === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
                <TextField fullWidth disabled={data.traitement !== true} id="traitementText" type="text" value={data.traitementText} onChange={this.handleChange} label="Si oui, précisez" />
            </Grid>

            <Grid>
                <p className="robotoFontB">Évolution</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Checkbox id="evoGen" checked={data.evolution === 'general'} onChange={this.handleChange} />
                        {'  Guérison générale'}
                    </label> 
                </Grid> 
                <Grid className='inputCheckboxRow'>
  
                    <label className='robotoFont'>
                        <Checkbox id="evoEnCours" checked={data.evolution === 'enCours'} onChange={this.handleChange} />
                        {'  Guérison en cours'}
                    </label>
                </Grid> 
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Checkbox id="evoNon" checked={data.evolution === 'non'} onChange={this.handleChange} />
                        {'  Pas de guérison'}
                    </label>
                </Grid>  
                <Grid className='inputCheckboxRow'>
  
                    <label className='robotoFont'>
                        <Checkbox id="evoAgg" checked={data.evolution === 'aggravation'} onChange={this.handleChange} />
                        {'  Aggravation de l\'état'}
                    </label>
                </Grid> 
                <TextField fullWidth id="evoChrono" type="text" value={data.evolutionChrono} onChange={this.handleChange} label="Précisez la chronologie de l'évolution" />

            </Grid>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>

            {data.symptomes !== '' && data.dateDebut !== null && (data.dateDebut.isBefore() && data.dateDebut.isValid())
            && data.examens !== '' && data.priseCharge !== '' 
                ?   <Grid textAlign={'end'} marginBottom="24px">
                        <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                    </Grid>
                :   <Grid textAlign={'end'} marginBottom="24px">
                        <Button variant="contained" disabled>{"SUIVANT"}</Button>
                    </Grid>
            }
        </div>
    </>
    }
}















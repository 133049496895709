import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Button, Grid, Box, Checkbox } from "@mui/material";
import Popup from "reactjs-popup";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";

import { VigicareLogoWhiteXXL } from "../../../styles/Icons";
import { newImputabilityTest } from "../../../api/history";
import { formatNumber } from "../../../utils/formatNumber";

export const AdverseEffectResult = ({ userData, data }) => {
  useTranslation();
  const [activePage, setActivePage] = useState(0); // 0 thankyou, 1 result

  const target = useMemo(() => {
    let _target = "";

    switch (data.typeProduit) {
      case "Dispositif médical":
        _target = "dispositif";
        break;
      case "Cosmétique":
        _target = "cosmetique";
        break;
      case "Complément alimentaire":
        _target = "complement";
        break;
      case "Médicament":
        _target = "medicament";
        break;
      default:
        break;
    }

    return _target;
  }, [data.typeProduit]);

  const [showAlert, setShowAlert] = useState(true);

  const [isCguAccepted, setIsCguAccepted] = useState(false);
  const [isDataAccepted, setIsDataAccepted] = useState(false);

  const isPremiumUser = useMemo(() => {
    return userData &&
      userData.dateFin !== undefined &&
      userData.dateFin !== null
      ? moment(userData.dateFin).isSameOrAfter()
      : false;
  }, [userData]);

  const _nbReports = useMemo(() => {
    if (data.nbReports !== undefined && data.nbReports !== null)
      return data.nbReports;
    if (data.nbReportsVigicare !== undefined && data.nbReportsVigicare !== null)
      return data.nbReportsVigicare;

    return 0;
  }, [data.nbReports, data.nbReportsVigicare]);

  const computeScore = (data) => {
    let score = 2;

    if (data.symptomesProduit === true) {
      score += 1;
    }

    if (data.arretEffet === true) {
      score += 1;
    }

    if (data.retourEffet === true) {
      score += 2;
    } else if (data.retourEffet === false) {
      score -= 1;
    }

    if (data.autresCauses === true) {
      score -= 1;
    } else if (data.autresCauses === false) {
      score += 2;
    }

    if (data.retourEffetAutreProduit === true) {
      score -= 1;
    } else if (data.retourEffetAutreProduit === false) {
      score += 1;
    }

    if (data.produitAnalyses === true) {
      score += 1;
    }

    if (data.reactionDose === true) {
      score += 1;
    }

    if (data.reactionSimilaire === true) {
      score += 1;
    }

    if (data.confirmationMedicale === true) {
      score += 1;
    }

    return score;
  };
  const score = useMemo(() => computeScore(data), [data]);

  const {
    mutateAsync: addAdverseEffect,
    isLoading: isAddAdverseEffectLoading,
  } = useMutation({
    mutationFn: (data) => {
      return newImputabilityTest(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  });

  const handleAccept = () => {
    if (!!userData) {
      addAdverseEffect({
        resultat: score,
        type: data.typeProduit,
        nomProduit: data.nomProduit,
        nomMarque: data.marqueProduit,
        annee: moment(data.dateEffet).year(),
        substanceActive1: data.substanceActive1,
        substanceActive2: data.substanceActive2,
        substanceActive3: data.substanceActive3,
        typesEffets: data.typeEffet,
        grave: !data.aucun,
        sexe: data.sex,
        groupeAge: data.trancheAge,
      }).then((res) => {
        setActivePage(1);
      });
    } else {
      setActivePage(1);
    }
  };

  const renderAgreementForm = () => {
    return (
      <div className="thanksBox">
        <VigicareLogoWhiteXXL />
        <p className="thanksTitle">
          <Trans>Merci d'avoir rempli le questionnaire d'imputabilité.</Trans>
        </p>
        <Box className="bottomBtnQuestionnaire">
          <div
            className="checkboxAccept"
            onClick={() => setIsDataAccepted((value) => !value)}
          >
            <Checkbox
              checked={isDataAccepted}
              id="acceptData"
              disabled={isAddAdverseEffectLoading}
            />
             <p id="acceptData">
              <Trans>En cochant cette case, j'accepte que mes données à caractère personnelles soient traitées afin de me fournir les services proposés par l'application VIGICARE. Pour en savoir plus, consultez notre <a href='/privacy' target='_blank'>Politique de confidentialité</a>. *</Trans>
              </p>
          </div>

          <div
            className="checkboxAccept"
            onClick={() => setIsCguAccepted((value) => !value)}
          >
            <Checkbox
              checked={isCguAccepted}
              id="acceptCGU"
              disabled={isAddAdverseEffectLoading}
            />
            <p id="acceptCGU">
              <Trans>J'ai lu et accepte les <a href='/cgu' target='_blank'>Conditions Générales d'Utilisations</a>. *</Trans>
            </p>
          </div>
        </Box>
        <div className="acceptBtn">
          <Button
            disabled={
              !isDataAccepted || !isCguAccepted || isAddAdverseEffectLoading
            }
            onClick={handleAccept}
            variant="contained"
            color="blue"
            sx={{
              width: "80%",
              textTransform: "none !important",
              marginBottom: "12px",
              padding: "10px 0px 10px 0px",
            }}
          >
            <Trans>Accéder à mes résultats</Trans>
          </Button>
        </div>
      </div>
    );
  };

  const renderProAlert = () => {
    if (
      data.proSante &&
      data.proSanteLabel !== "Autre" &&
      (target === "medicament" || target === "complement" || !data.aucun)
    ) {
      return (
        <Popup
          open={showAlert}
          position="center"
          onClose={() => setShowAlert(false)}
          contentStyle={{ minWidth: "80%", borderRadius: "1em" }}
        >
          <Grid className="alertProPanel">
            <div className="alertProTitle">
              <p>
                <b>
                  <Trans>
                    Vous êtes un professionnel de santé, conformément à la
                    législation vous devez déclarer l'effet indésirable décrit.
                  </Trans>
                </b>
              </p>
            </div>
            {!isPremiumUser ? (
              <>
                <p>
                  <Trans>
                    En vous connectant vous pouvez accéder à votre historique et
                    suivre vos déclarations.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    L'abonnement premium vous permet d'accéder à l'application
                    hors connexion et envoyer votre déclaration plus tard, ainsi
                    que l'accès aux effets indésirables en vie réelle pour de
                    nombreux produits.
                  </Trans>
                </p>
              </>
            ) : (
              <></>
            )}
            <div className="alertProTitle">
              <Button onClick={() => setShowAlert(false)} variant="contained">
                <Trans>Je déclare</Trans>
              </Button>
            </div>
          </Grid>
        </Popup>
      );
    }

    return null;
  };

  const renderResult = () => {
    let resultTitle = "";
    let resultScore = "";
    let resultRow1 = "";
    let resultRow2 = "";

    if (score < 1) {
      resultTitle = <Trans>Réaction douteuse</Trans>;
      resultScore = (
        <>
          <Trans>Score</Trans> ≤ 0
        </>
      );
      resultRow1 = (
        <Trans>
          D'après vos réponses, <b>vos symptômes semblent être faiblement liés</b>{" "}
          à l'utilisation du(des) produit(s) mentionné(s).
        </Trans>
      );
      resultRow2 = (
        <Trans>
          En cas de persistance des symptômes, nous vous conseillons de vous
          rapprocher d'un professionnel de santé pour en savoir plus et
          connaître les mesures à appliquer.
        </Trans>
      );
    } else if (score < 5) {
      resultTitle = <Trans>Réaction possible</Trans>;
      resultScore = (
        <>
          <Trans>Score</Trans> 1 <Trans>à</Trans> 4
        </>
      );
      resultRow1 = (
        <Trans>
          Vous nous avez décrit des événements indésirables qu'il ne faut pas
          prendre à la légère ! D'après vos réponses et en conformité avec
          l'échelle officielle et internationale de Naranjo, <b>vos symptômes pourrait être liés</b>{" "}
          à l'utilisation du(des) produit(s) mentionné(s).
        </Trans>
      );
      resultRow2 = (
        <Trans>
          Nous vous conseillons de vous rapprocher d'un professionnel de santé
          pour en savoir plus et connaître le protocole à suivre.
        </Trans>
      );
    } else if (score < 9) {
      resultTitle = <Trans>Réaction probable</Trans>;
      resultScore = (
        <>
          <Trans>Score</Trans> 5 <Trans>à</Trans> 8
        </>
      );
      resultRow1 = (
        <Trans>
          Vous nous avez décrit des événements indésirables qu'il ne faut pas
          prendre à la légère ! D'après vos réponses et en conformité avec
          l'échelle officielle et internationale de Naranjo, <b>vos symptômes semblent être liés</b>{" "}
          à l'utilisation du(des) produit(s) mentionné(s).
        </Trans>
      );
      resultRow2 = (
        <Trans>
          Nous vous conseillons de vous rapprocher rapidement d'un professionnel
          de santé pour en savoir plus et connaître le protocole à suivre.
        </Trans>
      );
    } else {
      resultTitle = <Trans>Réaction certaine</Trans>;
      resultScore = (
        <>
          <Trans>Score</Trans> ≥ 9
        </>
      );
      resultRow1 = (
        <Trans>
          Vous nous avez décrit des événements indésirables qu'il ne faut pas
          prendre à la légère ! D'après vos réponses et en conformité avec
          l'échelle officielle et internationale de Naranjo, <b>vos symptômes sont liés</b>{" "}
          à l'utilisation du(des) produit(s) mentionné(s).
        </Trans>
      );
      resultRow2 = (
        <Trans>
          Nous vous conseillons de vous rapprocher au plus vite d'un
          professionnel de santé pour en savoir plus et connaître le protocole à
          suivre.
        </Trans>
      );
    }

    const toSend = {
      user: {
        sex: data.sex,
        proSante: data.proSante,
        proSanteLabel: data.proSanteLabel,
        proSanteAutre: data.proSanteAutre,
      },
      effets: {
        datePrise:
          data.datePrise !== undefined ? data.datePrise.toISOString() : null,
        dateEffet:
          data.dateEffet !== undefined ? data.dateEffet.toISOString() : null,
        descEffet: data.descEffet,
        typeEffet: data.typeEffet,
      },
      produit: {
        name: data.nomProduit,
        marqueProduit: data.marqueProduit,
        nomFabricant: data.nomFabricant,
      },
      gravite: {
        hospitalisation: data.hospitalisation,
        incapacite: data.incapacite,
        handicap: data.handicap,
        deces: data.deces,
        anomalies: data.anomalies,
        risqueVital: data.risqueVital,
        aucun: data.aucun,
      },
    };

    return (
      <>
        {renderProAlert()}
        <div className="resultatHead">
          <div className="resultatTitle">
            <CircleIcon color="yellowDot" />
            <p>{resultTitle}</p>
          </div>
          <p className="resultatScore">{resultScore}</p>
        </div>

        <div className="resultRow">
          <CheckIcon fontSize="large" color="grey" />
          <p>{resultRow1}</p>
        </div>
        <div className="resultRow">
          <MedicalInformationIcon fontSize="large" color="blue.dark" />
          <p>{resultRow2}</p>
        </div>
        <div className="resultRow">
          <QueryStatsIcon fontSize="large" color="premium" />
          {isPremiumUser ? (
            <p>
              <Trans>À ce jour,</Trans>{" "}
              <b>
                {formatNumber(_nbReports)} <Trans>effets indésirables</Trans>
              </b>{" "}
              <Trans>
                ont été déclarés sur ce produit.{" "}
                <Link className="premiumLinks" target="_blank" to="/recherche">
                  Recherchez ce produit
                </Link>
              </Trans>
            </p>
          ) : (
            <p>
              <Trans>À ce jour,</Trans>{" "}
              <b>
                {formatNumber(_nbReports)} <Trans>effets indésirables</Trans>
              </b>{" "}
              <Trans>
                ont été déclarés sur ce produit. Pour en savoir plus, passez à
                la version
              </Trans>
              <Link className="premiumLinks" target="_blank" to="/premium">
                Vigicare PREMIUM
              </Link>
            </p>
          )}
        </div>
        <div className="bottomBtnQuestionnaire bottomBtnDecla">
          <Link to={"/declaration/" + target} state={toSend}>
            <Button
              variant="contained"
              sx={{
                marginBottom: "128px",
                padding: "12px 0px 12px 0px",
                textTransform: "unset",
              }}
            >
              <Trans>Déclarer l'effet indésirable en ligne (5min)</Trans>
            </Button>
          </Link>
          <a
            href="https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              sx={{
                marginBottom: "64px",
                padding: "12px 0px 12px 0px",
                textTransform: "unset",
              }}
              variant="outlined"
            >
              <Trans>Déclaration sur le portail de signalement</Trans>
            </Button>
          </a>
          <Link to="/">
            <Button
              variant="outlined"
              sx={{
                padding: "12px 0px 12px 0px",
                textTransform: "unset",
                color: "#071B2E !important",
                borderColor: "#D9D9D9 !important",
              }}
            >
              <Trans>Retour à l'accueil</Trans>
            </Button>
          </Link>
        </div>
      </>
    );
  };

  return <>{activePage === 0 ? renderAgreementForm() : renderResult()}</>;
};

import React from 'react';
import { Checkbox, Grid, Button, Box } from '@mui/material';
    
export default class PatientNouveauNe extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data: {
                    newborn: props.data.newborn !== undefined ? props.data.newborn : false,
                    direct: props.data.direct !== undefined ? props.data.direct : false,
                    mother: props.data.mother !== undefined ? props.data.mother : false,
                    pregnancy: props.data.pregnancy !== undefined ? props.data.pregnancy : false,
                    father: props.data.father !== undefined ? props.data.father : false,
                }   
            }
        } else {
            this.state = {
                data: {
                    newborn: false,
                    direct: false,
                    mother: false,
                    pregnancy: false,
                    father: false,
                },
            }        
        }

        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }

    handleChange(ev) {
        const nbData = this.state.data;
        switch (ev.target.id) {
            case "newborn" : this.setState({ data: { ...nbData, newborn: !nbData.newborn, direct: false, mother: false } }); break;
            case "direct" : this.setState({ data: { ...nbData, direct: !nbData.direct, newborn: true } }); break;
            case "mother" : this.setState({ data: { ...nbData, mother: !nbData.mother, newborn: true } }); break;
            case "pregnancy" : this.setState({ data: { ...nbData, pregnancy: !nbData.pregnancy } }); break;
            case "father" : this.setState({ data: { ...nbData, father: !nbData.father } }); break;
            default: break;
        }
    }
    
    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <div>
            <p className="pageTitle">Patient traité</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}}  />
                <hr className="lineVectorFull" />
            </div>
            <p>Si la déclaration <b>concerne un nouveau-né</b>, les médicaments ont été reçus :</p>
            <div>
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox  id="newborn" className="inputBox" checked={data.newborn} onChange={this.handleChange}  />
                    <p style={{ cursor: 'pointer' }} id="newborn">par le nouveau né</p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox size={'small'} id="direct" className="inputBox" checked={data.direct}  />
                    <p style={{ cursor: 'pointer' }} id="direct">directement</p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox size={'small'} id="mother" className="inputBox" checked={data.mother}  />
                    <p style={{ cursor: 'pointer' }} id="mother">via l'allaitement</p>
                </Grid>
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox id="pregnancy" className="inputBox" checked={data.pregnancy}  />
                    <p style={{ cursor: 'pointer' }} id="pregnancy">par la mère durant la grossesse</p>
                </Grid>
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox id="father" className="inputBox" checked={data.father}  />
                    <p style={{ cursor: 'pointer' }} id="father">par le père</p>
                </Grid>   
            </div>    
            <Box className='bottomBtnForm'>
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{!data.newborn && !data.direct && !data.mother && !data.father && !data.pregnancy ? "PASSER" : "CONFIRMER"}</Button>
            </Box>
        </div>
    }
}
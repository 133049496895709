// Desktop Back button Handling

// Neutralize and replace by callback
export const neutralizeBack = (callback) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      callback();
    };
};

// Re-activate classic button working
export const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
};
import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Grid, Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { withTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { fetchNbReports } from '../../api/search';
import { fetchNbImput } from '../../api/products';
import { formatNumber } from '../../utils/formatNumber';

class HistoryTest extends React.Component {
    constructor(props) {
        super(props);
        let target = 'medicament';
        if (props.data.type !== undefined) {
            switch(props.data.type) {
                case 'Dispositif médical' : target = "dispositif"; break;
                case 'Cosmétique' : target = "cosmetique"; break;
                case 'Complément alimentaire' : target = "complement"; break;
                case 'Médicament' : target = "medicament"; break;
                default: break;
            }
        }

        this.state = {
            resultat: props.data.resultat !== undefined && props.data.resultat !== null ? props.data.resultat : null,
            data: props.data !== undefined && props.data !== null ? props.data : {},
            target,
            connected: props.connected !== undefined ? props.connected : false,
            premium: props.premium !== undefined ? props.premium : false,
            nbReports: null,
        }
    }

    componentDidMount() {
        if (this.props.data.type === "Médicament") {
            this.props.mutationNbOfReports.mutate(this.state.data.nomProduit);
        } else {
            this.props.mutationNbOfImputVigicare.mutate(this.state.data.nomProduit);
        }
    }

    componentDidUpdate() {
        
        if (this.state.data.type === "Médicament") {
            if (this.state.nbReports === null && this.props.mutationNbOfReports.isSuccess) {
                this.setState({nbReports: formatNumber(this.props.mutationNbOfReports.data.nbOfReports)});
            }
            if (this.state.nbReports === null && this.props.mutationNbOfReports.isError){
                this.setState({nbReports: 0});
            }
        } else {
            if (this.state.nbReports === null && this.props.mutationNbOfImputVigicare.isSuccess) {
                this.setState({nbReports: formatNumber(this.props.mutationNbOfImputVigicare.data.nb)});
            }
            if (this.state.nbReports === null && this.props.mutationNbOfImputVigicare.isError){
                this.setState({nbReports: 0});
            }
        }
    }

    render () {
        const { target, resultat, data, premium, nbReports } = this.state;        
   
        return <div>
                <p className='resultatTitleHistory'>Résultat du test d'imputabilité : {data.nomProduit}</p>
                    {resultat === null ? 
                        <>
                            <p className='errorText'>Test introuvable</p>
                            <Button variant='outlined' onClick={this.props.handler}>Retour accueil</Button>
                        </>
                        
                    : <>
                    {resultat < 1 ? <>
                        <div className='resultatHead'>
                            <div className='resultatTitle'><CircleIcon color='yellowDot'/><p><Trans>Réaction douteuse</Trans></p></div>
                            <p className='resultatScore'><Trans>Score</Trans> ≤ 0</p>
                        </div>
                        
                        <div className='resultRow'>
                            <CheckIcon fontSize='large' color='grey' />
                            <p><Trans>D'après vos réponses, <b>vos symptômes semblent être faiblement liés</b> à l'utilisation du(des) produit(s) mentionné(s).</Trans></p>
                        </div>
                        <div className='resultRow'>
                            <MedicalInformationIcon fontSize='large' color='blue.dark' />
                            <p><Trans>En cas de persistance des symptômes, nous vous conseillons de vous rapprocher d'un professionnel de santé pour en savoir plus et connaître les mesures à appliquer.</Trans></p>
                        </div>
                        <div className='resultRow'>
                            <QueryStatsIcon fontSize='large' color='premium' />
                            {premium ?
                                <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit. <Link to='/recherche'>Recherchez ce produit</Link></Trans></p>
                            : <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirable</Trans></b> <Trans>ont été déclarés sur ce produit. Pour en savoir plus, passez à la version</Trans><Link to='/premium' target="_blank">Vigicare PREMIUM</Link></p>}
                        </div>
                        <div className='bottomBtnQuestionnaire bottomBtnHisto' >
                            <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>Déclarer l'effet indésirable en ligne (5min)</Button></div></Link>
                            <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>Déclaration sur le portail de signalement</Button></div></a>
                            <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>Retour à l'accueil</Button></div>
                        </div>
                    </> 
                        : <>{resultat < 5 ? 
                                <>
                                    <div className='resultatHead'>
                                        <div className='resultatTitle'><CircleIcon color='orangeDot'/><p>Réaction possible</p></div>
                                        <p className='resultatScore'><Trans>Score</Trans> 1 <Trans>à</Trans> 4</p>
                                    </div>
                                    
                                    <div className='resultRow'>
                                        <CheckIcon fontSize='large' color='grey' />
                                        <p><Trans>Vous nous avez décrit des événements indésirables qu'il ne faut pas prendre à la légère ! D'après vos réponses et en conformité avec l'échelle officielle et internationale de Naranjo, <b>vos symptômes pourrait être liés</b> à l'utilisation du(des) produit(s) mentionné(s).</Trans></p>
                                    </div>
                                    <div className='resultRow'>
                                        <MedicalInformationIcon fontSize='large' color='blue' />
                                        <p><Trans>Nous vous conseillons de vous rapprocher d'un professionnel de santé pour en savoir plus et connaître le protocole à suivre.</Trans></p>
                                    </div>
                                    <div className='resultRow'>
                                        <QueryStatsIcon fontSize='large' color='premium' />
                                        {premium ?
                                            <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit.</Trans> <Link className='premiumLinks' target="_blank" to='/recherche'>Recherchez ce produit</Link></p>
                                        : <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit. Pour en savoir plus, passez à la version</Trans> <Link className='premiumLinks' target="_blank" to='/premium'>Vigicare PREMIUM</Link></p>}
                                    </div>
                                    <div className='bottomBtnQuestionnaire bottomBtnHisto' >
                                        <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>Déclarer l'effet indésirable en ligne (5min)</Button></div></Link>
                                        <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>Déclaration sur le portail de signalement</Button></div></a>
                                        <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>Retour à l'accueil</Button></div>
                                    </div>
                                </> 
                                : <> {resultat < 9 ? 
                                    <>
                                        <div className='resultatHead'>
                                            <div className='resultatTitle'><CircleIcon color='redDot' sx={{ pt: '1em'}}/><p><Trans>Réaction probable</Trans></p></div>
                                            <p className='resultatScore'><Trans>Score</Trans> 5 <Trans>à</Trans> 8</p>
                                        </div>
                                        <div className='resultRow'>
                                            <CheckIcon fontSize='large' color='grey' />
                                            <p><Trans>Vous nous avez décrit des événements indésirables qu'il ne faut pas prendre à la légère ! D'après vos réponses et en conformité avec l'échelle officielle et internationale de Naranjo, <b>vos symptômes semblent être liés</b> à l'utilisation du(des) produit(s) mentionné(s).</Trans></p>
                                        </div>
                                        <div className='resultRow'>
                                            <MedicalInformationIcon fontSize='large' color='blue' />
                                            <p><Trans>Nous vous conseillons de vous rapprocher rapidement d'un professionnel de santé pour en savoir plus et connaître le protocole à suivre.</Trans></p>
                                        </div>
                                        <div className='resultRow'>
                                            <QueryStatsIcon />
                                            {premium ?
                                                <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit. <Link className='premiumLinks' to='/recherche'>Recherchez ce produit</Link></Trans></p>
                                            : <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit. Pour en savoir plus, passez à la version</Trans><Link className='premiumLinks' target="_blank" to='/premium'>Vigicare PREMIUM</Link></p>}
                                        </div>
                                        <div className='bottomBtnQuestionnaire bottomBtnHisto' >
                                            <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>Déclarer l'effet indésirable en ligne (5min)</Button></div></Link>
                                            <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>Déclaration sur le portail de signalement</Button></div></a>
                                            <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>Retour à l'accueil</Button></div>
                                        </div>
                                    </> 
                                    : <>
                                        <div className='resultatHead'>
                                            <div className='resultatTitle'><CircleIcon color='redDotDark'/><p><Trans>Réaction certaine !</Trans></p></div>
                                            <p className='resultatScore'><Trans>Score</Trans> ≥ 9</p>
                                        </div>
                                        
                                        <div className='resultRow'>
                                            <CheckIcon fontSize='large' color='grey' />
                                            <p><Trans>Vous nous avez décrit des événements indésirables qu'il ne faut pas prendre à la légère ! D'après vos réponses et en conformité avec l'échelle officielle et internationale de Naranjo, <b>vos symptômes sont liés</b> à l'utilisation du(des) produit(s) mentionné(s).</Trans></p>
                                        </div>
                                        <div className='resultRow'>
                                            <MedicalInformationIcon fontSize='large' color='blue' />
                                            <p><Trans>Nous vous conseillons de vous rapprocher au plus vite d'un professionnel de santé pour en savoir plus et connaître le protocole à suivre.</Trans></p>
                                        </div>
                                        <div className='resultRow'>
                                            <QueryStatsIcon fontSize='large' color='premium' />
                                            {premium ?
                                                <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit. <br /><Link className='premiumLinks' to='/recherche'>Recherchez ce produit</Link></Trans></p>
                                            : <p><Trans>À ce jour,</Trans> <b>{nbReports !== null ? nbReports : 0} <Trans>effets indésirables</Trans></b> <Trans>ont été déclarés sur ce produit. <br />Pour en savoir plus, passez à la version</Trans> <Link className='premiumLinks' target="_blank" to='/premium'>Vigicare PREMIUM</Link></p>}
                                        </div>

                                        <div className='bottomBtnQuestionnaire bottomBtnHisto'>
                                            <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>Déclarer l'effet indésirable en ligne (5min)</Button></div></Link>
                                            <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>Déclaration sur le portail de signalement</Button></div></a>
                                            <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>Retour à l'accueil</Button></div>
                                        </div>
                                </>}
                            </>
                        }</>
                    }  </>}
            </div>
    }
};

export default withQuery(withTranslation()(HistoryTest));

function withQuery( Child ) {
    return ( props ) => {
        const mutationNbOfReports = useMutation({
            mutationFn: (product) => {
                return fetchNbReports(product).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });
        const mutationNbOfImputVigicare = useMutation({
            mutationFn: (product) => {
                return fetchNbImput(product).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });

        return <Child { ...props } mutationNbOfReports={mutationNbOfReports} mutationNbOfImputVigicare={mutationNbOfImputVigicare} />;
    }
}
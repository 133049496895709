import React from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button, TextField } from '@mui/material';

export default class DispositifMedical extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    denominationDM: props.data.denominationDM !== undefined ? props.data.denominationDM : '',
                    denominationComDM: props.data.denominationComDM !== undefined ? props.data.denominationComDM : '',
                    numSerieLot: props.data.numSerieLot !== undefined ? props.data.numSerieLot : '',
                    vLogicielle: props.data.vLogicielle !== undefined ? props.data.vLogicielle : '',
                    nomFournisseur: props.data.nomFournisseur !== undefined ? props.data.nomFournisseur : '',
                    adrFournisseur: props.data.adrFournisseur !== undefined ? props.data.adrFournisseur : '',
                    cpFournisseur: props.data.cpFournisseur !== undefined ? props.data.cpFournisseur : '',
                    comFournisseur: props.data.comFournisseur !== undefined ? props.data.comFournisseur : '',
                    nomFabricant: props.data.nomFabricant !== undefined ? props.data.nomFabricant : '',
                    adrFabricant: props.data.adrFabricant !== undefined ? props.data.adrFabricant : '',
                    cpFabricant: props.data.cpFabricant !== undefined ? props.data.cpFabricant : '',
                    comFabricant: props.data.comFabricant !== undefined ? props.data.comFabricant : '',
                },
                errorIncomplete: false,
            };
        } else {
            this.state = {
                data: {
                    denominationDM: '',
                    denominationComDM: '',
                    numSerieLot: '',
                    vLogicielle: '',
                    nomFournisseur: '',
                    adrFournisseur: '',
                    cpFournisseur: '',
                    comFournisseur: '',
                    nomFabricant: '',
                    adrFabricant: '',
                    cpFabricant: '',
                    comFabricant: '',
                },
                errorIncomplete: false,
            }
        }

        this.handleChange = this.handleChange.bind(this);
        window.scrollTo(0, 0);
    }
       

    handleChange(ev) {
        const { data } = this.state;
        switch (ev.target.id) {
            case "denominationDM" : this.setState({ data: { ...data, denominationDM: ev.target.value } } ); break;
            case "denominationComDM" : this.setState({ data: { ...data, denominationComDM: ev.target.value  }  }); break;
            case "numSerieLot" : this.setState({ data: { ...data, numSerieLot: ev.target.value }  }); break;
            case "vLogicielle" : this.setState({ data: { ...data, vLogicielle: ev.target.value }   }); break;
            case "nomFournisseur" : this.setState({ data: { ...data, nomFournisseur: ev.target.value }  }); break;
            case "adrFournisseur" : this.setState({ data: { ...data, adrFournisseur: ev.target.value  }  }); break;
            case "cpFournisseur" : this.setState({ data: { ...data, cpFournisseur: ev.target.value }  }); break;
            case "comFournisseur" : this.setState({ data: { ...data, comFournisseur: ev.target.value }  }); break;
            case "nomFabricant" : this.setState({ data: { ...data, nomFabricant: ev.target.value }  }); break;
            case "adrFabricant" : this.setState({ data: { ...data, adrFabricant: ev.target.value }  }); break;
            case "cpFabricant" : this.setState({ data: { ...data, cpFabricant: ev.target.value }  }); break;
            case "comFabricant" : this.setState({ data: { ...data, comFabricant: ev.target.value }  }); break;
            default: break;
        }
    }

    onSubmit() {
        if (this.state.data.denominationDM !== "" && this.state.data.denominationComDM !== "" 
        && this.state.data.numSerieLot !== "" && this.state.data.nomFabricant !== "" 
        && this.state.data.adrFabricant !== "" && this.state.data.cpFabricant !== "" 
        && this.state.data.comFabricant !== "") {
            this.props.handler(this.state.data);
        } else {
            window.scrollTo(0, 0);
            this.setState({ errorIncomplete: true });
        }
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
        <p className="pageTitle">Le dispositif médical impliqué</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            {errorIncomplete ? <p className='errorText'>Champs manquants</p> : null}

            <div className='inputForm'>
                <TextField fullWidth id="denominationDM" type="text" value={data.denominationDM} onChange={this.handleChange} error={errorIncomplete && data.denominationDM === ""}  label="Dénomination commune du DM *" />
                <TextField fullWidth multiline id="denominationComDM" type="text" value={data.denominationComDM} onChange={this.handleChange} error={errorIncomplete && data.denominationComDM === ""}  label="Dénomination commerciale : modèle/type/référence *" />
                <TextField fullWidth id="numSerieLot" type="text" value={data.numSerieLot} onChange={this.handleChange} error={errorIncomplete && data.numSerieLot === ""}  label="N° de série ou de lot *" />
                <TextField fullWidth id="vLogicielle" type="text" value={data.vLogicielle} onChange={this.handleChange} label="Version logicielle (facultatif)" />
              
                <TextField fullWidth id="nomFournisseur" type="text" value={data.nomFournisseur} onChange={this.handleChange} label="Nom du fournisseur (facultatif)" />
                <TextField fullWidth id="adrFournisseur" type="text" value={data.adrFournisseur} onChange={this.handleChange} label="Adresse du fournisseur (facultatif)" />
                  
                <div className="inputSmallRow">
                    <TextField id="cpFournisseur" className="inputSmall" type="number" value={data.codePostal} onChange={this.handleChange} label="Code Postal" />
                    <TextField id="comFournisseur" className="inputSmall" type="text" value={data.commune} onChange={this.handleChange} label="Commune" />
                </div>
                <TextField fullWidth id="nomFabricant" type="text" value={data.nomFabricant}  error={errorIncomplete && data.nomFabricant === ""} onChange={this.handleChange} label="Nom du fabricant *" />
                <TextField fullWidth id="adrFabricant" type="text" value={data.adrFabricant}  error={errorIncomplete && data.adrFabricant === ""} onChange={this.handleChange} label="Adresse du fabriquant *" />
                <div className="inputSmallRow">
                    <TextField id="cpFabricant" className="inputSmall" type="number" value={data.cpFabricant}  error={errorIncomplete && data.cpFabricant === ""} onChange={this.handleChange} label="Code Postal *" />
                    <TextField id="comFabricant" className="inputSmall" type="text" value={data.comFabricant}  error={errorIncomplete && data.comFabricant === ""} onChange={this.handleChange} label="Commune *" />
                </div>
            </div>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>
            <Grid textAlign={'end'} marginBottom="24px" marginTop="24px">
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
            </Grid>
        </>
    }
}
import { useState } from "react";
import { Grid, StepLabel, Stepper } from "@mui/material";
import { Trans } from "react-i18next";
import Step from "@mui/material/Step";

import { Product } from "./Product";
import { Usage } from "./Usage";
import { Symptoms } from "./Symptoms";
import { ProductsInfo } from "./ProductsInfo";

export const Questionnaire = ({
  connected,
  userData,
  data,
  handleBack,
  handleChange,
  handleNext,
}) => {
  const [activeStep, setActiveStep] = useState(0); // 0 product, 1 usage, 2 symptoms
  const [showProductsInfo, setShowProductsInfo] = useState(false);

  const handlePrev = () => {
    if (activeStep === 0) {
      handleBack();
    } else {
      setActiveStep((activeStep) => activeStep - 1);
    }
  };
  const _handleNext = () => {
    if (activeStep === 2) {
      handleNext();
    } else {
      setActiveStep((activeStep) => activeStep + 1);
    }
  };

  const renderStep = () => {
    if (activeStep === 0) {
      return (
        <Product
          connected={connected}
          userData={userData}
          data={data}
          handleChange={handleChange}
          handlePrev={handlePrev}
          handleNext={_handleNext}
          handleShowProductsInfo={() => setShowProductsInfo(true)}
        ></Product>
      );
    } else if (activeStep === 1) {
      return (
        <Usage
          data={data}
          handleChange={handleChange}
          handlePrev={handlePrev}
          handleNext={_handleNext}
        ></Usage>
      );
    } else {
      return (
        <Symptoms
          data={data}
          handleChange={handleChange}
          handlePrev={handlePrev}
          handleNext={_handleNext}
        ></Symptoms>
      );
    }
  };

  if (showProductsInfo) {
    return (
      <ProductsInfo
        handleClose={() => setShowProductsInfo(false)}
      ></ProductsInfo>
    );
  }

  return (
    <Grid container spacing={2} className="stepper">
      <Grid item xs={12}>
        <Stepper activeStep={activeStep}>
          {[
            "Données pre-questionnaire 1",
            "Données pre-questionnaire 2",
            "Questionnaire",
          ].map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <Trans>{label}</Trans>
                </StepLabel>
                <StepLabel
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                >
                  {activeStep === index ? <Trans>{label}</Trans> : ""}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {renderStep()}
      </Grid>
    </Grid>
  );
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { resources } from './locales';

const availableLanguages = ['en', 'fr'];

i18n
  .use(Backend) // load translations using http
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    whitelist: availableLanguages,
    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },

    fallbackLng: "fr",

    resources,
    defaultNS: "app",
  });

export default i18n;

import { Box, Button, Grid, MenuItem, Radio, TextField } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export const ProfessionForm = ({ data, handleChange, handleNext }) => {
  useTranslation();

  const handleChangeInput = (e) => {
    switch (e.target.id) {
      case "proSanteTrue":
        handleChange({ ...data, proSante: true });
        break;
      case "proSanteFalse":
        handleChange({ ...data, proSante: false });
        break;
      case "proSanteAutre":
        handleChange({ ...data, proSanteAutre: e.target.value });
    }
  };

  const handleChangeDropdown = (e) => {
    handleChange({ ...data, proSanteLabel: e.target.value });
  };

  return (
    <Grid>
      <p>
        <Trans>Êtes-vous un professionnel de santé ?</Trans>
      </p>
      <Grid className="inputRadioRow">
        <label className="robotoFont">
          <Radio
            id="proSanteTrue"
            checked={data.proSante === true}
            onChange={handleChangeInput}
          />
          <Trans>Oui</Trans>
        </label>
        <label className="robotoFont">
          <Radio
            id="proSanteFalse"
            checked={data.proSante === false}
            onChange={handleChangeInput}
          />
          <Trans>Non</Trans>
        </label>
      </Grid>
      {data.proSante === true ? (
        <Grid className="formRowDouble" sx={{ marginTop: "24px" }}>
          <TextField
            fullWidth
            id="proSanteLabel"
            select
            value={data.proSanteLabel}
            onChange={handleChangeDropdown}
            label={<Trans>Profession</Trans>}
          >
            <MenuItem value="Médecin">
              <Trans>Médecin</Trans>
            </MenuItem>
            <MenuItem value="Chirurgien-Dentiste">
              <Trans>Chirurgien-Dentiste</Trans>
            </MenuItem>
            <MenuItem value="Pharmacien">
              <Trans>Pharmacien</Trans>
            </MenuItem>
            <MenuItem value="Sage-Femme">
              <Trans>Sage-Femme</Trans>
            </MenuItem>
            <MenuItem value="Autre">
              <Trans>Autre</Trans>
            </MenuItem>
          </TextField>
          {data.proSanteLabel === "Autre" ? (
            <TextField
              fullWidth
              id="proSanteAutre"
              type="text"
              value={data.proSanteAutre}
              label={<Trans>Précisez</Trans>}
              onChange={handleChangeInput}
            />
          ) : null}
        </Grid>
      ) : null}
      <Box className="bottomBtn">
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={
            data.proSante === null ||
            (data.proSante && data.proSanteLabel === "") ||
            (data.proSante &&
              data.proSanteLabel === "Autre" &&
              data.proSanteAutre === "")
          }
        >
          <Trans>Suivant</Trans>
        </Button>
      </Box>
    </Grid>
  );
};

import { PDFDocument } from 'pdf-lib';
import moment from 'moment';

  
export async function DispositifPDF(data) {
    // Fetch the PDF with form fields
    const formUrl = '/files/sampleDM.pdf';
    const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

    // Load the PDF with form fields
    const pdfDoc = await PDFDocument.load(formBytes);

    // Get two text fields from the form
    const form = pdfDoc.getForm(); 
   
    data.emetteur.date !== undefined ? form.getTextField("Date_envoi").setText(moment(data.emetteur.date).format("DD/MM/YYYY")) : form.getTextField("Date_envoi").setText(moment().format())
    form.getTextField("Nom_prénom_Emetteur").setText(data.emetteur.nom);
    data.emetteur.patient ? form.getTextField("Qualité_Emetteur").setText("Patient(e)") : form.getTextField("Qualité_Emetteur").setText("")
    form.getTextField("Adresse_Emetteur").setText(data.emetteur.adresse);
    form.getTextField("Code_postal_Emetteur").setText(data.emetteur.codePostal);
    form.getTextField("Commune_Emetteur").setText(data.emetteur.commune);
    form.getTextField("Email_Emetteur").setText(data.emetteur.email);
    form.getTextField("Téléphone_Emetteur").setText(data.emetteur.telephone);
    form.getTextField("Fax_Emetteur").setText(data.emetteur.fax);

    data.emetteur.etablissement ? form.getCheckBox("Statut_Emetteur0").check() : form.getCheckBox("Statut_Emetteur0").uncheck()
    data.emetteur.association ? form.getCheckBox("Statut_Emetteur1").check() : form.getCheckBox("Statut_Emetteur1").uncheck()
    form.getCheckBox("Statut_Emetteur2").uncheck();
    form.getCheckBox("Statut_Emetteur3").uncheck();

    if (data.emetteur.signalement) {
      form.getCheckBox("Emetteur_correspondant1").check();
      form.getCheckBox("Emetteur_correspondant0").uncheck();
    } else {
      form.getCheckBox("Emetteur_correspondant0").check();
      form.getCheckBox("Emetteur_correspondant1").uncheck();
    }

    form.getTextField("Dénomination_commune_DM").setText(data.dispoMed.denominationDM);
    form.getTextField("Dénomination_commerciale").setText(data.dispoMed.denominationComDM);
    form.getTextField("N_de_série_ou_de_lot").setText(data.dispoMed.numSerieLot);
    form.getTextField("Version_logicielle").setText(data.dispoMed.vLogicielle);
    form.getTextField("Fournisseur").setText(data.dispoMed.nomFournisseur + '\n' + data.dispoMed.adrFournisseur);
    form.getTextField("Code_postal_Fournisseur").setText(data.dispoMed.cpFournisseur);
    form.getTextField("Commune_Fournisseur").setText(data.dispoMed.cpFournisseur);
    form.getTextField("Fabricant").setText(data.dispoMed.nomFabricant + '\n' + data.dispoMed.adrFabricant);
    form.getTextField("Code_postal_Fabricant").setText(data.dispoMed.cpFabricant);
    form.getTextField("Commune_Fabricant").setText(data.dispoMed.comFabricant);

    data.incident.date !== undefined ? form.getTextField("Date_de_survenue").setText(moment(data.incident.date).format("DD/MM/YYYY").toString()) : form.getTextField("Date_de_survenue").setText(moment().format("DD/MM/YYYY").toString())

    form.getTextField("Lieu_de_survenue").setText(data.incident.lieu);
    form.getTextField("Utilisateur_à_contacter").setText(data.incident.contact);
    form.getTextField("Circonstances_de_survenue").setText(data.incident.circonstances);
    form.getTextField("Situation_de_signalement").setText(data.incident.situation);
    form.getTextField("Conséquences_cliniques").setText(data.incident.consequences);
    form.getTextField("Mesures_conservatoires").setText(data.incident.mesures);

    if (data.incident.fabInform) {
      form.getCheckBox("Information_du_fournisseur1").check();
      // form.getCheckBox("Information_du_fournisseur0").uncheck();
    } else {
      // form.getCheckBox("Information_du_fournisseur0").check();
      form.getCheckBox("Information_du_fournisseur1").uncheck();
    }

    return await pdfDoc.save();
}

export async function CosmetiquePDF(data) {
  // Fetch the PDF with form fields
  const formUrl = '/files/sampleCs.pdf';
  const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  // Load the PDF with form fields
  const pdfDoc = await PDFDocument.load(formBytes);
  const form = pdfDoc.getForm(); 
 

  if (data.notificateur !== undefined) {
    if (data.notificateur.profession === 'Médecin') { form.getCheckBox("Profession_Notificateur0").check(); }
    else if (data.notificateur.profession === "Pharmacien") { form.getCheckBox("Profession_Notificateur1").check(); }
    else if (data.notificateur.profession === "Chirurgien-Dentiste") { form.getCheckBox("Profession_Notificateur2").check(); }
    else if (data.notificateur.profession === "Autre") { form.getTextField("Profession_NotificateurAutre").setText(data.notificateur.professionAutre); }
    else form.getTextField("Profession_NotificateurAutre").setText(data.notificateur.profession);

    form.getTextField("Notificateur_Nom").setText(data.notificateur.nom);
    form.getTextField("Notificateur_Adresse").setText(data.notificateur.adresse + '\n' + data.notificateur.codePostal + ' ' + data.notificateur.commune);
    form.getTextField("Notificateur_Email").setText(data.notificateur.email);
    form.getTextField("Notificateur_Telephone").setText(data.notificateur.telephone);
    form.getTextField("Notificateur_Telecopie").setText();
    data.notificateur.date !== undefined ? form.getTextField("Notificateur_Date").setText(moment(data.notificateur.date).format("DD/MM/YYYY").toString()) : form.getTextField("Notificateur_Date").setText(moment().format("DD/MM/YYYY").toString())  
  }

  if (data.utilisation !== undefined) {
    data.utilisation.dateFirstUse !== undefined ? form.getTextField("Utilisation_Date_Debut").setText(moment(data.utilisation.dateFirstUse).format("DD/MM/YYYY").toString()) : form.getTextField("Utilisation_Date_Debut").setText(moment().format("DD/MM/YYYY").toString())  
    data.utilisation.dateEffetInde !== undefined ? form.getTextField("Utilisation_Date_Effet").setText(moment(data.utilisation.dateEffetInde).format("DD/MM/YYYY").toString()) : form.getTextField("Utilisation_Date_Effet").setText(moment().format("DD/MM/YYYY").toString())  

    form.getTextField("Utilisation_Rythme").setText(data.utilisation.ryhtm + ' par ' + data.utilisation.uniteRythm);
    
    data.utilisation.proUse ? form.getCheckBox("Exposition_Professionnel").check() : form.getCheckBox("Exposition_Professionnel").uncheck()
    data.utilisation.missUse ? form.getCheckBox("Exposition_Mesusage").check() : form.getCheckBox("Exposition_Mesusage").uncheck()
  }

  if (data.consequences !== undefined) {
    data.consequences.consultPharmacien ? form.getCheckBox("Consequences_Pharmacien").check() : form.getCheckBox("Consequences_Pharmacien").uncheck()
    data.consequences.consultMedecin ? form.getCheckBox("Consequences_Medecin").check() : form.getCheckBox("Consequences_Medecin").uncheck()
    data.consequences.consultDentiste ? form.getCheckBox("Consequences_Dentiste").check() : form.getCheckBox("Consequences_Dentiste").uncheck()
    data.consequences.geneSociale ? form.getCheckBox("Consequences_Gene_Sociale").check() : form.getCheckBox("Consequences_Gene_Sociale").uncheck()
    data.consequences.hospitalisation ? form.getCheckBox("Consequences_Hospitalisation").check() : form.getCheckBox("Consequences_Hospitalisation").uncheck()
    data.consequences.sequelles ? form.getCheckBox("Consequences_Sequelles").check() : form.getCheckBox("Consequences_Sequelles").uncheck()
    data.consequences.autre ? form.getCheckBox("Consequences_Autres").check() : form.getCheckBox("Consequences_Autres").uncheck()
    data.consequences.arretTravail ? form.getCheckBox("Consequences_Arret_Travail").check() : form.getCheckBox("Consequences_Arret_Travail").uncheck()
    data.consequences.intervMedicale ? form.getCheckBox("Consequences_IMU").check() : form.getCheckBox("Consequences_IMU").uncheck()
    form.getTextField("Consequences_Gene_SocialeTexte").setText(data.consequences.geneTexte);
    form.getTextField("Consequences_IMUTexte").setText(data.consequences.intervMedText);
    form.getTextField("Consequences_AutresTexte").setText();
  }
  if (data.descEffets !== undefined) {
    form.getTextField("Description_Effet").setText(data.descEffets.textEffets);
  }
  if (data.utilisateur !== undefined) {
    form.getTextField("Utilisateur_Nom").setText(data.utilisateur.name);
    form.getTextField("Utilisateur_Prenom").setText(data.utilisateur.surname);
    data.utilisateur.birthdate !== undefined ? form.getTextField("Utilisateur_Date_Naissance").setText(moment(data.utilisateur.birthdate).format("DD/MM/YYYY").toString()) : form.getTextField("Utilisateur_Date_Naissance").setText(moment().format("DD/MM/YYYY").toString())  
    form.getTextField("Utilisateur_Profession").setText(data.utilisateur.profession);
    
    if (data.utilisateur.sex === "Femme") {
      form.getCheckBox("Utilisateur_SexeF").check();
      form.getCheckBox("Utilisateur_SexeM").uncheck();
      if (data.utilisateur.pregnancy) {
        form.getCheckBox("Utilisateur_Grossesse1").check();
      } else if (!data.utilisateur.pregnancy) {
        form.getCheckBox("Utilisateur_Grossesse0").check();
      }
    } else {
      form.getCheckBox("Utilisateur_SexeM").check();
      form.getCheckBox("Utilisateur_SexeF").uncheck();
      form.getCheckBox("Utilisateur_Grossesse1").uncheck();
      form.getCheckBox("Utilisateur_Grossesse0").uncheck();
    }
  }

  if (data.localisation !== undefined) {
    data.localisation.zoneProduit ? form.getCheckBox("Localisation_Zone_Application").check() : form.getCheckBox("Localisation_Zone_Application").uncheck()
    data.localisation.distanceProduit ? form.getCheckBox("Localisation_A_Distance").check() : form.getCheckBox("Localisation_A_Distance").uncheck()
    data.localisation.peau ? form.getCheckBox("Localisation_Peau").check() : form.getCheckBox("Localisation_Peau").uncheck()
    data.localisation.ongles ? form.getCheckBox("Localisation_Ongles").check() : form.getCheckBox("Localisation_Ongles").uncheck()
    data.localisation.cheveux ? form.getCheckBox("Localisation_Cheveux").check() : form.getCheckBox("Localisation_Cheveux").uncheck()
    data.localisation.dents ? form.getCheckBox("Localisation_Dents").check() : form.getCheckBox("Localisation_Dents").uncheck()
    data.localisation.yeux ? form.getCheckBox("Localisation_Yeux").check() : form.getCheckBox("Localisation_Yeux").uncheck()
    data.localisation.muqueuses ? form.getCheckBox("Localisation_Muqueuses").check() : form.getCheckBox("Localisation_Muqueuses").uncheck()
    data.localisation.oculaire ? form.getCheckBox("Localisation_Oculaire").check() : form.getCheckBox("Localisation_Oculaire").uncheck()
    data.localisation.auriculaire ? form.getCheckBox("Localisation_Auriculaire").check() : form.getCheckBox("Localisation_Auriculaire").uncheck()
    data.localisation.nasale ? form.getCheckBox("Localisation_Nasale").check() : form.getCheckBox("Localisation_Nasale").uncheck()
    data.localisation.buccale ? form.getCheckBox("Localisation_Buccale").check() : form.getCheckBox("Localisation_Buccale").uncheck()
    data.localisation.pharyngée ? form.getCheckBox("Localisation_Pharyngee").check() : form.getCheckBox("Localisation_Pharyngee").uncheck()
    data.localisation.pulmonaire ? form.getCheckBox("Localisation_Pulmonaire").check() : form.getCheckBox("Localisation_Pulmonaire").uncheck()
    data.localisation.génitale ? form.getCheckBox("Localisation_Genitale").check() : form.getCheckBox("Localisation_Genitale").uncheck()
    data.localisation.anale ? form.getCheckBox("Localisation_Anale").check() : form.getCheckBox("Localisation_Anale").uncheck()
    data.localisation.respisratoire ? form.getCheckBox("Signes_Respiratoires").check() : form.getCheckBox("Signes_Respiratoires").uncheck()
    data.localisation.digestifs ? form.getCheckBox("Signes_Digestifs").check() : form.getCheckBox("Signes_Digestifs").uncheck()
    data.localisation.généraux ? form.getCheckBox("Signes_Generaux").check() : form.getCheckBox("Signes_Generaux").uncheck()
    data.localisation.neurologique ? form.getCheckBox("Signes_Neurologiques").check() : form.getCheckBox("Signes_Neurologiques").uncheck()

    form.getTextField("Signes_AutresTexte").setText(data.localisation.otherInfos);
  }

  if (data.produitCosmetique !== undefined) {
    form.getTextField("Produit_Num_Lot").setText(data.produitCosmetique.numLot);
    form.getTextField("Produit_Nom").setText(data.produitCosmetique.nomComplet);
    form.getTextField("Produit_Societe").setText(data.produitCosmetique.societe);
    form.getTextField("Produit_Usage").setText(data.produitCosmetique.fonctionProd);
    form.getTextField("Produit_Lieu").setText(data.produitCosmetique.lieuAchat);
  }

  return await pdfDoc.save();
}

export async function MedicamentPDF(data) {
  // Fetch the PDF with form fields
  const formUrl = '/files/sampleMe.pdf';
  const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  // Load the PDF with form fields
  const pdfDoc = await PDFDocument.load(formBytes);
  const form = pdfDoc.getForm(); 

  if (data.userdata !== undefined) {
    form.getTextField("Nom_Patient").setText(data.userdata.name);
    form.getTextField("Prénom_Patient").setText(data.userdata.surname);
    if (data.userdata.sex === "Femme") {
      form.getCheckBox("Sexe_PatientF").check();
      form.getCheckBox("Sexe_PatientM").uncheck();
    } else if (data.userdata.sex === "Homme") {
      form.getCheckBox("Sexe_PatientM").check();
      form.getCheckBox("Sexe_PatientF").uncheck();
    }
    form.getTextField("Poids").setText(data.userdata.weight);
    form.getTextField("Taille").setText(data.userdata.heigth);
    data.userdata.birthdate !== undefined ? form.getTextField("Date_Naissance").setText(moment(data.userdata.birthdate).format("DD/MM/YYYY").toString()) : form.getTextField("Date_Naissance").setText("")  
  }

  if (data.newbornData !== undefined) {
    data.newbornData.newborn ? form.getCheckBox("Nouveau_Né").check() : form.getCheckBox("Nouveau_Né").uncheck()
    data.newbornData.direct ? form.getCheckBox("Nouveau_Né_directement").check() : form.getCheckBox("Nouveau_Né_directement").uncheck()
    data.newbornData.mother ? form.getCheckBox("Nouveau_Né_via_allaitement").check() : form.getCheckBox("Nouveau_Né_via_allaitement").uncheck()
    data.newbornData.pregnancy ? form.getCheckBox("Nouveau_Né_mère").check() : form.getCheckBox("Nouveau_Né_mère").uncheck()
    data.newbornData.father ? form.getCheckBox("Nouveau_Né_père").check() : form.getCheckBox("Nouveau_Né_père").uncheck()
  }

  // Cadre Identification Non renseigné dans les forms : Identification_PS

  if (data.background !== undefined) {
    form.getTextField("Antécédents_Patient").setText(data.background);
  }

  if (data.medicaments !== undefined && data.medicaments.length !== undefined && data.medicaments.length > 0) {
    data.medicaments.forEach((med, index) => {
      if (index < 6) {
        form.getTextField("Médicament_" + (index + 1)).setText(med.name);
        form.getTextField("Voie_Aministration_" + (index + 1)).setText(med.administrationRoute);
        form.getTextField("Posologie_" + (index + 1)).setText(med.posology + " " + med.uniteDuree);
        form.getTextField("Indication_" + (index + 1)).setText(med.indication);
        med.startDate !== undefined ? form.getTextField("Date_Début_" + (index + 1)).setText(moment(med.startDate).format("DD/MM/YYYY").toString()) : form.getTextField("Date_Début_" + (index + 1)).setText("")  
        med.endDate !== undefined && med.endDate !== null ? form.getTextField("Date_Fin_" + (index + 1)).setText(moment(med.endDate).format("DD/MM/YYYY").toString()) : form.getTextField("Date_Fin_" + (index + 1)).setText("")  

      }
    })
  }
  if (data.lotMedicamentVaccin !== undefined) {
    form.getTextField("Numéro_Lot_MDS").setText(data.lotMedicamentVaccin.numLot);
    form.getTextField("Service_Hospitalier").setText(data.lotMedicamentVaccin.service);
    form.getTextField("Pharmacie").setText(data.lotMedicamentVaccin.pharmacie);
  }
  if (data.produitSanguin !== undefined) {
    form.getTextField("Numéro_Lot_PSL").setText(data.produitSanguin.numLot);
    if (data.produitSanguin.hemoDecl === true) {
      form.getCheckBox("Hémovigilance1").check();
      form.getCheckBox("Hémovigilance0").uncheck();
    } else if (data.produitSanguin.hemoDecl === false) {
      form.getCheckBox("Hémovigilance1").uncheck();
      form.getCheckBox("Hémovigilance0").check();
    } else {
      form.getCheckBox("Hémovigilance1").uncheck();
      form.getCheckBox("Hémovigilance0").uncheck();
    }
  }
  if (data.effets !== undefined) {
    form.getTextField("Dept_Survenue").setText(data.effets.dept);
    form.getTextField("Description_effet_indésirable").setText(data.effets.description);
    form.getTextField("Durée_effet").setText(data.effets.duree);
    form.getTextField("Liste_durées").setText("par" + data.effets.uniteDuree);

    data.effets.date !== undefined ? form.getTextField("Date_Survenue").setText(moment(data.effets.date).format("DD/MM/YYYY").toString()) : form.getTextField("Date_Survenue").setText("")  
  }
  if (data.gravite !== undefined) {
    data.gravite.hospitalisation ? form.getCheckBox("Gravité_Hospitalisation").check() : form.getCheckBox("Gravité_Hospitalisation").uncheck()
    data.gravite.incapacite ? form.getCheckBox("Gravité_Incapacité_Invalidité").check() : form.getCheckBox("Gravité_Incapacité_Invalidité").uncheck()
    data.gravite.pronosticVital ? form.getCheckBox("Gravité_MJPVital").check() : form.getCheckBox("Gravité_MJPVital").uncheck()
    data.gravite.deces ? form.getCheckBox("Gravité_Décès").check() : form.getCheckBox("Gravité_Décès").uncheck()
    data.gravite.anomalies ? form.getCheckBox("Gravité_Anomalie_Malformation_congénitale").check() : form.getCheckBox("Gravité_Anomalie_Malformation_congénitale").uncheck()
    data.gravite.situationGrave ? form.getCheckBox("Gravité_Autre_situation").check() : form.getCheckBox("Gravité_Autre_situation").uncheck()
    data.gravite.aucun ? form.getCheckBox("Guérison_Non_Grave").check() : form.getCheckBox("Guérison_Non_Grave").uncheck()
  }

  if (data.evolution !== undefined) {
    data.evolution.guerison ? form.getCheckBox("Evolution_Guérison").check() : form.getCheckBox("Evolution_Guérison").uncheck()
    data.evolution.sansSequelle ? form.getCheckBox("Guérison_evolution0").check() : form.getCheckBox("Guérison_evolution0").uncheck()
    data.evolution.avecSequelle ? form.getCheckBox("Guérison_evolution1").check() : form.getCheckBox("Guérison_evolution1").uncheck()
    data.evolution.enCours ? form.getCheckBox("Guérison_evolution2").check() : form.getCheckBox("Guérison_evolution2").uncheck()
    data.evolution.nonRetabli ? form.getCheckBox("Evolution_Sujet_NEE").check() : form.getCheckBox("Evolution_Sujet_NEE").uncheck()
    data.evolution.deces ? form.getCheckBox("Evolution_Décès").check() : form.getCheckBox("Evolution_Décès").uncheck()
    data.evolution.decesDuAEffet ? form.getCheckBox("Décès_effet0").check() : form.getCheckBox("Décès_effet0").uncheck()
    data.evolution.decesEffetContribue ? form.getCheckBox("Décès_effet1").check() : form.getCheckBox("Décès_effet1").uncheck()
    data.evolution.decesSansRapport ? form.getCheckBox("Décès_effet2").check() : form.getCheckBox("Décès_effet2").uncheck()
    data.evolution.inconnu ? form.getCheckBox("Evolution_Inconnue").check() : form.getCheckBox("Evolution_Inconnue").uncheck()

  }
  return await pdfDoc.save();
}

export async function ComplementPDF(data) {
  // Fetch the PDF with form fields
  const formUrl = '/files/sampleCA.pdf';
  const formBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  // Load the PDF with form fields
  const pdfDoc = await PDFDocument.load(formBytes);
  const form = pdfDoc.getForm(); 
  if (data.declarant !== undefined) {
    form.getTextField("Déclarant_Nom").setText(data.declarant.nom);
    form.getTextField("Déclarant_Email").setText(data.declarant.email);
    form.getTextField("Déclarant_Adresse").setText(data.declarant.adresse);
    form.getTextField("Déclarant_Code_Postal").setText(data.declarant.codePostal);
    form.getTextField("Déclarant_Ville").setText(data.declarant.commune);
    form.getTextField("Déclarant_Telephone").setText(data.declarant.telephone);

    if (data.declarant.profession === "Médecin") {
      form.getCheckBox("Profession_Déclarant0").check();
      form.getCheckBox("Profession_Déclarant4").uncheck();
    } else if (data.declarant.profession === "Pharmacien") {
      form.getCheckBox("Profession_Déclarant1").check();
      form.getCheckBox("Profession_Déclarant4").uncheck();
    } else if (data.declarant.profession === "Fabricant") {
      form.getCheckBox("Profession_Déclarant2").check();
      form.getCheckBox("Profession_Déclarant4").uncheck();
    } else if (data.declarant.profession === "Particulier") {
      form.getCheckBox("Profession_Déclarant3").check();
      form.getCheckBox("Profession_Déclarant4").uncheck();
    } else {
      form.getCheckBox("Profession_Déclarant4").check();
      form.getTextField("Profession_DéclarantAutre").setText(data.declarant.profession);
    }
  }
  if (data.consommateur !== undefined) {
    form.getTextField("Consommateur_Nom").setText(data.consommateur.nom);
    form.getTextField("Consommateur_Prenom").setText(data.consommateur.prenom);
    data.consommateur.birthdate !== undefined ? form.getTextField("Consommateur_Annee").setText(moment(data.consommateur.birthdate).format("YYYY").toString()) : form.getTextField("Consommateur_Annee").setText("")  


    form.getTextField("Consommateur_Poids").setText(data.consommateur.weigth);
    form.getTextField("Consommateur_Taille").setText(data.consommateur.height);
    form.getTextField("Consommateur_Profession").setText(data.consommateur.profession);

    if (data.consommateur.sex === "Femme") {
      form.getCheckBox("Consommateur_SexeF").check();
      form.getCheckBox("Consommateur_SexeH").uncheck();
      if (data.consommateur.pregnancy) {
        form.getCheckBox("Consommateur_Grossesse1").check();
      } else if (!data.consommateur.pregnancy) {
        form.getCheckBox("Consommateur_Grossesse0").check();
      } else {
        form.getCheckBox("Consommateur_GrossesseN").check();
      }
    } else {
      form.getCheckBox("Consommateur_SexeH").check();
      form.getCheckBox("Consommateur_SexeF").uncheck();
      form.getCheckBox("Consommateur_Grossesse1").uncheck();
      form.getCheckBox("Consommateur_Grossesse0").uncheck();
      form.getCheckBox("Consommateur_GrossesseN").uncheck();
    }

    if (data.consommateur.antecedents === true) {
      form.getCheckBox("Consommateur_Antecedents1").check();
      form.getTextField("Consommateur_AntecedentsTexte").setText(data.consommateur.antecedentsText);
    } else if (data.consommateur.antecedents === false) {
      form.getCheckBox("Consommateur_Antecedents0").check();
    } else {
      form.getCheckBox("Consommateur_AntecedentsN").check();
    }
  }

  if (data.produitAlim !== undefined && data.produitAlim.length !== undefined && data.produitAlim.length > 0) {
    data.produitAlim.forEach((prod, index) => {

      if (index < 3) {
        form.getTextField("Produit_Nom" + (index + 1)).setText(prod.nomCom);
        form.getTextField("Produit_Fabricant" + (index + 1)).setText(prod.fabricant);
        form.getTextField("Produit_Num_Lot" + (index + 1)).setText(prod.numLot);
        form.getTextField("Produit_Motif" + (index + 1)).setText(prod.motifConso);

        prod.doseConso !== undefined && prod.doseConso !== null ? form.getTextField("Produit_Dose" + (index + 1)).setText(prod.doseConso + " " + prod.uniteDuree) : form.getTextField("Produit_Dose" + (index + 1)).setText();

        prod.dateDebutConso !== undefined ? form.getTextField("Produit_Date_Debut" + (index + 1)).setText(moment(prod.startDate).format("DD/MM/YYYY").toString()) : form.getTextField("Produit_Date_Debut" + (index + 1)).setText("")  

        if (prod.lieuAchat === "autre") {
          form.getCheckBox("Produit_Lieu_Autre" + (index + 1)).check();
          form.getTextField("Produit_Lieu_AutreTexte" + (index + 1)).setText(prod.lieuAchatAutre);
        } else if (prod.lieuAchat === "internet") {
          form.getCheckBox("Produit_Lieu_Internet" + (index + 1)).check();
        } else if (prod.lieuAchat === "pharmacie") {
          form.getCheckBox("Produit_Lieu_Pharmacie" + (index + 1)).check();
        } else {
          form.getCheckBox("Produit_Lieu_NonConnu" + (index + 1)).check();
        }

        if (prod.arretProduit === true) {
          form.getCheckBox("Produit_Arret1_" + (index + 1)).check();
          prod.dateFinConso !== undefined && prod.dateFinConso !== null ? form.getTextField("Produit_Date_Fin" + (index + 1)).setText(moment(prod.dateFinConso).format("DD/MM/YYYY").toString()) : form.getTextField("Produit_Date_Fin" + (index + 1)).setText("")  

          if (prod.disparitionEffets === true) {
            form.getCheckBox("Produit_Disparition_Effet1_" + (index + 1)).check();
          } else if (prod.disparitionEffets === false) {
            form.getCheckBox("Produit_Disparition_Effet0_" + (index + 1)).check();
          } else {
            form.getCheckBox("Produit_Disparition_EffetN_" + (index + 1)).check();
          }
          if (prod.repriseConso === true) {
            form.getCheckBox("Produit_Reprise1_" + (index + 1)).check();
            if (prod.retourEffet === true) {
              form.getCheckBox("Produit_Retour_Effet1_" + (index + 1)).check();
            } else if (prod.retourEffet === false) {
              form.getCheckBox("Produit_Retour_Effet0_" + (index + 1)).check();
            } else {
              form.getCheckBox("Produit_Retour_EffetN_" + (index + 1)).check();
            }
          } else if (prod.repriseConso === false) {
            form.getCheckBox("Produit_Reprise0_" + (index + 1)).check();
          } else {
            form.getCheckBox("Produit_RepriseN_" + (index + 1)).check();
          }
        } else {
          if (prod.arretProduit === false) form.getCheckBox("Produit_Arret0_" + (index + 1)).check();
          else form.getCheckBox("Produit_ArretN_" + (index + 1)).check();
        }
      }
      
    })
  }
  if (data.descEffets !== undefined) {
    form.getTextField("Effet_Delai").setText(data.descEffets.delai + " " + data.descEffets.uniteDuree);
    form.getTextField("Effet_Symptomes").setText(data.descEffets.symptomes);
    form.getTextField("Effet_Examens").setText(data.descEffets.examens);
    form.getTextField("Effet_TraitementTexte").setText(data.descEffets.traitementText);
    form.getTextField("Evolution_Chronologie").setText(data.descEffets.evolutionChrono);

    data.descEffets.dateDebut !== undefined ? form.getTextField("Effet_Date").setText(moment(data.descEffets.dateDebut).format("DD/MM/YYYY").toString()) : form.getTextField("Effet_Date").setText("")  

    if (data.descEffets.priseCharge === "urgences") {
      form.getCheckBox("Effet_PriseEnCharge2").check();
    } else if (data.descEffets.priseCharge === "medecin") {
      form.getCheckBox("Effet_PriseEnCharge1").check();
    } else if (data.descEffets.priseCharge === "hospitalisation") {
      form.getCheckBox("Effet_PriseEnCharge3").check();
    } else if (data.descEffets.priseCharge === "non") {
      form.getCheckBox("Effet_PriseEnCharge0").check();
    }

    if (data.descEffets.traitement === true) {
      form.getCheckBox("Effet_Traitement1").check();
    } else if (data.descEffets.traitement === false) {
      form.getCheckBox("Effet_Traitement0").check();
    } else {form.getCheckBox("Effet_TraitementN").check();}


    if (data.descEffets.evolution === "general") {
      form.getCheckBox("Evolution0").check();
    } else if (data.descEffets.evolution === "enCours") {
      form.getCheckBox("Evolution1").check();
    } else if (data.descEffets.evolution === "non") {
      form.getCheckBox("Evolution2").check();
    } else if (data.descEffets.evolution === "aggravation") {
      form.getCheckBox("Evolution3").check();
    }
  }

  if (data.consoAssociees !== undefined) {
    if (data.consoAssociees.medicaments === true) {
      form.getCheckBox("Conso_Medicaments1").check();
      form.getTextField("Conso_MedicamentsTexte").setText(data.consoAssociees.medicamentsText);
    } else if (data.consoAssociees.medicaments === false) {
      form.getCheckBox("Conso_Medicaments0").check();
    } else {
      form.getCheckBox("Conso_MedicamentsN").check();
    }
    if (data.consoAssociees.alcool === true) {
      form.getCheckBox("Conso_Alcool1").check();
      // form.getTextField("Conso_Alcool_Frequence").setText(data.consoAssociees.alcoolText);
    } else if (data.consoAssociees.alcool === false) {
      form.getCheckBox("Conso_Alcool0").check();
    } else {
      form.getCheckBox("Conso_AlcoolN").check();
    }

    if (data.consoAssociees.tabac === true) {
      form.getCheckBox("Conso_Tabac1").check();
      form.getTextField("Conso_Tabac_Frequence").setText(data.consoAssociees.tabacQte);
    } else if (data.consoAssociees.tabac === false) {
      form.getCheckBox("Conso_Tabac0").check();
    } else {
      form.getCheckBox("Conso_TabacN").check();
    }

    if (data.consoAssociees.cafe === true) {
      form.getCheckBox("Conso_Cafe1").check();
      // form.getTextField("Conso_Cafe_Frequence").setText(data.consoAssociees.cafeQte);
    } else if (data.consoAssociees.cafe === false) {
      form.getCheckBox("Conso_Cafe0").check();
    } else {
      form.getCheckBox("Conso_CafeN").check();
    }

    if (data.consoAssociees.cannabis === true) {
      form.getCheckBox("Conso_Cannabis1").check();
      // form.getTextField("Conso_Cannabis_Frequence").setText(data.consoAssociees.cannabisQte);
    } else if (data.consoAssociees.cannabis === false) {
      form.getCheckBox("Conso_Cannabis0").check();
    } else {
      form.getCheckBox("Conso_CannabisN").check();
    }

    if (data.consoAssociees.autre === true) {
      form.getCheckBox("Conso_Autre1").check();
      // form.getTextField("Conso_Autre_Frequence").setText(data.consoAssociees.autreQte);
    } else if (data.consoAssociees.autre === false) {
      form.getCheckBox("Conso_Autre0").check();
    } else {
      form.getCheckBox("Conso_AutreN").check();
    }
  }
  if (data.commentaires !== undefined) {
    form.getTextField("Commentaires").setText(data.commentaires.commentaires);
  }

  return await pdfDoc.save();
}
    




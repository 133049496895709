import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { ProfessionForm } from "./Profession";
import { Questionnaire } from "./questionnaire";

export const AdverseEffectForm = ({ connected, userData, handleSubmit }) => {
  useTranslation();

  const navigate = useNavigate();

  const [activePage, setActivePage] = useState(0); // 0 profession, 1, questionaire

  const [professionData, setProfessionData] = useState({
    // profession info
    proSante: null,
    proSanteLabel: "",
    proSanteAutre: "",
  });

  const [questionaireData, setQuestionaireData] = useState({
    // product info
    typeProduit: "",
    nomProduit: "",
    marqueProduit: "",
    substanceActive1: "",
    substanceActive2: "",
    substanceActive3: "",

    // product stat
    nbReports: null,
    nbReportsVigicare: null,

    //usage info
    sex: "",
    trancheAge: "",
    datePrise: null,
    dateEffet: null,
    typeEffet: [null],
    descEffet: "",
    hospitalisation: false,
    incapacite: false,
    handicap: false,
    anomalies: false,
    risqueVital: false,
    aucun: false,

    // questionnaire
    symptomesProduit: null,
    effetApresAdmin: null,
    arretEffet: null,
    retourEffet: null,
    autresCauses: null,
    retourEffetAutreProduit: null,
    produitAnalyses: null,
    reactionDose: null,
    reactionSimilaire: null,
    confirmationMedicale: null,
    selectedDrug: null,
  });

  useEffect(() => {
    if (userData) {
      setActivePage(1);

      if (!userData.proSante) {
        setProfessionData((prevData) => ({ ...prevData, proSante: false }));
      } else {
        let proData = { ...professionData };

        if (
          userData.proSanteLabel !== null &&
          ![
            "Médecin",
            "Chirurgien-Dentiste",
            "Pharmacien",
            "Sage-Femme",
            "Autre",
          ].includes(userData.proSanteLabel)
        ) {
          proData.proSante = true;
          proData.proSanteLabel = "Autre";
          proData.proSanteAutre = userData.proSanteLabel;
        } else {
          proData.proSante = true;
          proData.proSanteLabel = userData.proSanteLabel;
        }

        setProfessionData(proData);
      }
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeProfession = (data) => {
    setProfessionData(data);
  };
  const handleNextProfession = () => {
    setActivePage(1);
  };

  const handleBackQuestionnaire = useCallback(() => {
    if (!connected) {
      setActivePage(0);
    } else {
      // go to home
      navigate("/");
    }
  }, [connected, navigate, setActivePage]);

  const handleChangeQuestionnaire = (data) => {
    setQuestionaireData(data);
  };
  const handleNextQuestionnaire = () => {
    handleSubmit({ ...professionData, ...questionaireData });
  };

  const renderForm = () => {
    if (activePage === 0) {
      return (
        <ProfessionForm
          data={professionData}
          handleChange={handleChangeProfession}
          handleNext={handleNextProfession}
        />
      );
    } else {
      return (
        <Questionnaire
          connected={connected}
          userData={userData}
          data={questionaireData}
          handleChange={handleChangeQuestionnaire}
          handleBack={handleBackQuestionnaire}
          handleNext={handleNextQuestionnaire}
        />
      );
    }
  };

  return renderForm();
};

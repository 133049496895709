import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import { Button, TextField, MenuItem, Grid, Box  } from '@mui/material';

class Pays extends React.Component {
    constructor(props) {
        super(props);
        this.state = { country: 'France', type: props.location.pathname ?? null };

        this.selectCountry = this.selectCountry.bind(this);
        this.displayLink = this.displayLink.bind(this);
    }

    selectCountry(ev) {
        if (ev.target.value !== undefined) this.setState({ country: ev.target.value });
    }

    displayLink() {
        if (this.state.type === '/declaration/medicament') {
            switch (this.state.country) {
                case "Allemagne" : return "https://nebenwirkungen.bund.de/SiteGlobals/Forms/nebenwirkungen/01-person/person-node.html;jsessionid=A7C57BAC0F389D3E974E90536986EDEA.intranet182";
                case "Belgique" : return "https://www.fagg.be/nl/bijwerking";
                case "Angleterre" : return "https://yellowcard.mhra.gov.uk/";
                case "Canada" : return "https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting/drug.html";
                case "États-Unis" : return "https://www.accessdata.fda.gov/scripts/medwatch/index.cfm";
                case "Suisse" : return "https://www.swissmedic.ch/swissmedic/fr/home/medicaments-a-usage-humain/surveillance-du-marche/pharmacovigilance/formulaires.html";
                default: return null;
            }    
        } else if (this.state.type === '/declaration/cosmetique') {
            switch (this.state.country) {
                case "Allemagne" : return "https://www.bvl.bund.de/SharedDocs/Downloads/03_Verbraucherprodukte/Kosmetik/SUE_Checkliste_de.html";
                case "Belgique" : return "https://www.health.belgium.be/fr/fiche-courte-de-declaration-de-reactions-indesirables-suite-lutilisation-dun-produit-cosmetique";
                case "Angleterre" : return "https://yellowcard.mhra.gov.uk/";
                case "Canada" : return "https://www.healthycanadians.gc.ca/apps/radar/CPS-SPC-0001.08.C.html";
                case "États-Unis" : return "https://www.accessdata.fda.gov/scripts/medwatch/index.cfm";
                case "Suisse" : return null;
                default: return null;
            }    
        } else if (this.state.type === '/declaration/complement') {
            switch (this.state.country) {
                case "Allemagne" : return null;
                case "Belgique" : return null;
                case "Angleterre" : return "https://yellowcard.mhra.gov.uk/";
                case "Canada" : return "https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting/natural-health-products.html";
                case "États-Unis" : return "https://www.accessdata.fda.gov/scripts/medwatch/index.cfm";
                case "Suisse" : return null;
                default: return null;
            }    
        } else if (this.state.type === '/declaration/dispositif') {
            switch (this.state.country) {
                case "Allemagne" : return "https://www.bfarm.de/DE/Medizinprodukte/Antraege-und-Meldungen/Vorkommnis-melden/_node.html";
                case "Belgique" : return "https://www.afmps.be/fr/humain/produits_de_sante/dispositifs_medicaux/materiovigilance";
                case "Angleterre" : return "https://yellowcard.mhra.gov.uk/";
                case "Canada" : return "https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting/medical-device.html";
                case "États-Unis" : return "https://www.accessdata.fda.gov/scripts/medwatch/index.cfm";
                case "Suisse" : return null;
                default: return null;
            }    
        }
        return null;
        
    }

    onClickNext() {
        this.props.handler(); // Next Page
    }

    render() {
        const { country } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <Grid>
            <p className="pageTitle"><Trans>Pays</Trans></p>
            <Grid className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </Grid>
            
            <p className="pageText"><Trans>Dans quel pays vous situez vous pour la déclaration d'effets indésirables?</Trans></p>
            <TextField
                select
                label={<Trans>Pays *</Trans>}
                className="paysDropdown"
                value={country}
                onChange={this.selectCountry}
                placeholder="Sélectionner votre pays"
            >
                <MenuItem value={'Allemagne'}><Trans>Allemagne</Trans></MenuItem>
                <MenuItem value={'Belgique'}><Trans>Belgique</Trans></MenuItem>
                <MenuItem value={'Angleterre'}><Trans>Angleterre</Trans></MenuItem>
                <MenuItem value={'Canada'}><Trans>Canada</Trans></MenuItem>
                <MenuItem value={'États-Unis'}><Trans>États-Unis</Trans></MenuItem>
                <MenuItem value={'France'}><Trans>France</Trans></MenuItem>
                <MenuItem value={'Suisse'}><Trans>Suisse</Trans></MenuItem>
            </TextField>
            <p className="requiredMessage"><Trans>* Champs obligatoires</Trans></p>
            {country !== '' && country !== "France" ? 
                <Grid>
                    <p className="infoText"><Trans>La déclaration d'effets indésirables ne peut se faire via Vigicare.</Trans></p>
                    <p className="infoText"> 
                    {this.displayLink() === null ?
                        <Trans>Nous n'avons pas pu retrouver le lien de déclaration de ce produit dans votre pays, rapprochez vous de votre agence nationale de santé.</Trans>
                        : <>
                            <Trans>Vigicare vous invite à cliquer sur ce lien suivant afin de réaliser votre déclaration : </Trans>
                            <a href={`${this.displayLink()}`} target="_blank" rel="noreferrer">{this.displayLink()}</a>
                        </>
                    }
                    </p>
                </Grid> 
            : null}
            <Box className='bottomBtnForm'>
                {country === '' ? <Button variant="contained" disabled>{"SUIVANT "}</Button> : 
                    <>{country !== "France" ? <Link className="linkButton" to='/'><Button variant="contained" color="blue"><Trans>Retour Accueil</Trans></Button></Link>
                        : <Button variant="contained" color="blue" onClick={() => this.onClickNext()}><Trans>Suivant</Trans></Button>
                    }</>}
            </Box>
        </Grid>
    }
}

export default withRouter(withTranslation()(Pays));

function withRouter( Child ) {
    return ( props ) => {
        const location = useLocation();
        return <Child { ...props } location={location} />;
    }
}

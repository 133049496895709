import app from "./app.json";
import home from "./home.json";
import profile from "./profile.json";
import productSearch from "./productSearch.json";

const fr = {
  app,
  home,
  profile,
  productSearch
};

export default fr;

import React from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button, Box, TextField, Radio, MenuItem } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import AddIcon from '@mui/icons-material/Add';
import { CrossIcon } from '../../../styles/Icons';
import 'reactjs-popup/dist/index.css';
    
export default class ProduitAlim extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && props.data.length !== 0) {
            this.state = {
                produits: [...props.data],
                showInfo: false,
                errorIncomplete: false,
            };
        } else {
            this.state = {
                produits: [],
                showInfo: false,
                errorIncomplete: false,
            };
        }

        this.createProd = this.createProd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
        this.handleChangeDateEnd = this.handleChangeDateEnd.bind(this);
        this.handleChangeDropdownDuree = this.handleChangeDropdownDuree.bind(this);

        window.scrollTo(0, 0);
    }

    handleChangeDateStart(ev, index) {
        let prods = [...this.state.produits];
        let newProd = {...prods[index]};
        newProd.dateDebutConso = ev;
        prods[index] = newProd;

        this.setState({ produits: prods });
    }

    handleChangeDateEnd(ev, index) {
        let prods = [...this.state.produits];
        let newProd = {...prods[index]};
        newProd.dateFinConso = ev;
        prods[index] = newProd;

        this.setState({ produits: prods });
    }

    
    handleChangeDropdownDuree(ev, index) {
        let prods = [...this.state.produits];
        let newProd = {...prods[index]};
        newProd.uniteDuree = ev.target.value;
        prods[index] = newProd;

        this.setState({ produits: prods });
    }

    handleChange(ev, index) {  
        let prods = [...this.state.produits];
        let newProd = {...prods[index]};
        switch (ev.target.id) {
            case "nomCom" : { newProd.nomCom = ev.target.value; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "fabricant" : { newProd.fabricant = ev.target.value; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "numLot" : { newProd.numLot = ev.target.value; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "motifConso" : { newProd.motifConso = ev.target.value; prods[index] = newProd;  this.setState({ produits: prods  }); break; }
            case "doseConso" : { newProd.doseConso = ev.target.value; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "lieuText" : { newProd.lieuAchatAutre = ev.target.value; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "lieuPharm" : {  newProd.lieuAchat = "pharmacie"; newProd.lieuAchatAutre = ""; prods[index] = newProd; this.setState({ produits: prods });  break; }
            case "lieuInt" : { newProd.lieuAchat = "internet"; newProd.lieuAchatAutre = ""; prods[index] = newProd; this.setState({ produits: prods }); break; } 
            case "lieuAutre" : { newProd.lieuAchat = "autre"; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "lieuNSP" : { newProd.lieuAchat = "nsp"; newProd.lieuAchatAutre = ""; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "arretTrue" : { newProd.arretProduit = true  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "arretFalse" : { newProd.arretProduit = false  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "arretNSP" : { newProd.arretProduit = null  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "dspTrue" : { newProd.disparitionEffets = true  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "dspFalse" : { newProd.disparitionEffets = false  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "dspNull" : { newProd.disparitionEffets = null  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "repriseTrue" : { newProd.repriseConso = true  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "repriseFalse" : { newProd.repriseConso = false  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "repriseNull" : { newProd.repriseConso = null  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "retourTrue" : { newProd.retourEffet = true  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "retourFalse" : { newProd.retourEffet = false  ; prods[index] = newProd; this.setState({ produits: prods }); break; }
            case "retourNull" : { newProd.retourEffet = null; prods[index] = newProd; this.setState({ produits: prods }); break; }
            default: break;
        }
    }

    createProd() {
        let newProd = {
            nomCom: '',
            fabricant: '',
            numLot: '',
            motifConso: '',
            doseConso: '',
            uniteDuree: 'par jour', 
            lieuAchat: '',
            lieuAchatAutre: '',
            dateDebutConso: null,
            dateFinConso: null,
            arretProduit: null,
            disparitionEffets: null,
            repriseConso: null,
            retourEffet: null,
        }
        this.setState({ produits: [...this.state.produits, newProd] });
    }

    removeProd(id) {
        this.setState({produits: this.state.produits.filter(function(prod, index) { 
            if (index !== id) return prod;
        })});
    }

    onSubmit() {
        const { produits } = this.state;
        if (produits[0] === undefined) {

        } else {
            const prod = produits[0];
            
            if (prod !== undefined && prod.name !== "" && prod.posology !== "" && prod.numLot !== "" 
            && prod.administrationRoute !== "" && prod.dateDebutConso !== null
            && prod.dateDebutConso.isBefore() && prod.dateDebutConso.isValid()) {
                if (prod.dateFinConso !== null){
                    if(prod.dateFinConso.isBefore() && prod.dateFinConso.isValid() && prod.dateDebutConso.isSameOrBefore(prod.dateFinConso)) this.props.handler(this.state.produits);
                } else this.props.handler(this.state.produits);
            }
        }
    }


    render() {
        const { produits } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        
        return <Grid>
            <p className="pageTitle">{'Produits alimentaire(s) suspecté(s)'}</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            <p> Ajouter le ou les médicaments à l'origine de l'effet indésirable : </p>
            <div>
                {produits.map(
                    (prod, i) => { 
                        return (
                            <Grid key={i}>
                                {i > 0 ? <hr className='separator' /> : null}
                                <div className='medTitle'>
                                    <p className='medTitleP'>PRODUIT {i + 1}</p> 
                                    <p onClick={() => this.removeProd(i)}><CrossIcon /></p>
                                </div>
                                
                                <form className='medForm'>                                   
                                    <TextField id="nomCom" type="text" value={prod.nomCom} onChange={(e) => this.handleChange(e, i)} label="Nom commercial *" />
                                    <TextField id="fabricant" type="text" value={prod.fabricant} onChange={(e) => this.handleChange(e, i)} label="Fabricant *" />
                                    <TextField id="numLot" type="text" value={prod.numLot} onChange={(e) => this.handleChange(e, i)} label="Numéro de lot *" />
                                    <TextField id="motifConso" type="text" value={prod.motifConso} onChange={(e) => this.handleChange(e, i)} label="Motif de la consommation" />


                                    <Grid className='formRowDouble'>
                                        <TextField fullWidth id="doseConso" type="number" value={prod.doseConso} onChange={(e) => this.handleChange(e, i)} label="Dose de la consommation *" />        
                                        <TextField select value={prod.uniteDuree} onChange={(e) => this.handleChangeDropdownDuree(e, i)} sx={{ minWidth: '45%', textAlign: 'end' }}>
                                            <MenuItem value="par jour">par jour</MenuItem>
                                            <MenuItem value="par semaine">par semaine</MenuItem>
                                            <MenuItem value="par mois">par mois</MenuItem>
                                        </TextField>
                                    </Grid>    

                                    
                                    <Grid>
                                        <p className="robotoFontB">Lieu d'achat *</p>
                                        <Grid className='inputRadioRowGrid'>
                                            
                                            <label className='robotoFont'>
                                                <Radio id="lieuPharm" checked={prod.lieuAchat === "pharmacie"} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Pharmacie'}
                                            </label>
                                            <label className='robotoFont'>
                                                <Radio id="lieuInt" checked={prod.lieuAchat === "internet"} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Internet'}
                                            </label>
                                        </Grid>
                                        <Grid className='inputRadioRowGrid'>
                                            <label className='robotoFont'>
                                                <Radio id="lieuAutre" checked={prod.lieuAchat === "autre"} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Autre'}
                                            </label>
                                            <label className='robotoFont'>
                                                <Radio id="lieuNSP" checked={prod.lieuAchat === "nsp"} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Je ne sais pas'}
                                            </label>
                                        </Grid>
                                        {prod.lieuAchat === "autre" ? 
                                            <TextField fullWidth id="lieuText" type="text" value={prod.lieuAchatAutre} onChange={(e) => this.handleChange(e, i)} label="Si autre, précisez" />
                                        : null }

                                    </Grid>
                                        
                                    <DesktopDatePicker id="dateDebutConso" inputFormat="DD/MM/YYYY" value={prod.dateDebutConso} onChange={(e) => this.handleChangeDateStart(e, i)} label="Date de début de consommation *" renderInput={(params) => {return <TextField {...params} error={prod.dateDebutConso !== null && (!prod.dateDebutConso.isBefore() || !prod.dateDebutConso.isValid())} />}} />
                                    <Grid>
                                        <p className="robotoFontB">Arrêt du produit</p>
                                        <Grid className='inputRadioRow'>
                                            <label className='robotoFont'>
                                                <Radio id="arretTrue" checked={prod.arretProduit === true} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Oui'}
                                            </label>     
                                            <label className='robotoFont'>
                                                <Radio id="arretFalse" checked={prod.arretProduit === false} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Non'}
                                            </label>
                                            <label className='robotoFont'>
                                                <Radio id="arretNSP" checked={prod.arretProduit === null} onChange={(e) => this.handleChange(e, i)} />
                                                {'  Je ne sais pas'}
                                            </label>
                                        </Grid>
                                    </Grid>
                                    {prod.arretProduit === true ?
                                        <DesktopDatePicker id="dateFinConso" inputFormat="DD/MM/YYYY" disabled={prod.arretProduit !== true} value={prod.dateFinConso} onChange={(e) => this.handleChangeDateEnd(e, i)} label="Date de fin de la consommation" renderInput={(params) => {return <TextField {...params} error={prod.dateFinConso !== null && prod.arretProduit === true && (!prod.dateFinConso.isBefore() || !prod.dateFinConso.isValid() || !(prod.dateDebutConso !== null && prod.dateFinConso.isSameOrAfter(prod.dateDebutConso)))} />}} />
                                    : null}
                                    {prod.arretProduit === true ?
                                        <>
                                        <Grid>
                                            <p className="robotoFontB">Disparition/regression des effets à l'arret</p>
                                            <Grid className='inputRadioRow'>
                                                <label className='robotoFont'>
                                                    <Radio id="dspTrue" checked={prod.disparitionEffets === true} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Oui'}
                                                </label>     
                                                <label className='robotoFont'>
                                                    <Radio id="dspFalse" checked={prod.disparitionEffets === false} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Non'}
                                                </label>
                                                <label className='robotoFont'>
                                                    <Radio id="dspNull" checked={prod.disparitionEffets === null} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Je ne sais pas'}
                                                </label>
                                            </Grid>
                                        </Grid>
                                    
                                        <Grid>
                                            <p className="robotoFontB">Reprise de la consommation du produit</p>
                                            <Grid className='inputRadioRow'>
                                                <label className='robotoFont'>
                                                    <Radio id="repriseTrue" checked={prod.repriseConso === true} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Oui'}
                                                </label>     
                                                <label className='robotoFont'>
                                                    <Radio id="repriseFalse" checked={prod.repriseConso === false} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Non'}
                                                </label>
                                                <label className='robotoFont'>
                                                    <Radio id="repriseNull" checked={prod.repriseConso === null} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Je ne sais pas'}
                                                </label>
                                            </Grid>
                                        </Grid>
                                        </>
                                    : null}
                                    {prod.repriseConso === true ?
                                        <Grid>
                                            <p className="robotoFontB">Si oui, l'effet indesirable est-il réapparu ?</p>
                                            <Grid className='inputRadioRow'>
                                                <label className='robotoFont'>
                                                    <Radio id="retourTrue" checked={prod.retourEffet === true} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Oui'}
                                                </label>     
                                                <label className='robotoFont'>
                                                    <Radio id="retourFalse" checked={prod.retourEffet === false} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Non'}
                                                </label>
                                                <label className='robotoFont'>
                                                    <Radio id="retourNull" checked={prod.retourEffet === null} onChange={(e) => this.handleChange(e, i)} />
                                                    {'  Je ne sais pas'}
                                                </label>
                                            </Grid>
                                        </Grid>
                                    : null }
                                </form>                                
                            </Grid>
                        );
                    }
                )}
                { produits.length > 0 ? <p className="requiredMessage"><Trans>* Champs obligatoires</Trans></p> : null}
                {produits.length !== undefined && produits.length < 3 ?
                    <Box className="boxBtn" marginTop="24px">
                        <Button variant="contained" color="blue"  onClick={() => this.createProd()}>
                            <AddIcon fontSize="small" sx={{ paddingRight: "12px" }} />
                            {'AJOUTER UN PRODUIT'}
                        </Button>
                    </Box>
                : null}
                
                
                {produits[0] !== undefined && produits[0].nomCom !== ''  && produits[0].numLot !== "" 
                && produits[0].dateDebutConso !== null && produits[0].fabricant !== '' && produits[0].doseConso !== '' && produits[0].lieuAchat !== '' 
                    ?   <Grid textAlign={'end'} marginBottom="24px">
                            <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                        </Grid>
                    :   <Grid textAlign={'end'} marginBottom="24px">
                            <Button variant="contained" disabled>{"SUIVANT"}</Button>
                        </Grid>
                }

            </div>
        </Grid>
    }
};
import React from 'react';
import Popup from 'reactjs-popup';
import { Trans } from 'react-i18next';

import { Grid, Button, TextField, Radio } from '@mui/material';
import { CrossIcon } from '../../../styles/Icons';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
    
export default class Incident extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    date: props.data.date !== undefined ? props.data.date : null,
                    lieu: props.data.lieu !== undefined ? props.data.lieu : '',
                    contact: props.data.contact !== undefined ? props.data.contact : '',
                    circonstances: props.data.circonstances !== undefined ? props.data.circonstances : '',
                    consequences: props.data.consequences !== undefined ? props.data.consequences : '',
                    mesures: props.data.mesures !== undefined ? props.data.mesures : '',
                    situation: props.data.situation !== undefined ? props.data.situation : '',
                    fabInform: props.data.fabInform !== undefined ? props.data.fabInform : null,
                },
                errorIncomplete: false,
                showInfo: false,
            };
        } else {
            this.state = {
                data: {
                    date: null,
                    lieu: '',
                    contact: '',
                    circonstances: '',
                    consequences: '',
                    mesures: '',
                    situation: '',
                    fabInform: null,
                },
                errorIncomplete: false,
                showInfo:false,
            }
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        window.scrollTo(0, 0);
    }

    handleChangeDate(ev) {
        this.setState({ data: { ...this.state.data, date: ev } });
    }      

    handleChange(ev) {
        const { data } = this.state;
        switch (ev.target.id) {
            case "lieu" : this.setState({ data: { ...data, lieu: ev.target.value  } }); break;
            case "contact" : this.setState({ data: { ...data, contact: ev.target.value } }); break;
            case "circonstances" : this.setState({ data: { ...data, circonstances: ev.target.value }  }); break;
            case "consequences" : this.setState({ data: { ...data, consequences: ev.target.value } }); break;
            case "mesures" : this.setState({ data: { ...data, mesures: ev.target.value  } }); break;
            case "situation" : this.setState({ data: { ...data, situation: ev.target.value } }); break;
            case "true" : this.setState({ data: { ...data, fabInform: true } }); break;
            case "false" : this.setState({ data: { ...data, fabInform: false } }); break;
            default: break;
        }
    }

    displayInfos() {
        this.setState(prevState => ({ showInfo: !prevState.showInfo }));
    }
    
    closePopup() {
        this.setState({ showInfo: false });
    }

    onSubmit() {
        const { data } = this.state;

        if (data.date !== null && data.date.isValid() && data.date.isBefore() && data.lieu !== "" 
        && data.circonstances !== "" && data.consequences !== "" && data.mesures !== "" 
        && data.situation !== "" && data.situation.match(/^[A-Za-z]$/) !== null && data.fabInform !== null) {
            this.props.handler(this.state.data);
        } else {
            window.scrollTo(0, 0);
            this.setState({ errorIncomplete: true });
        }
    }

    render() {
        const { data, errorIncomplete, showInfo } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";

        return <>
            <p className="pageTitle">L'incident ou le risque d'incident</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            {errorIncomplete ? <p className='errorText'>Champs manquants</p> : null}

            <div className='inputForm'>
                <DesktopDatePicker id="date" inputFormat="DD/MM/YYYY" value={data.date} onChange={this.handleChangeDate} label="Date de survenue *" renderInput={(params) => {return <TextField {...params}  error={errorIncomplete && (data.date === null || !data.date.isValid() || !data.date.isBefore())} />}} />
                <TextField id="lieu" type="text" value={data.lieu} onChange={this.handleChange} error={errorIncomplete && data.lieu === ""}  label="Lieu de survenue *" />
                <TextField fullWidth multiline minRows={2} id="contact" type="text" value={data.contact} onChange={this.handleChange} label="Si nécessaire : nom, qualité, téléphone, fax de la personne à contacter" />
                <TextField fullWidth multiline minRows={4} id="circonstances" value={data.circonstances} onChange={this.handleChange} error={errorIncomplete && data.circonstances === ""} label="Circonstances de survenue / Description des faits *" />
                <TextField fullWidth multiline minRows={4} id="consequences" value={data.consequences} onChange={this.handleChange} error={errorIncomplete && data.consequences === ""} label="Conséquences cliniques constatées *" />
                <TextField fullWidth multiline minRows={4} id="mesures" value={data.mesures} onChange={this.handleChange} error={errorIncomplete && data.mesures === ""} label="Mesures conservatoires et actions entreprises *" />
                
                <div className="inputSmallRow">
                    <TextField id="situation" className="inputFormUserdataLast input80" type="text" value={data.situation} onChange={this.handleChange} error={errorIncomplete && (data.situation === "" || data.situation.match(/^[A-Za-z]$/) === null)}  label="Situation de signalement (de A à N) *" />
                    <svg className="infoIncidents" onClick={() => {this.displayInfos()}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z" fill="#AFAFAF"/>
                    </svg>
                </div>
                
                <Grid>
                    <p className='robotoFontB'>Le fabricant ou fournisseur est-il informé de l'incident ou risque d'incident ? *</p>
                    {errorIncomplete && data.fabInform === null ? <p className='errorText'>Donnée manquante</p> : null}
                    <Grid className='inputRadioRow'>
                        <label className='robotoFont'>
                            <Radio id="true" checked={data.fabInform === true} onChange={this.handleChange} />
                            {'  Oui'}
                        </label>     
                        <label className='robotoFont'>
                            <Radio id="false" checked={data.fabInform === false} onChange={this.handleChange} />
                            {'  Non'}
                        </label>        
                    </Grid>
                </Grid>
            </div>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>
            <Grid textAlign={'end'} marginBottom="24px" marginTop="24px">
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
            </Grid>

            <Popup open={showInfo} position="center" onClose={() => this.closePopup()} contentStyle={{minWidth: '80%'}}>
                <div className='infoPanel'>
                    <div className='infoTitle'>
                        <p><b>Aide au signalement des incidents</b></p>
                        <p onClick={() => this.displayInfos()}><CrossIcon /></p>
                    </div>
                </div>
            </Popup>
        </>
    }
}
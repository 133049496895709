import React from 'react';
import { Grid, Button, Box, TextField } from '@mui/material';
    
export default class Antecedents extends React.Component {
    constructor (props) {
        super(props);

        if (props.data !== undefined && props.data !== "") {
            this.state = {
                text: props.data,
            }
        } else {
            this.state = {
                text: "",
            };
        }
        

        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }

    handleChange(ev) {
        if (ev.target.value !== undefined) this.setState({ text: ev.target.value })
    }

    onSubmit() {
        this.props.handler(this.state.text);
    }

    render() {
        const { text } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <Grid>
                <p className="pageTitle">Antécédents du patient</p>
                <Grid className="lineVectors">
                    <hr className="lineVector" style={{width}} />
                    <hr className="lineVectorFull" />
                </Grid>
                <p>Antécédents du patient / Facteurs ayant pu favoriser la survenue de l'effet indésirable :</p>
                <div className='inputForm'>
                    <TextField multiline rows={6} value={text} onChange={this.handleChange}  />
                </div>
                <Box className="bottomBtnForm">
                    <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{text === "" ? "PASSER" : "CONFIRMER"}</Button>
                </Box>
        </Grid>
    }
};
import axios from 'axios';
import { config } from '../config';

const instance = axios.create({
  withCredentials: true
});

const BASE_URL = config.apiBaseUrl

export const fetchNbImput = async (produit) => {
    return instance.get(`${BASE_URL}/imputabilite/${produit}`);
}

export const fetchTypesImput = async (produit) => {
    return instance.get(`${BASE_URL}/imputabilite/types/${produit}`);
}

export const fetchStatsImput = async (data) => {
    return instance.get(`${BASE_URL}/imputabilite/stats/${data.type}/${data.produit}`);
}

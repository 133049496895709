import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { validateToken, updateRecoverPassword } from '../../api/user';
import { useMutation, useQuery } from '@tanstack/react-query';


import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, ThemeProvider } from '@mui/material';
import { Theme } from '../../styles/styles';
import HomeIcon from '@mui/icons-material/Home';

import './auth.css';

import { Trans, withTranslation } from "react-i18next";   
import { Visibility, VisibilityOff } from '@mui/icons-material';


class MotdepasseNouveau extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordVerif: '',
            showPassword: false,
            showPasswordVerif: false,
            tokenValid: null,
        }; 

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClickShowPasswordVerif = this.handleClickShowPasswordVerif.bind(this);

        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if (this.props.getValidateToken.isError && this.state.tokenValid !== false) {
            this.setState({ tokenValid: false });
        } else if (this.props.getValidateToken.isSuccess && this.state.tokenValid !== true) {
            this.setState({ tokenValid: true });
        }
    }

    handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword});
    }

    handleClickShowPasswordVerif() {
        this.setState({ showPasswordVerif: !this.state.showPasswordVerif});
    }

    handleChange(ev) {
        switch (ev.target.id) {
            case "passwordVerif" : this.setState({ passwordVerif: ev.target.value  }); break;
            case "password" : this.setState({ password: ev.target.value  }); break;
            default: break;
        }
    }

    render() {
        const { password, passwordVerif, showPasswordVerif, showPassword, tokenValid } = this.state;
        const { mutationUpdatePassword } = this.props; 
        return (
            <ThemeProvider theme={Theme}>
                    <Grid className='mainApp'>
                        <header className='headerSignIn'>
                            <div></div>
                            <Grid>
                                <p><Trans>Vigicare</Trans></p>
                            </Grid>
                            <Link to='/'>
                                <HomeIcon color="white" />
                            </Link>
                        </header>
                        <div className='mainSignIn'>
                            {mutationUpdatePassword.isSuccess ? 
                                <Grid>
                                    <p className='authTitle'><Trans>Mot de passe modifié avec succès</Trans></p>
                                    <Box className='bottomBtnQuestionnaire bottomBtnAuth'>
                                        <Link to='/auth'><Button variant="outlined" sx={{ textTransform: 'unset !important', marginBottom: '16px', padding: '10px 0px 10px 0px', textAlign:'center'  }}><Trans>Connectez vous</Trans></Button></Link>
                                    </Box>
                                </Grid>
                                : <>{mutationUpdatePassword.isError ? 
                                        <Grid>
                                            <p className='authTitle'><Trans>Erreur dans la modification du mot de passe</Trans></p>
                                            <p><Trans>Merci de réitérer votre demande de réinitialisation</Trans></p>
                                            <Box className='bottomBtnQuestionnaire bottomBtnAuth'>
                                                <Link to='/auth'><Button variant="outlined" sx={{ textTransform: 'unset !important', marginBottom: '16px', padding: '10px 0px 10px 0px'  }}><Trans>Connexion</Trans></Button></Link>
                                            </Box>
                                        </Grid>
                                    : <>{tokenValid === null ? <> 
                                        <Grid>
                                            <Grid  sx={{ textAlign: 'center', paddingTop: '1em' }}>
                                                <CircularProgress /> 
                                            </Grid>
                                            
                                            <Box className='bottomBtnQuestionnaire bottomBtnAuth'>
                                                <Link to='/'><Button variant="contained"><Trans>Retour à l'accueil</Trans></Button></Link>
                                            </Box>
        
                                        </Grid></>
                                        : (tokenValid ?
                                            <>
                                        <p className='authTitle'><Trans>Créez votre nouveau mot de passe</Trans></p>
                                    
                                        <TextField 
                                                    fullWidth id="password" 
                                                    type={showPassword ? "text" : "password"} 
                                                    value={password} onChange={this.handleChange} 
                                                    label={<Trans>Votre nouveau mot de passe</Trans>} 
                                                    sx={{ marginTop: '12px' }} 
                                                    error={password !== '' && password.match(/^(?=.*[A-Z])(?=.*[.!@#$&*])(?=.*[0-9]).{8,}$/) === null}
                                                    helperText={<Trans>Votre mot de passe doit contenir au moins 8 caractères, dont au moins 1 majuscule, 1 chiffre et 1 symbole (.!@#$&*)</Trans>} 
                                                    InputProps={{
                                                        endAdornment: 
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    color={"#DDDDDD"}
                                                                    onClick={this.handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>,
                                                    }}
                                                />
        
                                        <TextField 
                                            fullWidth id="passwordVerif"
                                            type={showPasswordVerif ? "text" : "password"}  
                                            value={passwordVerif} 
                                            onChange={this.handleChange} 
                                            error={passwordVerif !== '' && password !== passwordVerif}
                                            label={<Trans>Vérifiez votre mot de passe</Trans>} 
                                            sx={{ marginTop: '12px' }} 
                                            InputProps={{
                                                endAdornment: 
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            color={"#DDDDDD"}
                                                            onClick={this.handleClickShowPasswordVerif}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                            }}
                                        />
        
                                        <Box className='bottomBtnQuestionnaire bottomBtnAuth'>  
                                            <Button disabled={passwordVerif === '' || password === '' || passwordVerif !== password || password.match(/^(?=.*[A-Z])(?=.*[.!@#$&*])(?=.*[0-9]).{8,}$/) === null} onClick={() => mutationUpdatePassword.mutate({password})} variant="contained" color="blue" sx={{ textTransform: 'unset !important', marginBottom: '16px', padding: '10px 0px 10px 0px'  }}><Trans>Valider</Trans></Button>
                                        </Box>
                                    </> : <>
                                        <Grid>
                                            <p className='authTitleCenter'><Trans>Identification invalide</Trans></p> 
                                            <Box className='bottomBtnQuestionnaire bottomBtnAuth'>
                                                <Link to='/'><Button variant="contained"><Trans>Retour à l'accueil</Trans></Button></Link>
                                            </Box>
                                        </Grid>
                                    </>)}</>
                                }</>
                            }
                            
                        </div>
                    </Grid> 
                    
            </ThemeProvider>
        )
    }; 
};

export default withRouter(withQuery(withTranslation()(MotdepasseNouveau)));

function withRouter( Child ) {
    return ( props ) => {
        const params = useParams();
        const navig = useNavigate();
        return <Child { ...props } params ={ params } navigation={navig} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const token = props.params.token;
        const getValidateToken = useQuery(['validate-token'], () => validateToken(token).then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationUpdatePassword = useMutation({
            mutationFn: (data) => {
              return updateRecoverPassword(data, token).then((r) => {return r.data}).catch(e =>  {throw e});
            },
        });
        return <Child { ...props } mutationUpdatePassword ={mutationUpdatePassword} getValidateToken={getValidateToken} />;
    }
}

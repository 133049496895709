import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReturnArrow, VigicareLogoWhite } from '../../styles/Icons';

import { ThemeProvider, Grid, Button  } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Theme } from '../../styles/styles';
import Popup from 'reactjs-popup';

import { useMutation, useQuery } from '@tanstack/react-query';
import { newDeclaration } from '../../api/history';
import { getUser } from '../../api/user';

import Infos from '../sharedComponents/Infos';
import Pays from '../sharedComponents/Pays';
import DeclarationValidePDF from '../sharedComponents/DeclarationValidePDF';

import Consequences from './forms/Consequences';
import DescEffets from './forms/DescEffets';
import Localisation from './forms/Localisation';
import Notificateur from './forms/Notificateur';
import ProduitCosmetique from './forms/ProduitCosmetique';
import Utilisateur from './forms/Utilisateur';
import Utilisation from './forms/Utilisation';

import { neutralizeBack } from '../../misc/navigation';

import './cosmetique.css' 
import moment from 'moment';

class Cosmetique extends React.Component {
    constructor (props) {
        super(props);
        let userdata = {};
        if (props.queryUser.isSuccess && this.props.queryUser.data !== undefined && this.props.queryUser.data.length > 0) {
            userdata = {
                userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode, 
                    dateFin: this.props.queryUser.data[0].dateFin,
            }
        };
        if (props.params !== undefined && props.params !== null) {
            this.state = {
                activePage: 0,
                infoPage: false,
                notificateur: {},
                utilisateur: {
                    sex: props.params.user.sex,
                },
                produitCosmetique: {
                    nomComplet: props.params.produit.name,
                    societe: props.params.produit.nomFabricant,
                },
                utilisation: {
                    dateFirstUse: props.params.effets.datePrise !== '' ? moment(props.params.effets.datePrise) : null,
                    dateEffetInde: props.params.effets.dateEffet !== '' ? moment(props.params.effets.dateEffet) : null,
                },
                localisation: {},
                descEffets: {
                    textEffets: props.params.effets.descEffet,
                },
                consequences: {},
                userdata,
                connected: false,
                showAlert: false,
                closeConfirm: false,
                loading: false,
            };
        } else {
            this.state = {
                activePage: 0,
                infoPage: false,
                notificateur: {},
                utilisateur: {},
                produitCosmetique: {},
                utilisation: {},
                localisation: {},
                descEffets: {},
                consequences: {},
                userdata,
                connected: false,
                showAlert: false,
                closeConfirm: false,
                loading: false,
            };
        }

        this.handlerPage = this.handlerPage.bind(this);
        this.handlerNotificateur = this.handlerNotificateur.bind(this);
        this.handlerUtilisateur = this.handlerUtilisateur.bind(this);
        this.handlerProduitCosmetique = this.handlerProduitCosmetique.bind(this);
        this.handlerUtilisation = this.handlerUtilisation.bind(this);
        this.handlerLocalisation = this.handlerLocalisation.bind(this);
        this.handlerDescEffets = this.handlerDescEffets.bind(this);
        this.handlerConsequences = this.handlerConsequences.bind(this);

        this.onClickInfo = this.onClickInfo.bind(this);
        this.openAlert = this.openAlert.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.backHome = this.backHome.bind(this);
    }

    componentDidMount() {
        this.props.queryUser.refetch();
    }

    componentDidUpdate(pP, pS) {
        if (!this.state.loading) {
            this.setState({ loading: true, showAlert: false }, () => {neutralizeBack(this.openAlert)})
        }
        if (!pS.connected && this.props.queryUser.data !== undefined && this.props.queryUser.data[0] !== undefined) {
            this.setState({
                connected: true, 
                userdata: {
                    userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode,
                    dateFin:  this.props.queryUser.data[0].dateFin
            }});
        }
        if (!pS.closeConfirm && this.state.closeConfirm) {
            this.props.navigation('/');
        }
    }

    handlerPage() {
        let newPage = this.state.activePage + 1;
        this.setState({ activePage: newPage, showAlert: false })
    }
    handlerNotificateur(data){
        let newPage = this.state.activePage + 1;
        this.setState({ notificateur: data, activePage: newPage })
    }
    handlerUtilisateur(data){
        let newPage = this.state.activePage + 1;
        this.setState({ utilisateur: data, activePage: newPage })
    }
    handlerProduitCosmetique(data){
        let newPage = this.state.activePage + 1;
        this.setState({ produitCosmetique: data, activePage: newPage })
    }
    handlerUtilisation(data){
        let newPage = this.state.activePage + 1;
        this.setState({ utilisation: data, activePage: newPage })
    }
    handlerLocalisation(data){
        let newPage = this.state.activePage + 1;
        this.setState({ localisation: data, activePage: newPage })
    }
    handlerDescEffets(data){
        let newPage = this.state.activePage + 1;
        this.setState({ descEffets: data, activePage: newPage })
    }
    handlerConsequences(data){
        let newPage = this.state.activePage + 1;
        this.props.mutationCreateDeclaration.mutate({ type: "Cosmétique", nomProduit: this.state.produitCosmetique.nomComplet, effets: this.state.descEffets.textEffets });
        this.setState({ consequences: data, activePage: newPage })
    }

    
    onClickInfo() { this.setState({ infoPage: !this.state.infoPage }) }

    onClickReturn() {
        if (this.state.infoPage) this.setState({ infoPage: false })
        else if (this.state.activePage > 0) { 
            let newPage = this.state.activePage - 1;
            this.setState({ activePage: newPage })
        }
    }
    openAlert() { this.setState({ showAlert: true }) }

    closeAlert() { this.setState({ showAlert: false }) }

    backHome() {
        this.setState({ showAlert: false, closeConfirm: true });
    }

    render() {
        const { activePage, connected,showAlert } = this.state;

        return <ThemeProvider theme={Theme}>
            <Grid className='mainApp'>
                <header className='headerApp'>
                    <Grid className='headerDeclaration'>
                        {activePage === 0 || activePage === 8 ?
                            <VigicareLogoWhite />
                        : <div className='headerDeclarationLeft'>
                            <svg className="returnArrow" onClick={() => this.onClickReturn()} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <VigicareLogoWhite />
                        </div>
                        }
                        <div className='headerDeclarationCenter'>
                            <div className='headerText'>Déclaration d'effet indésirable</div>
                            <svg className="infoBtn" style={{ marginLeft: '0.25em'}} onClick={() => {this.onClickInfo()}} width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z" fill="#ffffff"/>
                            </svg>
                        </div>
                        {showAlert ?
                            <div onClick={() => this.closeAlert()}>
                                <HomeIcon color="white" />
                            </div> :
                            <div onClick={() => this.openAlert()}>
                                <HomeIcon color="white" />
                            </div>
                        }
                    </Grid>
                </header>
                <Grid className='mainDeclaration'>
                    { this.state.infoPage ? 
                        <Infos handler={this.onClickInfo}></Infos> 
                            : <>{activePage === 0 ? <Pays handler={this.handlerPage} page={activePage * 12.5 + 12.5}></Pays>
                                : <>{activePage === 1 ? <Notificateur data={this.state.notificateur} handler={this.handlerNotificateur} page={activePage * 12.5 + 12.5}></Notificateur> 
                                    : <>{activePage === 2 ? <Utilisateur data={this.state.utilisateur} handler={this.handlerUtilisateur} page={activePage * 12.5 + 12.5}></Utilisateur> 
                                        : <> {activePage === 3 ? <ProduitCosmetique data={this.state.produitCosmetique}  handler={this.handlerProduitCosmetique} page={activePage * 12.5 + 12.5 }></ProduitCosmetique> 
                                            : <> {activePage === 4 ? <Utilisation data={this.state.utilisation} handler={this.handlerUtilisation} page={activePage * 12.5 + 12.5}></Utilisation> 
                                                : <> {activePage === 5 ? <Localisation data={this.state.localisation} handler={this.handlerLocalisation} page={activePage * 12.5 + 12.5}></Localisation> 
                                                    : <> {activePage === 6 ? <DescEffets data={this.state.descEffets} handler={this.handlerDescEffets} page={activePage * 12.5 + 12.5}></DescEffets> 
                                                        : <> {activePage === 7 ? <Consequences data={this.state.consequences} handler={this.handlerConsequences} page={activePage * 12.5 + 12.5}></Consequences> 
                                                            : <> {activePage === 8 ? <DeclarationValidePDF data={this.state}></DeclarationValidePDF> 
                                                                : <>
                                                                </> }
                                                            </> }
                                                        </> }
                                                    </> }
                                                </> }
                                            </> }
                                        </>}
                                    </>}
                                </>
                            }</>
                        }
                    <Popup open={showAlert && activePage < 8} position="center" onClose={() => this.closeAlert()} contentStyle={{minWidth: '80%'}}>
                        <Grid className='infoPanel'>
                            <div className='infoTitle'>
                                <p><b>Attention</b></p>
                            </div>
                                <p>En retournant à l'accueil, vous allez perdre les informations entrées dans ces formulaires</p>
                                <p><i>Vous pouvez revenir aux formulaires précédents avec la flèche présente dans le bandeau de navigation</i></p>
                            <div className='infoTitle'>
                                <Button onClick={() => this.backHome()} variant="outlined" color="error">Retour à l'accueil</Button>
                                <Button onClick={() => this.closeAlert()} variant="contained">Je continue</Button>
                            </div>
                        </Grid>
                    </Popup>
                    <Popup open={showAlert && activePage === 8} position="center" onClose={() => this.closeAlert()} contentStyle={{minWidth: '80%'}}>
                        <Grid className='infoPanel'>
                            <div className='infoTitle'>
                                <p><b>Attention</b></p>
                            </div>
                                <p>Etes vous surs d'avoir enregistré votre déclaration avant de revenir à l'accueil ?</p>
                            <div className='infoTitle'>
                                <Button onClick={() => this.closeAlert()} variant="contained" color="error">Non</Button>
                                <Button onClick={() => this.backHome()} variant="outlined">Oui</Button>
                            </div>
                        </Grid>
                    </Popup>
                </Grid>        
            </Grid>
        </ThemeProvider>
    }
};

export default withRouter(withQuery(Cosmetique));

function withRouter( Child ) {
    return ( props ) => {
        const location = useLocation(); 
        const navigate = useNavigate(); 
        if (location.state !== null) return <Child { ...props } params={location.state} navigation={navigate} />;
        else return <Child { ...props } navigation={navigate} />;
    }
}


function withQuery( Child ) {
    return ( props ) => {
        const queryUser = useQuery(['fetch-user'], () => getUser().then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationCreateDeclaration = useMutation({
            mutationFn: (data) => {
                return newDeclaration(data).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });

        return <Child { ...props } mutationCreateDeclaration={mutationCreateDeclaration} queryUser={queryUser} />;
    }
}
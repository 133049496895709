import React from 'react';
import { Trans } from 'react-i18next';

import { Button, TextField, MenuItem, Grid, Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
  
export default class Patient extends React.Component {
    constructor (props) {
        super(props);

        if(props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data: {
                    name: props.data.name ? props.data.name : "",
                    surname: props.data.surname ? props.data.surname : "",
                    sex: props.data.sex ? props.data.sex : "",
                    weight: props.data.weight ? props.data.weight : "",
                    heigth: props.data.heigth ? props.data.heigth : "",
                    birthdate: props.data.birthdate ? props.data.birthdate : null,
                },
                errorIncomplete: false,
            }
        } else {
            this.state = {
                data: {
                    name: "",
                    surname: "",
                    sex: "",
                    weight: "",
                    heigth: "",
                    birthdate: null
                },
                errorIncomplete: false,
            }    
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        window.scrollTo(0, 0);
    }

    handleChangeDate(ev) {
        if (ev !== null) this.setState({ data: { ...this.state.data, birthdate: ev } });
        else this.setState({ data: { ...this.state.data, birthdate: '' } });
    }

    handleChangeDropdown(ev) {
        if (ev.target.value !== undefined) this.setState({ data : { ...this.state.data, sex: ev.target.value }});
    } 

    handleChange(ev) {
        switch (ev.target.id) {
            case "name" : this.setState({ data: { ...this.state.data, name: ev.target.value } }); break;
            case "surname" : this.setState({ data: { ...this.state.data, surname: ev.target.value } }); break;
            case "weight" : this.setState({ data: { ...this.state.data, weight: ev.target.value } }); break;
            case "heigth" : this.setState({ data: { ...this.state.data, heigth: ev.target.value } }); break;
            default: break;
        }
    }
    
    onSubmit() {
        const { data } = this.state;
        if (data.name.match(/^[A-Za-z]{2,}$/) !== null && data.surname.match(/^[A-Za-z]{2,}$/) !== null
        && data.sex !== "" && data.weight !== "" 
        && data.heigth !== "" && data.birthdate !== null && data.birthdate.isValid() && data.birthdate.isBefore()) {
            this.props.handler(this.state.data);
        } else {
            this.setState({ errorIncomplete: true });
        }
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <Grid>
            <p className="pageTitle">Patient traité</p>

            <Grid className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </Grid>

            {errorIncomplete ? <Box className='errorText'>Champs manquants ou incorrects</Box> : null}

            <form className='inputForm'>
                <TextField id="name" type="text" value={data.name} onChange={this.handleChange} label="Nom * (minimum 2 lettres)" error={errorIncomplete && data.name.match(/^[A-Za-z]{2,}$/) === null} />
                <TextField id="surname" type="text" value={data.surname} onChange={this.handleChange} label="Prénom * (minimum 2 lettres)" error={errorIncomplete && data.surname.match(/^[A-Za-z]{2,}$/) === null} />
                <TextField select id="sex" className="inputDropdown" value={data.sex} onChange={this.handleChangeDropdown} label="Sexe *" error={errorIncomplete && data.sex === ''} >
                    <MenuItem value={"Femme"}>Femme</MenuItem>
                    <MenuItem value={"Homme"}>Homme</MenuItem>
                </TextField>
                <Grid className="inputSmallRow">
                    <TextField id="weight" className="inputSmall" type="number" value={data.weight} onChange={this.handleChange} label="Poids * (en kg)" error={errorIncomplete && data.weight.match(/^[0-9]{1,3}$/) === null}  />
                    <TextField id="heigth" className="inputSmall" type="number" value={data.heigth} onChange={this.handleChange} label="Taille * (en m)" error={errorIncomplete && data.heigth.match(/^[0-9][\.\,][0-9]{1,2}$/) === null}  />
                </Grid>
                <DesktopDatePicker id="birthdate" inputFormat="DD/MM/YYYY" value={data.birthdate} onChange={this.handleChangeDate} label="Date de naissance *" renderInput={(params) => {return <TextField {...params} error={errorIncomplete && (data.birthdate === null || !data.birthdate.isValid() || !data.birthdate.isBefore())} />}} />
            </form>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>
            <Box className='bottomBtnForm'>
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
            </Box>
        </Grid>
    }
}
import React from 'react';
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { validateToken, recoverPassword } from '../../api/user';
import { useMutation, useQuery } from '@tanstack/react-query';


import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, ThemeProvider } from '@mui/material';
import { Theme } from '../../styles/styles';
import HomeIcon from '@mui/icons-material/Home';

import './auth.css';

import { Trans, withTranslation } from "react-i18next";   
import { Visibility, VisibilityOff } from '@mui/icons-material';


class MotdepasseOublie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            msg: '',
        }; 

        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if ((this.props.mutationRecover.isSuccess || this.props.mutationRecover.isError) && this.state.msg === '') {
            this.setState({ msg: 'Si vous avez un compte Vigicare, un email vous a été envoyé pour réinitialiser votre mot de passe.' })
        }
    }

    handleChange(ev) {
        switch (ev.target.id) {
            case "email" : this.setState({ email: ev.target.value  }); break;
            default: break;
        }
    }

    render() {
        const { email,msg } = this.state;
        const { mutationRecover } = this.props;
        return (
            <ThemeProvider theme={Theme}>
                    <Grid className='mainApp'>
                        <header className='headerSignIn'>
                            <div></div>
                            <Grid>
                                <p><Trans>Vigicare</Trans></p>
                            </Grid>
                            <Link to='/'>
                                <HomeIcon color="white" />
                            </Link>
                        </header>
                        <div className='mainSignIn'>
                            <p className='authTitle'><Trans>Mot de passe oublié</Trans></p>
                            <TextField 
                                    fullWidth id="email" 
                                    type="text" 
                                    value={email} 
                                    onChange={this.handleChange} 
                                    label={<Trans>Votre email</Trans>}
                                    sx={{ marginTop: '12px' }}  
                                    error={email !== null && email !== ''  && email.match(/^\S+@\S+\.\S+$/) === null} 
                                    helperText={email !== '' && email !== null && email.match(/^\S+@\S+\.\S+$/) === null ? (<Trans>Format d'email incorrect</Trans>)  : null}/>
                        
                            {mutationRecover.isLoading ? <Grid sx={{textAlign: 'center'}}><CircularProgress /></Grid> : null}
                            {msg !== '' && !mutationRecover.isLoading ? 
                                <Box sx={{ padding: '1em 0.5em' }}>
                                    <p>{msg}</p>
                                </Box>
                            : null}
                            
                            <Box className='bottomBtnQuestionnaire bottomBtnAuth'>  
                                <Button disabled={email === '' || email.match(/^\S+@\S+\.\S+$/) === null} onClick={() => mutationRecover.mutate({email})} variant="contained" color="blue" sx={{ textTransform: 'unset !important', marginBottom: '16px', padding: '10px 0px 10px 0px'  }}><Trans>Valider</Trans></Button>
                                <Link to='/'><Button variant="outlined" sx={{ textTransform: 'unset !important', marginBottom: '16px', padding: '10px 0px 10px 0px'  }}><Trans>Retour à l'accueil</Trans></Button></Link>

                            </Box>
                        </div>
                    </Grid> 
                    
            </ThemeProvider>
        )
    }; 
};

export default withRouter(withQuery(withTranslation()(MotdepasseOublie)));

function withRouter( Child ) {
    return ( props ) => {
        const params = useParams();
        const navig = useNavigate();
        return <Child { ...props } params ={ params } navigation={navig} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const mutationRecover = useMutation({
            mutationFn: (data) => {
              return recoverPassword(data).then((r) => {return r.data}).catch(e =>  {throw e});
            },
        });
        return <Child { ...props } mutationRecover ={mutationRecover} />;
    }
}

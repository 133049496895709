import React from 'react';

import { ThemeProvider, Grid, Button, Checkbox  } from '@mui/material';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import HomeIcon from '@mui/icons-material/Home';
import { Theme } from '../../styles/styles';

import { useMutation, useQuery } from '@tanstack/react-query';
import { newCheckoutMonthly, newCheckoutAnnual } from '../../api/premium';
import { getUser } from '../../api/user';


import './premium.css';
import { CheckPremium, WelcomeVisual, WelcomeVisualXL } from '../../styles/Icons';
import { Link, useNavigate } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next"; 

import moment from 'moment';

class Premium extends React.Component {
    constructor(props) {
        super(props);
        let userdata = {};
        let connected = false;
        if (props.queryUser.isSuccess && this.props.queryUser.data !== undefined && this.props.queryUser.data.length > 0) {
            userdata = {
                userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode, 
                    dateFin: this.props.queryUser.data[0].dateFin,
            };
            connected = true;
        };
        this.state = {
            activeOffer: 0,
            tabOpen: false,
            userdata,
            connected,
            acceptCGV: false,
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this)
    }

    componentDidMount() {
        this.props.queryUser.refetch();
    }

    componentDidUpdate(pP, pS) {
        if (!pS.connected && this.props.queryUser.data !== undefined && this.props.queryUser.data[0] !== undefined) {
            if (moment(this.props.queryUser.data[0].dateFin).isAfter()) {
                this.props.navigation('/');
            } else {
                this.setState({
                    connected: true, 
                    userdata: {
                        userId: this.props.queryUser.data[0].userId,
                        pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                        proLabel: this.props.queryUser.data[0].proSanteLabel,
                        email: this.props.queryUser.data[0].email,
                        periode: this.props.queryUser.data[0].periode,
                        dateFin:  this.props.queryUser.data[0].dateFin
                }});
            }
            
        }
        if (this.props.mutationAbonnementMensuel.isLoading && !this.props.mutationAbonnementAnnuel.isLoading && !this.state.tabOpen) {
            this.setState({ tabOpen: true })
        }
        if (this.props.mutationAbonnementMensuel.isSuccess && !this.props.mutationAbonnementAnnuel.isLoading && this.state.tabOpen) {
            window.open(this.props.mutationAbonnementMensuel.data);
            this.setState({ tabOpen: false })
        }
        if (this.props.mutationAbonnementAnnuel.isLoading && !this.props.mutationAbonnementMensuel.isLoading && !this.state.tabOpen) {
            this.setState({ tabOpen: true })
        }
        if (this.props.mutationAbonnementAnnuel.isSuccess && !this.props.mutationAbonnementMensuel.isLoading && this.state.tabOpen) {
            window.open(this.props.mutationAbonnementAnnuel.data);
            this.setState({ tabOpen: false })
        }
        
    }

    handleSelect(i) {
        this.setState({ activeOffer: i,  tabOpen: false });
    }

    handleCheckout() {
        const { activeOffer } = this.state;
        if (activeOffer === 1) {
            this.props.mutationAbonnementMensuel.mutate();
        } else if (activeOffer === 2) {
            this.props.mutationAbonnementAnnuel.mutate();
        }
    }

    handleCheckBox() {
        this.setState({ acceptCGV: !this.state.acceptCGV  });
    }

    render () {
        const { activeOffer, connected, acceptCGV } = this.state;
        return <ThemeProvider theme={Theme}>
            <Grid className="mainApp premiumApp">
                <Grid>
                    <Grid className='premiumTopRow'>
                        <Button disabled sx={{ color: 'white !important', background: 'linear-gradient(90deg, #F46B45 0%, #EEA849 100%)', textTransform: 'unset !important', fontSize: '10px' }}><AutoAwesomeRoundedIcon fontSize='12px' color='white' />{' Vigicare Premium'}</Button>
                        <Link to='/'><HomeIcon color='black' /></Link> 
                    </Grid>
                </Grid>
                <p className='premiumMainTitle'><Trans>Prendre soin de votre santé en toute sécurité n'a jamais été aussi simple !</Trans></p>
                <div className='premiumVisual'>
                    <WelcomeVisual />
                </div>
                <div className='premiumVisualXL'>
                    <WelcomeVisualXL />
                </div>


                
                <Grid className='premiumOffers'>
                    <fieldset className={activeOffer === 1 ? 'premiumScreenBox selectedBox premiumScreenBoxMonth' : 'premiumScreenBox premiumScreenBoxMonth'} onClick={() => this.handleSelect(1)}>
                        <p className='premiumTitle'><Trans>Mensuel</Trans></p>
                        <p className='premiumText'><Trans><b>3.99€</b> par mois</Trans></p>
                        <p className='premiumTextS'><Trans>Sans engagement</Trans></p>
                    </fieldset>
                    <fieldset className={activeOffer === 2 ? 'premiumScreenBox selectedBox' : 'premiumScreenBox'} onClick={() => this.handleSelect(2)}>
                        <legend className='premiumOffersBtn'><Button disabled sx={{ alignItems: 'flex-end', color: 'white !important', background: 'linear-gradient(90deg, #F46B45 0%, #EEA849 100%)', textTransform: 'unset !important', fontSize: '12px' }}><Trans>Économisez 2 mois</Trans></Button></legend>

                        <p className='premiumTitle'><Trans>Annuel</Trans></p>
                        <p className='premiumText'><Trans><b>3.33€</b> par mois</Trans></p>
                        <p className='premiumTextS'><Trans>Engagement 1 an</Trans></p>
                    </fieldset>
                </Grid>
                <div className='checkboxSignIn' onClick={this.handleCheckBox}>
                    <Checkbox checked={acceptCGV}  id="acceptCGV"/>
                    <p id="acceptCGV"><Trans>J'ai lu et accepte les <a href='/cgu' target='_blank'>Conditions Générales de Vente</a> de Vigicare.*</Trans></p>
                </div>

                <Grid>
                    <Grid className='premiumRow'>
                        <div>
                            <CheckPremium className='iconPremium' />
                        </div>
                        <p><Trans>Utilisation illimitée sans publicité</Trans></p>
                    </Grid>
                    <Grid className='premiumRow'>
                        <div>
                            <CheckPremium className='iconPremium' />
                        </div>
                        <p><Trans>Possibilité d'utiliser les fonctionnalités (effets indésirable-imputabilité et déclaration d'effet indésirable) hors ligne</Trans></p></Grid>
                    <Grid className='premiumRow'>
                        <div>
                            <CheckPremium className='iconPremium' />
                        </div>
                        <p><Trans>Rechercher n'importe quel produit de santé pour accéder à son profil de sécurité</Trans></p></Grid>
                </Grid>
               
                {!connected ?
                    <div className='premiumConnectionBox'>
                        <p className='premiumTitle'><Trans>Vous devez être connecté(e) afin de pouvoir souscrire à un abonnement Vigicare Premium.</Trans></p>
                        <div className='premiumLinkButtons'>
                            <Link to ="/inscription" className='linkButton'><Button variant="outlined" color="premium"><Trans>S'inscrire</Trans></Button></Link>
                            <Link to ="/auth" className='linkButton'><Button variant="contained" color="premium"><Trans>Se connecter</Trans></Button></Link>
                        </div>
                    </div>
                    : <>
                    {activeOffer === 0 || !acceptCGV ?
                        <div className='bottomBtnFullWidth'>
                            <Button disabled><Trans>Suivant</Trans></Button>
                        </div>
                    : <div className='bottomBtnFullWidth'>
                        <Button color='white' sx={{ background: '#F46B45 !important' }} onClick={this.handleCheckout}><Trans>Je m'abonne</Trans></Button>
                    </div>}
                    </>
                }
                
            </Grid>
        </ThemeProvider>
    }
}

export default withRouter(withQuery(withTranslation()(Premium)));

function withRouter( Child ) {
    return ( props ) => {
        const navig = useNavigate();
        return <Child { ...props } navigation={navig} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const queryUser = useQuery(['fetch-user'], () => getUser().then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationAbonnementMensuel = useMutation({
            mutationFn: () => {
               return newCheckoutMonthly().then((r) => {return r.data}).catch(e =>  {throw e})
            },
        });
        const mutationAbonnementAnnuel = useMutation({
            mutationFn: () => {
               return newCheckoutAnnual().then((r) => {return r.data}).catch(e =>  {throw e})
            },
        });
        return <Child { ...props } mutationAbonnementMensuel={mutationAbonnementMensuel} mutationAbonnementAnnuel={mutationAbonnementAnnuel} queryUser={queryUser} />;
    }
}
import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CrossIcon, EnglishFlag, FrenchFlag } from "../../../styles/Icons";

export default function LanguagePopup(props) {
  const { i18n, t } = useTranslation("app");

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    props.handler();
  };

  return (
    <>
      <Grid className="languagePanel">
        <div className="languageTitle">
          <p>
            <b>{t("common.language")}</b>
          </p>
          <p onClick={props.handler}>
            <CrossIcon />
          </p>
        </div>
        <div className="languageList">
          <div
            onClick={() => {
              changeLanguage("fr");
            }}
          >
            <FrenchFlag /> <p>Français</p>
          </div>
          <div onClick={() => changeLanguage("en")}>
            <EnglishFlag /> <p>English</p>
          </div>
          {/** Languages to come
                    <div onClick={() => changeLanguage('sp')}>
                        <SpanishFlag /> <p>Español</p>
                    </div>
                    <div onClick={() => changeLanguage('de')}>
                        <DeutschFlag /> <p>Deutsch</p>
                    </div>
                    <div onClick={() => changeLanguage('it')}>
                        <ItalianFlag /> <p>Italiano</p>
                    </div>
                    */}
        </div>
      </Grid>
    </>
  );
}

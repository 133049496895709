import { useState } from "react";
import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getUser } from "../../api/user";
import { AdverseEffectForm } from "./form";
import { AdverseEffectResult } from "./result";
import { VigicareLogoWhite } from "../../styles/Icons";
import { Theme } from "../../styles/styles";
import "./adverse-effect.css";

export const AdverseEffect = () => {
  const [activePage, setActivePage] = useState(0); // 0 form, 1 result

  const [data, setData] = useState({});

  const { isLoading, data: userData } = useQuery({
    queryKey: ["fetch-user"],
    queryFn: () =>
      getUser()
        .then((res) => {
          return res.data[0];
        })
        .catch((err) => {
          throw err;
        }),
    refetchOnWindowFocus: false,
  });

  const handleSubmitForm = (data) => {
    // Quick fix: convert typeEffet to French value
    const typeEffet = data.typeEffet.map((effect) => effect.value);
    setData({ ...data, typeEffet: typeEffet });

    setActivePage(1);
  };

  const renderPage = () => {
    if (activePage === 0) {
      return (
        <AdverseEffectForm
          connected={!!userData}
          userData={userData}
          handleSubmit={handleSubmitForm}
        />
      );
    }

    if (activePage === 1) {
      return (
        <AdverseEffectResult
          data={data}
          connected={!!userData}
          userData={userData}
        ></AdverseEffectResult>
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={Theme}>
      <Grid className="mainApp">
        <header className="headerApp">
          <Grid className="headerImputabilite">
            <VigicareLogoWhite />
            <p className="headerText">
              {activePage === 0 ? (
                <Trans>Imputabilité</Trans>
              ) : (
                <Trans>Résultat</Trans>
              )}
            </p>
            <Link to="/">
              <HomeIcon color="white" />
            </Link>
          </Grid>
        </header>
        <div className="mainImputabilite">
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            renderPage()
          )}
        </div>
      </Grid>
    </ThemeProvider>
  );
};

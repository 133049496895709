import axios from 'axios';
import { config } from '../config';

const instance = axios.create({
  withCredentials: true
});

const BASE_URL = config.apiBaseUrl

export const deleteSubscription = async () => {
    return instance.delete(`${BASE_URL}/premium`);
}

export const newSubscription = async (data) => {
  return instance.post(`${BASE_URL}/premium`, data);
}

export const newCheckoutMonthly = async () => {
  return instance.post(`${BASE_URL}/checkout-session/month`);
}

export const newCheckoutAnnual = async () => {
  return instance.post(`${BASE_URL}/checkout-session/year`);
}

export const validateSubscription = async (data) => {
  return instance.post(`${BASE_URL}/checkout-session/validation`, data);
}
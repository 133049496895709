import React from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button, TextField } from '@mui/material';

export default class Declarant extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    profession: props.data.profession !== undefined ? props.data.profession : '',
                    nom: props.data.nom !== undefined ? props.data.nom : '',
                    email: props.data.email !== undefined ? props.data.email : '',
                    adresse: props.data.adresse !== undefined ? props.data.adresse : '',
                    codePostal: props.data.codePostal !== undefined ? props.data.codePostal : '',
                    commune: props.data.commune !== undefined ? props.data.commune : '',
                    telephone: props.data.telephone !== undefined ? props.data.telephone : '',
                },
                errorIncomplete: false,
            }
        } else {
            this.state = {
                data : {
                    profession: '',
                    nom: '',
                    email: '',
                    adresse: '',
                    codePostal: '',
                    commune: '',
                    telephone: '',
                },
                errorIncomplete: false,
            };
        }

        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }

    handleChange(ev) {
        const { data } = this.state;
        switch (ev.target.id) {
            case "nom" : this.setState({ data: { ...data, nom: ev.target.value  }, errorIncomplete: false }); break;
            case "profession" : this.setState({ data: { ...data, profession: ev.target.value }, errorIncomplete: false }); break;
            case "adresse" : this.setState({ data: { ...data, adresse: ev.target.value } , errorIncomplete: false }); break;
            case "codePostal" : this.setState({ data: { ...data, codePostal: ev.target.value }, errorIncomplete: false }); break;
            case "commune" : this.setState({ data: { ...data, commune: ev.target.value  }, errorIncomplete: false }); break;
            case "email" : this.setState({ data: { ...data, email: ev.target.value }, errorIncomplete: false }); break;
            case "telephone" : this.setState({ data: { ...data, telephone: ev.target.value }, errorIncomplete: false }); break;
            default: break;
        }
    }

    onSubmit() {
        const { data } = this.state;
      
        if (data.nom.match(/^[A-Za-z]{2,}$/) !== null && data.email !== '' && data.telephone !== '') this.props.handler(this.state.data);
        else { window.scrollTo(0, 0); this.setState({ errorIncomplete: true });}
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return  <>
            <p className="pageTitle">Déclarant</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            <p>Les coordonnées du déclarant sont requises pour permettre, si nécessaire, de compléter l'information</p>
            {errorIncomplete ? <p className='errorText'>Champs manquants</p> : null}

            <div className='inputForm'>
                <TextField fullWidth id="profession" type="text" value={data.profession} onChange={this.handleChange} label="Profession" />
                <TextField fullWidth id="nom" type="text" value={data.nom} onChange={this.handleChange} label="Nom * (minimum 2 lettres)" error={errorIncomplete && data.nom.match(/^[A-Za-z]{2,}$/) === null} />
                <TextField fullWidth id="email" type="text" value={data.email} onChange={this.handleChange} label="Email *" />
                <TextField fullWidth id="adresse" type="text" value={data.adresse} onChange={this.handleChange} label="Adresse" />
                
                <div className="inputSmallRow">
                    <TextField  id="codePostal" className="inputSmall" type="number" value={data.codePostal} onChange={this.handleChange} label="Code Postal" />
                    <TextField  id="commune" className="inputSmall" type="text" value={data.commune} onChange={this.handleChange} label="Commune" />
                </div>
                <TextField fullWidth id="telephone" type="number" value={data.telephone} onChange={this.handleChange} label="Téléphone *" />
            </div>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>

            {data.nom !== '' && data.email !== '' && data.telephone !== '' ? 
                <Grid textAlign={'end'} marginBottom="24px" marginTop="24px">
                    <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                </Grid>
            :   <Grid textAlign={'end'} marginBottom="24px" marginTop="24px">
                    <Button variant="contained" disabled onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                </Grid>
            }
            
        </>
    }
}
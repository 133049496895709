import React from 'react';
import { Trans } from 'react-i18next';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { Grid, Button, TextField, MenuItem } from '@mui/material';
   
export default class Notificateur extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    profession: props.data.profession,
                    professionAutre: props.data.professionAutre,
                    nom: props.data.nom,
                    email: props.data.email,
                    adresse: props.data.adresse,
                    codePostal: props.data.codePostal,
                    commune: props.data.commune,
                    telephone: props.data.telephone,
                    date: props.data.date,
                },
                errorIncomplete: false,
            }
        } else {
            this.state = {
                data : {
                    profession: '',
                    professionAutre: null,
                    nom: '',
                    email: '',
                    adresse: '',
                    codePostal: '',
                    commune: '',
                    telephone: '',
                    date: null,
                },
                errorIncomplete: false,
            };
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        window.scrollTo(0, 0);
    }

    handleChangeDate(ev) {
        this.setState({ data: { ...this.state.data, date: ev } });
    }
       
    handleChangeDropdown(ev) {
        const { data } = this.state;
        if (ev.target.value === 'Autre') {
            this.setState({ data: { ...data, profession: ev.target.value, professionAutre: '' } });
        } else {
            this.setState({ data: { ...data, profession: ev.target.value, professionAutre: null } });
        }
        

    } 

    handleChange(ev) {
        const { data } = this.state;
        switch (ev.target.id) {
            case "nom" : this.setState({ data: { ...data, nom: ev.target.value  }, errorIncomplete: false }); break;
            case "professionAutre" : this.setState({ data: { ...data, professionAutre: ev.target.value  }, errorIncomplete: false }); break;
            case "prenom" : this.setState({ data: { ...data, prenom: ev.target.value }, errorIncomplete: false }); break;
            case "adresse" : this.setState({ data: { ...data, adresse: ev.target.value } , errorIncomplete: false }); break;
            case "codePostal" : this.setState({ data: { ...data, codePostal: ev.target.value }, errorIncomplete: false }); break;
            case "commune" : this.setState({ data: { ...data, commune: ev.target.value  }, errorIncomplete: false }); break;
            case "email" : this.setState({ data: { ...data, email: ev.target.value }, errorIncomplete: false }); break;
            case "telephone" : this.setState({ data: { ...data, telephone: ev.target.value }, errorIncomplete: false }); break;
            default: break;
        }
    }

    onSubmit() {
        const { data } = this.state;
        if ((data.profession !== '' && data.profession === 'Autre' && data.professionAutre !== null && data.professionAutre !== '') || data.profession !== 'Autre') {
            this.props.handler(this.state.data);
        }
        else { window.scrollTo(0, 0); this.setState({ errorIncomplete: false });}
    
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
        <p className="pageTitle">Notificateur</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            {errorIncomplete ? <p className='errorText'>Champs manquants</p> : null}

            <div className='inputForm'>
                <Grid className='formRowDouble'>
                    <TextField fullWidth id="profession" select value={data.profession} onChange={this.handleChangeDropdown} label="Profession *">
                        <MenuItem value="Médecin">Médecin</MenuItem>
                        <MenuItem value="Chirurgien-Dentiste">Chirurgien-Dentiste</MenuItem>
                        <MenuItem value="Pharmacien">Pharmacien</MenuItem>
                        <MenuItem value="Sage-Femme">Sage-Femme</MenuItem>
                        <MenuItem value="Autre">Autre</MenuItem>
                    </TextField>
                    {data.profession === 'Autre' ? <TextField fullWidth id="professionAutre" type="text" value={data.professionAutre} onChange={this.handleChange} label="Précisez"  /> : null}
                </Grid>
                <TextField fullWidth id="nom" type="text" value={data.nom} onChange={this.handleChange} label="Nom" />
                <TextField fullWidth id="adresse" type="text" value={data.adresse} onChange={this.handleChange} label="Adresse" />
                
                <div className="inputSmallRow">
                    <TextField  id="codePostal" className="inputSmall" type="number" value={data.codePostal} onChange={this.handleChange} label="Code Postal" />
                    <TextField  id="commune" className="inputSmall" type="text" value={data.commune} onChange={this.handleChange} label="Commune" />
                </div>
                <TextField fullWidth id="telephone" type="number" value={data.telephone} onChange={this.handleChange} label="Téléphone" />
                <TextField fullWidth id="email" type="text" value={data.email} onChange={this.handleChange} label="Email" />
                <DesktopDatePicker id="date" inputFormat="DD/MM/YYYY" value={data.date} onChange={this.handleChangeDate} label="Date d'établissement de la demande" renderInput={(params) => {return <TextField {...params} error={errorIncomplete && (data.date === null || (!data.date.isValid() || !data.date.isBefore()))} />}} />
            </div>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>
            <Grid textAlign={'end'} marginBottom="24px" marginTop="24px">
                <Button 
                    variant="contained"
                    disabled={data.profession === '' || (data.profession === 'Autre' && (data.professionAutre === null || data.professionAutre === ''))}
                    onClick={() => this.onSubmit()}>
                        {"SUIVANT"}
                    </Button>
            </Grid>
        </>
    }
}
import React from 'react';
import { Trans } from 'react-i18next';

import { Grid, Button, TextField, Checkbox, MenuItem } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default class Utilisation extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    dateFirstUse: props.data.dateFirstUse !== undefined ? props.data.dateFirstUse : null,
                    ryhtm: props.data.ryhtm !== undefined ? props.data.ryhtm : '',
                    uniteRythm: props.data.uniteRythm !== undefined ? props.data.uniteRythm : 'Jours',
                    dateEffetInde: props.data.dateEffetInde !== undefined ? props.data.dateEffetInde : null,
                    proUse: props.data.proUse !== undefined ? props.data.proUse : false,
                    missUse: props.data.missUse !== undefined ? props.data.missUse : false,
                },
                errorIncomplete: false,
            }
        } else {
            this.state = {
                data : {
                    dateFirstUse: null,
                    ryhtm: '',
                    uniteRythm: 'Jours',
                    dateEffetInde: null,
                    proUse: false,
                    missUse: false,
                },
                errorIncomplete: false,
            };
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
        this.handleChangeDateEffect = this.handleChangeDateEffect.bind(this);
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);

        window.scrollTo(0, 0);
    }

    handleChangeDropdown(ev) {
        if (ev.target.value !== undefined) { 
            this.setState({ data: { ...this.state.data, uniteRythm: ev.target.value } });
        }
    }
       
    handleChangeDateStart(ev) {
        this.setState({ data: { ...this.state.data, dateFirstUse: ev } });
    }
    handleChangeDateEffect(ev) {
        this.setState({ data: { ...this.state.data, dateEffetInde: ev } });
    }

    handleChange(ev) {
        const { data } = this.state;
        switch (ev.target.id) {
            case "ryhtm" : this.setState({ data: { ...data, ryhtm: ev.target.value  } }); break;
            case "proUse" : this.setState({ data: { ...data, proUse: !data.proUse } }); break;
            case "missUse" : this.setState({ data: { ...data, missUse: !data.missUse }  }); break;
            default: break;
        }
    }

    onSubmit() {
        const { data } = this.state;
        if (data.dateFirstUse !== null && data.dateFirstUse.isBefore() && data.dateFirstUse.isValid()
            && data.dateEffetInde !== null && data.dateEffetInde.isBefore() && data.dateEffetInde.isValid() && data.dateEffetInde.isSameOrAfter(data.dateFirstUse)
            && data.ryhtm !== '') this.props.handler(this.state.data);
        else this.setState({ errorIncomplete: true });
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
        <p className="pageTitle">Utilisation</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            {errorIncomplete ? <p className='errorText'>Champs manquants ou incorrects</p> : null}

            <div className='inputForm'>
                <DesktopDatePicker fullWidth inputFormat="DD/MM/YYYY" id="dateFirstUse" type="date" value={data.dateFirstUse} onChange={this.handleChangeDateStart} label="Date de la première utilisation *" renderInput={(params) => {return <TextField {...params} error={errorIncomplete && (data.dateFirstUse === null || (!data.dateFirstUse.isBefore() || !data.dateFirstUse.isValid()))} />}} />
                
                <Grid className='formRowDouble'>
                    <TextField fullWidth id="ryhtm" type="number" value={data.ryhtm} onChange={this.handleChange} label="Rythme d'utilisation *" error={data.ryhtm === "" && errorIncomplete} />
                    <TextField select value={data.uniteRythm} onChange={this.handleChangeDropdown} sx={{ minWidth: '40%', textAlign: 'end' }}>
                        <MenuItem value="Jours">par jour</MenuItem>
                        <MenuItem value="Semaines">par semaine</MenuItem>
                        <MenuItem value="Mois">par mois</MenuItem>
                    </TextField>
                </Grid>
                
                <DesktopDatePicker fullWidth inputFormat="DD/MM/YYYY" id="dateEffetInde" type="text" value={data.dateEffetInde} onChange={this.handleChangeDateEffect} label="Date de survenue de l'effet indésirable *" renderInput={(params) => {return <TextField {...params} error={errorIncomplete && (data.dateEffetInde === null || !data.dateEffetInde.isBefore() || !data.dateEffetInde.isValid() || !data.dateEffetInde.isSameOrAfter(data.dateFirstUse)) } />}} />
                <Grid>
                    <p className="robotoFontB">Usage professionnel :</p>
                    <label className='robotoFont'>
                        <Checkbox id="proUse" checked={data.proUse} onChange={this.handleChange} />
                        {' Oui'}
                    </label>
                </Grid>
                <Grid>
                    <p className="robotoFontB">Mésusage :</p>
                    <label className='robotoFont'>
                        <Checkbox id="missUse" checked={data.missUse} onChange={this.handleChange} />
                        {' Oui'}
                    </label>
                </Grid>
            </div>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>

            {data.dateFirstUse !== null ? 
                <Grid className="bottomBtnForm">
                    <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                </Grid>
            :   <Grid className="bottomBtnForm">
                    <Button variant="contained" disabled onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                </Grid>
            }

        </>
    }
}
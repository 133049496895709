import React from 'react';
import { Grid, Button, TextField, Radio } from '@mui/material';

export default class ConsoAssociees extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data: {
                    medicaments: props.data.medicaments,
                    alcool: props.data.alcool,
                    tabac: props.data.tabac,
                    cafe: props.data.cafe,
                    cannabis: props.data.cannabis,
                    autre: props.data.autre,
                    medicamentsText: props.data.medicamentsText,
                    tabacQte: props.data.tabacQte,
                }, 
                errorIncomplete : false,
            }
        } else {
            this.state = {
                data: {
                    medicaments: null,
                    alcool: null,
                    tabac: null,
                    cafe: null,
                    cannabis: null,
                    autre: null,
                    medicamentsText: '',
                    tabacQte: '',
                }, 
                errorIncomplete : false,
            }
        }
        

        this.handleChange = this.handleChange.bind(this);
        window.scrollTo(0, 0);
    }
       

    handleChange(ev) {
        const { data } = this.state;
        if (ev !== undefined) {
            switch (ev.target.id) {
                case "medicamentsTrue": this.setState({ data: { ...data, medicaments: true } }); break;
                case "medicamentsFalse": this.setState({ data: { ...data, medicaments: false, medicamentsText: '', } }); break;
                case "medicamentsNSP": this.setState({ data: { ...data, medicaments: null, medicamentsText: '', } }); break;
                case "alcoolTrue": this.setState({ data: { ...data, alcool: true } }); break;
                case "alcoolFalse": this.setState({ data: { ...data, alcool: false } }); break;
                case "alcoolNSP": this.setState({ data: { ...data, alcool: null } }); break;
                case "tabacTrue": this.setState({ data: { ...data, tabac: true } }); break;
                case "tabacFalse": this.setState({ data: { ...data, tabac: false, tabacQte: '', } }); break;
                case "tabacNSP": this.setState({ data: { ...data, tabac: null, tabacQte: '', } }); break;
                case "cafeTrue": this.setState({ data: { ...data, cafe: true } }); break;
                case "cafeFalse": this.setState({ data: { ...data, cafe: false } }); break;
                case "cafeNSP": this.setState({ data: { ...data, cafe: null } }); break;
                case "cannabisTrue": this.setState({ data: { ...data, cannabis: true } }); break;
                case "cannabisFalse": this.setState({ data: { ...data, cannabis: false } }); break;
                case "cannabisNSP": this.setState({ data: { ...data, cannabis: null } }); break;
                case "autreTrue": this.setState({ data: { ...data, autre: true } }); break;
                case "autreFalse": this.setState({ data: { ...data, autre: false } }); break;
                case "autreNSP": this.setState({ data: { ...data, autre: null } }); break;
                case "medicamentsText" : this.setState({ data: { ...data, medicamentsText: ev.target.value } }); break;
                case "tabacQte" : this.setState({ data: { ...data, tabacQte: ev.target.value } }); break;
                default: break;
            }
        }
    }

    onSubmit() {
        const { data } = this.state;
        if ((data.tabac && data.tabacQte === "") || (data.medicaments && data.medicamentsText === "")) {
            window.scrollTo(0, 0);
            this.setState({ errorIncomplete: true })
        } else this.props.handler(this.state.data);
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
            <p className="pageTitle">{'Consommations associées'}</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>

            <Grid>
                <p className="robotoFontB">Prise de produits associés dont médicaments :</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="medicamentsTrue" checked={data.medicaments === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="medicamentsFalse" checked={data.medicaments === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="medicamentsNSP" checked={data.medicaments === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
                {data.medicaments === true ?
                    <TextField fullWidth id="medicamentsText" type="text" value={data.medicamentsText} onChange={this.handleChange} label="Si oui, précisez" helperText={errorIncomplete && data.medicamentsText === "" ? "Donnée manquante" : null} error={errorIncomplete && data.medicamentsText === ""} />
                : null }
            </Grid>
            <Grid>
                <p className="robotoFontB">Alcool :</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="alcoolTrue" checked={data.alcool === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="alcoolFalse" checked={data.alcool === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="alcoolNSP" checked={data.alcool === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
            </Grid>
            <Grid>
                <p className="robotoFontB">Tabac :</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="tabacTrue" checked={data.tabac === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="tabacFalse" checked={data.tabac === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="tabacNSP" checked={data.tabac === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
                {data.tabac === true ?
                    <TextField fullWidth id="tabacQte" type="text" value={data.tabacQte} onChange={this.handleChange} label="Quantité et fréquence" helperText={errorIncomplete && data.tabacQte === "" ? "Donnée manquante" : null} error={errorIncomplete && data.tabacQte === ""}  /> :
                    null
                }
                
            </Grid>
            <Grid>
                <p className="robotoFontB">Café :</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="cafeTrue" checked={data.cafe === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="cafeFalse" checked={data.cafe === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="cafeNSP" checked={data.cafe === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
            </Grid>
            <Grid>
                <p className="robotoFontB">Cannabis :</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="cannabisTrue" checked={data.cannabis === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="cannabisFalse" checked={data.cannabis === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="cannabisNSP" checked={data.cannabis === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
            </Grid>
            <Grid>
                <p className="robotoFontB">Autres produits associés (thés, tisanes, cosmetiques, autres substances ou produits) :</p>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="autreTrue" checked={data.autre === true} onChange={this.handleChange} />
                        {'  Oui'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="autreFalse" checked={data.autre === false} onChange={this.handleChange} />
                        {'  Non'}
                    </label>
                </Grid>
                <Grid className='inputCheckboxRow'>
                    <label className='robotoFont'>
                        <Radio id="autreNSP" checked={data.autre === null} onChange={this.handleChange} />
                        {'  Je ne sais pas'}
                    </label>
                </Grid>
            </Grid>

            <Grid textAlign={'end'} marginBottom="24px" marginTop="24px">
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
            </Grid>
        </>
    }
}
import React from 'react';
import { Checkbox, Grid, Button } from '@mui/material';
    
export default class Evolution extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            data : {
                guerison: false,
                sansSequelle: false,
                avecSequelle: false,
                enCours: false,
                nonRetabli: false,
                deces: false,
                decesDuAEffet: false,
                decesEffetContribue: false,
                decesSansRapport: false,
                inconnu: false,
            }
        };

        
        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }

    handleChange(ev){
        const { data } = this.state;
        switch (ev.target.id) {
            case "guerison" : this.setState({ data: { ...data, guerison: !data.guerison, sansSequelle: false, avecSequelle: false, enCours: false, nonRetabli: false, deces: false, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false, inconnu: false } } ); break;
            case "sansSequelle" : this.setState({ data: { ...data, sansSequelle: !data.sansSequelle, avecSequelle: false, enCours: false, guerison: true, nonRetabli: false, deces: false, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false, inconnu: false } }); break;
            case "avecSequelle" : this.setState({ data: { ...data, avecSequelle: !data.avecSequelle, sansSequelle: false, enCours: false, guerison: true, nonRetabli: false, deces: false, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false, inconnu: false } }); break;
            case "enCours" : this.setState({ data: { ...data, enCours: !data.enCours, avecSequelle: false, sansSequelle: false, guerison: true, nonRetabli: false, deces: false, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false, inconnu: false }  }); break;
            case "nonRetabli" : this.setState({ data: { nonRetabli: !data.nonRetabli, guerison: false, sansSequelle: false, avecSequelle: false, enCours: false, deces: false, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false, inconnu: false } }); break;
            case "deces" : this.setState({ data: { ...data, deces: !data.deces, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false, guerison: false, sansSequelle: false, avecSequelle: false, enCours: false, nonRetabli: false, inconnu: false } }); break;
            case "decesDuAEffet" : this.setState({ data: { ...data, deces: true, decesDuAEffet: !data.decesDuAEffet, decesEffetContribue: false, decesSansRapport: false, guerison: false, sansSequelle: false, avecSequelle: false, enCours: false, nonRetabli: false, inconnu: false } }); break;
            case "decesEffetContribue" : this.setState({ data: { ...data, deces: true, decesEffetContribue: !data.decesEffetContribue, decesDuAEffet: false, decesSansRapport: false, guerison: false, sansSequelle: false, avecSequelle: false, enCours: false, nonRetabli: false, inconnu: false } }); break;
            case "decesSansRapport" : this.setState({ data: { ...data, deces: true, decesSansRapport: !data.decesSansRapport, decesDuAEffet: false, decesEffetContribue: false, guerison: false, sansSequelle: false, avecSequelle: false, enCours: false, nonRetabli: false, inconnu: false } }); break;
            case "inconnu" : this.setState({ data: { ...data, inconnu: !data.inconnu, guerison: false, sansSequelle: false, avecSequelle: false, enCours: false,  nonRetabli: false, deces: false, decesDuAEffet: false, decesEffetContribue: false, decesSansRapport: false,} }); break;
            default: break;
        }
    }

    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
            <p className="pageTitle">{'Evolution'}</p>
            <Grid className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </Grid>
            <p>Evolution de l'effet indésirable :</p>
            <Grid>
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox"  id="guerison" className="inputBox" checked={data.guerison}  />
                    <p style={{ cursor: 'pointer' }}><b id="guerison">Guérison</b></p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="sansSequelle" className="inputBox" checked={data.sansSequelle}  />
                    <p style={{ cursor: 'pointer' }} id="sansSequelle">sans séquelle</p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="avecSequelle" className="inputBox" checked={data.avecSequelle}  />
                    <p style={{ cursor: 'pointer' }} id="avecSequelle">avec séquelle</p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="enCours" className="inputBox" checked={data.enCours}  />
                    <p style={{ cursor: 'pointer' }} id="enCours">en cours</p>
                </Grid>
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="nonRetabli" className="inputBox" checked={data.nonRetabli}  />
                    <p style={{ cursor: 'pointer' }}><b id="nonRetabli">Sujet non encore rétabli</b></p>
                </Grid> 
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="deces" className="inputBox" checked={data.deces}  />
                    <p style={{ cursor: 'pointer' }}><b id="deces">Décès</b></p>
                </Grid> 
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="decesDuAEffet" className="inputBox" checked={data.decesDuAEffet}  />
                    <p style={{ cursor: 'pointer' }} id="decesDuAEffet">dû à l'effet</p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="decesEffetContribue" className="inputBox" checked={data.decesEffetContribue}  />
                    <p style={{ cursor: 'pointer' }} id="decesEffetContribue">auquel l'effet a pu contribuer</p>
                </Grid>
                <Grid className="formRow subBoxRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="decesSansRapport" className="inputBox" checked={data.decesSansRapport}  />
                    <p style={{ cursor: 'pointer' }} id="decesSansRapport">sans rapport avec l'effet</p>
                </Grid>
                <Grid className="formRow" onClick={this.handleChange}>
                    <Checkbox type="checkbox" id="inconnu" className="inputBox" checked={data.inconnu}  />
                    <p style={{ cursor: 'pointer' }}><b id="inconnu">Inconnu</b></p>
                </Grid>
            </Grid>    
            {data.guerison || data.nonRetabli || data.deces || data.inconnu 
                ? <Grid textAlign={'end'} marginBottom="16px">
                        <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
                    </Grid>
                :   <Grid textAlign={'end'} marginBottom="16px">
                        <Button variant="contained" disabled>{"SUIVANT"}</Button>
                    </Grid>
            }
        </>
    }
};
import React from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { signinUser, getUser } from '../../api/user';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Box, Button, Grid, TextField, ThemeProvider, Checkbox, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import { Theme } from '../../styles/styles';

import { Trans, withTranslation } from "react-i18next";   

import InscriptionValide from './InscriptionValide';
import './auth.css';
import { VigicareLogoWhite } from '../../styles/Icons';

class Inscription extends React.Component {
    constructor(props) {
        super(props);
        if (props.params !== undefined && props.params === 'true') {
            this.state = {
                pro: true,
                proInput: true,
                acceptCGU: false,
                acceptNewsletter: false,
                profession: '',
                professionAutre: '',
                name: '',
                email: '',
                password: '',
                passwordConfirm: '',
                signinDone: false,
                showPassword: false,
                showPasswordConfirm: false,
            }
        } else {
            this.state = {
                pro: false,
                proInput: false,
                acceptCGU: false,
                acceptNewsletter: false,
                profession: '',
                professionAutre: '',
                name: '',
                email: '',
                password: '',
                passwordConfirm: '',
                signinDone: false,
                showPassword: false,
                showPasswordConfirm: false,
            }
        }
       
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClickShowPasswordConfirm = this.handleClickShowPasswordConfirm.bind(this);

        window.scrollTo(0, 0);
    }

    componentDidUpdate(pP, pS) {
        if (!pS.signinDone && this.props.mutationSignin.isSuccess) {
            this.setState({ signinDone: true })
        } else if (this.props.queryUser.isSuccess && !this.state.signinDone) {
            this.props.navigation('/');
        } else if (this.props.mutationSignin.isError) {
            window.scrollTo(0, 0);
        }
    }

    handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword});
    }

    handleClickShowPasswordConfirm() {
        this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm});
    }


    handleChange(ev) {
        switch (ev.target.id) {
            case "name" : this.setState({ name: ev.target.value  }); break;
            case "email" : this.setState({ email: ev.target.value  }); break;
            case "password" : this.setState({ password: ev.target.value  }); break;
            case "passwordConfirm" : this.setState({ passwordConfirm: ev.target.value  }); break;
            case "proInput" : this.setState({ proInput: !this.state.proInput  }); break;
            case "professionAutre" : this.setState({ professionAutre: ev.target.value  }); break;
            case "acceptCGU" : this.setState({ acceptCGU: !this.state.acceptCGU  }); break;
            case "acceptNewsletter" : this.setState({ acceptNewsletter: !this.state.acceptNewsletter  }); break;
            default: break;
        }
    }

    handleChangeDropdown(ev) {
        if (ev.target.value === 'Autre') {
            this.setState({  profession: ev.target.value, professionAutre: ''  });
        } else {
            this.setState({ profession: ev.target.value, professionAutre: null });
        }
    } 
   
    render() {
        const { profession, professionAutre, pro, proInput, acceptCGU, acceptNewsletter, name, email, password, passwordConfirm, signinDone, showPassword, showPasswordConfirm } = this.state;
        const { mutationSignin } = this.props;

        return (
            <ThemeProvider theme={Theme}>
                    <Grid className='mainApp'>
                        <header className='headerSignIn'>
                            <VigicareLogoWhite />
                            <Grid>
                                <p><Trans>Bienvenue sur Vigicare</Trans></p>
                            </Grid>
                            <Link to='/'>
                                <HomeIcon color="white" />
                            </Link>
                        </header>
                        <div className='mainSignIn'>
                            {signinDone ?
                                    <InscriptionValide />
                                    : <>
                                    <p className='authTitle'><Trans>Inscrivez-vous gratuitement</Trans></p>
                                    
                                    <TextField 
                                        fullWidth id="name" 
                                        type="text" 
                                        value={name} 
                                        onChange={this.handleChange} 
                                        label={<Trans>Votre prénom</Trans>} 
                                        error={name === '' && email !== '' && password !== '' && passwordConfirm !== '' } />
                                    
                                    <TextField 
                                        fullWidth id="email" 
                                        type="text" 
                                        value={email} 
                                        onChange={this.handleChange} 
                                        label={<Trans>Votre email</Trans>}
                                        sx={{ marginTop: '12px' }}  
                                        error={email !== null && email !== '' && password !== '' && email.match(/^\S+@\S+\.\S+$/) === null } 
                                        helperText={email !== '' && email !== null && password !== '' && email.match(/^\S+@\S+\.\S+$/) === null ? (<Trans>Format d'email incorrect</Trans>)  : null}/>
                                    
                                    <TextField 
                                        fullWidth id="password" 
                                        type={showPassword ? "text" : "password"} 
                                        value={password} onChange={this.handleChange} 
                                        label={<Trans>Créer votre mot de passe</Trans>} 
                                        sx={{ marginTop: '12px' }} 
                                        error={password !== '' && password.match(/^(?=.*[A-Z])(?=.*[.!@#$&*])(?=.*[0-9]).{8,}$/) === null}
                                        helperText={<Trans>Votre mot de passe doit contenir au moins 8 caractères, dont au moins 1 majuscule, 1 chiffre et 1 symbole (.!@#$&*)</Trans>} 
                                        InputProps={{
                                            endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        color={"#DDDDDD"}
                                                        onClick={this.handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>,
                                        }}
                                    />
                                    
                                    <TextField 
                                        fullWidth id="passwordConfirm" 
                                        type={showPasswordConfirm ? "text" : "password"} 
                                        value={passwordConfirm} 
                                        onChange={this.handleChange} 
                                        label={<Trans>Confirmez votre mot de passe</Trans>} 
                                        sx={{ marginTop: '12px' }} 
                                        error={passwordConfirm !== "" && passwordConfirm !== password} 
                                        helperText={passwordConfirm !== "" && passwordConfirm !== password ? (<Trans>Les mots de passe ne correspondent pas</Trans>) : null} 

                                        InputProps={{
                                            endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        color={"#DDDDDD"}
                                                        onClick={this.handleClickShowPasswordConfirm}
                                                        edge="end"
                                                    >
                                                        {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>,
                                        }}
                                    />
                    
                                    {!pro ? 
                                        <div className='checkboxSignIn'  onClick={this.handleChange}>
                                            <Checkbox id="proInput" checked={proInput}  />
                                            <p id="proInput"><Trans>Vous êtes un professionnel de santé</Trans></p>
                                        </div>
                                    : null}
                                    {proInput ? 
                                        <>
                                            <TextField fullWidth id="profession" select value={profession} onChange={this.handleChangeDropdown} label={<Trans>Profession</Trans>} sx={{ marginTop: '12px' }}>
                                                <MenuItem value="Médecin"><Trans>Médecin</Trans></MenuItem>
                                                <MenuItem value="Chirurgien-Dentiste"><Trans>Chirurgien-Dentiste</Trans></MenuItem>
                                                <MenuItem value="Pharmacien"><Trans>Pharmacien</Trans></MenuItem>
                                                <MenuItem value="Sage-Femme"><Trans>Sage-Femme</Trans></MenuItem>
                                                <MenuItem value="Autre"><Trans>Autre</Trans></MenuItem>
                                            </TextField>
                                            {profession === 'Autre' ? <TextField fullWidth id="professionAutre" type="text" value={professionAutre} onChange={this.handleChange} label={<Trans>Précisez</Trans>} sx={{ marginTop: '12px' }}/> : null}
                                        </>
                                    : null}
                                    {mutationSignin.error !== null && mutationSignin.error.response !== undefined  ? <>{mutationSignin.error.response.data !== undefined && mutationSignin.error.response.status !== 503 ? <p className='errorLabel'><Trans>{mutationSignin.error.response.data}</Trans></p> : <p className='errorLabel'><Trans>Erreur de connexion au serveur</Trans></p>}</> : null}

                                    <Box className='bottomBtnQuestionnaire bottomBtnCo'>
                                        <div className='checkboxSignIn' onClick={this.handleChange}>
                                            <Checkbox checked={acceptNewsletter}  id="acceptNewsletter"/>
                                            <p id="acceptNewsletter"><Trans>Je souhaite recevoir les actualités de Vigicare par email.</Trans></p>
                                        </div>
                                        <div className='checkboxSignIn' onClick={this.handleChange}>
                                            <Checkbox checked={acceptCGU}  id="acceptCGU"/>
                                            <p id="acceptCGU"><Trans>J'ai lu et accepte les <a href='/cgu' target='_blank'>Conditions Générales d'Utilisations</a> et la <a href='/privacy' target='_blank'>politique de confidentialité</a> de Vigicare.*</Trans></p>
                                        </div>
                                        <Button disabled={!acceptCGU || name === '' || email === '' || email.match(/^\S+@\S+\.\S+$/) === null || password === '' || password.match(/^(?=.*[A-Z])(?=.*[.!@#$&*])(?=.*[0-9]).{8,}$/) === null || passwordConfirm !== password || (proInput && profession === '') || (proInput && profession === 'Autre' && professionAutre === '')} onClick={() => mutationSignin.mutate({email, name, password, pro, proInput, profession, professionAutre, acceptNewsletter})} variant="contained" color="blue" sx={{ textTransform: 'none !important', marginBottom: '12px', padding: '10px 0px 10px 0px'  }}><Trans>Créer mon compte</Trans></Button>
                                        <Link className='linkButton' to={"/auth"}><Button variant="outlined" color="white" sx={{ textTransform: 'none !important', textDecorationLine: 'none !important',  padding: '10px 0px 10px 0px', color: "#000 !important"  }}><Trans>J'ai déjà un compte Vigicare</Trans></Button></Link>
                                    </Box>
                                </>
                            }
                        </div>                        
                    </Grid>
            </ThemeProvider>
        )
    }; 
};



export default withRouter(withQuery(withTranslation()(Inscription)));


function withRouter( Child ) {
    return ( props ) => {
        const [searchParams, setSearchParams] = useSearchParams(); 
        const params = searchParams.get("p");
        const navig = useNavigate();
        return <Child { ...props } params ={ params } navigation={navig} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const queryUser = useQuery(['fetch-user'], () => getUser().then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationSignin = useMutation({
            mutationFn: (data) => {
                let userdata = {};
                if (data.pro) {
                    userdata = {
                        name: data.name,
                        email: data.email,
                        password: data.password,
                        proSante: 1,
                        proSanteLabel: data.professionAutre !== null ? data.professionAutre : data.profession,
                        acceptNewsletter: data.acceptNewsletter ? 1 : 0,
                    }
                } else {
                    userdata = {
                        email: data.email,
                        name: data.name,
                        password: data.password,
                        proSante: data.proInput ? 1 : 0,
                        proSanteLabel: data.professionAutre !== null ? data.professionAutre : data.profession,
                        acceptNewsletter: data.acceptNewsletter ? 1 : 0,
                    }
                }
                return signinUser(userdata).then((r) => {return r.data}).catch(e =>  {throw e});
            },
        });
        return <Child { ...props } mutationSignin ={ mutationSignin } queryUser={queryUser} />;
    }
}
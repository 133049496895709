import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { Grid, TextField, MenuItem, Button, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useMutation } from "@tanstack/react-query";

import DrugSearchField from "../../../sharedComponents/DrugSearchField";
import { fetchNbReports } from "../../../../api/search";
import { fetchNbImput } from "../../../../api/products";

export const Product = ({
  connected,
  userData,
  data,
  handleChange,
  handlePrev,
  handleNext,
  handleShowProductsInfo,
}) => {
  useTranslation();

  const [displayMissing, setDisplayMissing] = useState(false);

  const { mutateAsync: getNbOfReportsMutation, isLoading: isNbReportsLoading } =
    useMutation({
      mutationFn: (product) => {
        return fetchNbReports(product)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
      onSuccess: () => { },
    });

  const {
    mutateAsync: getNbOfReportsVigicareMutation,
    isLoading: isNbReportsVigicareLoading,
  } = useMutation({
    mutationFn: (product) => {
      return fetchNbImput(product)
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        });
    },
    onSuccess: () => { },
  });

  const handleChangeType = (e) => {
    handleChange({ ...data, typeProduit: e.target.value });
  };

  const handleChangeDrug = (drug) => {
    if (typeof drug === "string") {
      if (drug !== data.nomProduit) {
        handleChange({
          ...data,
          nomProduit: drug,
          substanceActive1: "",
          substanceActive2: "",
          substanceActive3: "",
          selectedDrug: drug
            ? {
              tradeName: drug,
              activeSubstances: [],
            }
            : null,
        });
      }
    } else {
      const [substanceActive1, substanceActive2, substanceActive3] = drug
        ? drug.activeSubstances
        : [];

      handleChange({
        ...data,
        nomProduit: drug ? drug.tradeName : "",
        substanceActive1:
          substanceActive1 !== undefined ? substanceActive1 : "",
        substanceActive2:
          substanceActive2 !== undefined ? substanceActive2 : "",
        substanceActive3:
          substanceActive3 !== undefined ? substanceActive3 : "",
        selectedDrug: drug,
      });
    }
  };

  const handleChangeInput = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "nomProduit":
        handleChange({ ...data, nomProduit: value });
        setDisplayMissing(false);
        break;
      case "marqueProduit":
        handleChange({ ...data, marqueProduit: value });
        break;
      case "substanceActive1":
        handleChange({ ...data, substanceActive1: value });
        break;
      case "substanceActive2":
        handleChange({ ...data, substanceActive2: value });
        break;
      case "substanceActive3":
        handleChange({ ...data, substanceActive3: value });
        break;
      default:
        break;
    }
  };

  const _handleNext = () => {
    if (data.typeProduit === "Médicament") {
      getNbOfReportsMutation(data.nomProduit)
        .then((res) => {
          handleChange({ ...data, nbReports: res.nbOfReports });
          handleNext();
        })
        .catch((err) => setDisplayMissing(true));
    } else {
      if (connected) {
        getNbOfReportsVigicareMutation(data.nomProduit)
          .then((res) => {
            handleChange({ ...data, nbReportsVigicare: res.nb });
          })
          .finally(() => handleNext());
      } else {
        handleNext();
      }
    }
  };

  const handleContinue = () => {
    setDisplayMissing(false);
    handleNext();
  };

  const handleCloseAlert = () => {
    setDisplayMissing(false);
  };

  const renderForm = () => {
    let placeholder = "";
    let activeSubstance1Label = <Trans>Substance active 1 *</Trans>;

    switch (data.typeProduit) {
      case "Dispositif médical":
        placeholder = "Stérimar Soufre";
        activeSubstance1Label = <Trans>Substance active 1 (facultatif)</Trans>;
        break;
      case "Cosmétique":
        placeholder = "Dream Long Shampooing reconstructeur";
        break;
      case "Complément alimentaire":
        placeholder = "Good night";
        break;

      default:
    }

    return (
      <>
        <Grid
          className="inputSmallRow"
          sx={{ marginTop: "24px", marginBottom: "16px" }}
        >
          <TextField
            fullWidth
            id="typeProduit"
            select
            value={data.typeProduit}
            onChange={handleChangeType}
            label={<Trans>Type de produit *</Trans>}
          >
            <MenuItem value="Médicament">
              <Trans>Médicament</Trans>
            </MenuItem>
            <MenuItem value="Dispositif médical">
              <Trans>Dispositif médical</Trans>
            </MenuItem>
            <MenuItem value="Cosmétique">
              <Trans>Cosmétique</Trans>
            </MenuItem>
            <MenuItem value="Complément alimentaire">
              <Trans>Complément alimentaire</Trans>
            </MenuItem>
          </TextField>
          <InfoIcon
            className="infoImputabilite"
            onClick={handleShowProductsInfo}
            color="primary"
          />
        </Grid>
        {data.typeProduit && (
          <Grid className="questionForm">
            {data.typeProduit === "Médicament" ? (
              <DrugSearchField
                fullWidth
                onChange={handleChangeDrug}
                selectedDrug={data.selectedDrug}
              />
            ) : (
              <TextField
                fullWidth
                id="nomProduit"
                type="text"
                value={data.nomProduit}
                onChange={handleChangeInput}
                label={<Trans>Nom du produit *</Trans>}
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder}
              />
            )}
            <TextField
              fullWidth
              id="marqueProduit"
              type="text"
              value={data.marqueProduit}
              onChange={handleChangeInput}
              label={<Trans>Nom de la marque/fabricant (facultatif)</Trans>}
              InputLabelProps={{ shrink: true }}
              placeholder="Humex"
            />
            <p>
              <Trans>Substances actives :</Trans>
            </p>
            <TextField
              fullWidth
              id="substanceActive1"
              type="text"
              value={data.substanceActive1}
              onChange={handleChangeInput}
              label={activeSubstance1Label}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              id="substanceActive2"
              type="text"
              value={data.substanceActive2}
              onChange={handleChangeInput}
              label={<Trans>Substance active 2 (facultatif)</Trans>}
              disabled={data.substanceActive1 === ""}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              id="substanceActive3"
              type="text"
              value={data.substanceActive3}
              onChange={handleChangeInput}
              label={<Trans>Substance active 3 (facultatif)</Trans>}
              disabled={data.substanceActive2 === ""}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}
        <p className="requiredMessage">
          <Trans>* Champs obligatoires</Trans>
        </p>
      </>
    );
  };

  return (
    <Grid>
      <Popup
        open={displayMissing}
        position="center"
        contentStyle={{ minWidth: "80%" }}
        onClose={handleCloseAlert}
      >
        <Grid className="infoPanel">
          <div className="infoTitle">
            <p>
              <b>Attention</b>
            </p>
          </div>
          <p>
            <Trans>Vous avez entré un nom de médicament qui n'est pas reconnu par notre
              base de données.</Trans>
          </p>
          <p>
            <i><Trans>Si vous êtes sûr(e) du nom de produit renseigné, vous pouvez continuer</Trans></i>
          </p>
          <div className="infoTitle">
            <Button onClick={handleCloseAlert} variant="outlined" color="error">
              <Trans>Je modifie</Trans>
            </Button>
            <Button onClick={handleContinue} variant="contained">
              <Trans>Je continue</Trans>
            </Button>
          </div>
        </Grid>
      </Popup>

      {renderForm()}

      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
          <Button
            color="inherit"
            onClick={handlePrev}
            sx={{ mr: 1 }}
            disabled={isNbReportsLoading || isNbReportsVigicareLoading}
          >
            <Trans>Retour</Trans>
          </Button>

          <Button
            variant="contained"
            color="blue"
            onClick={_handleNext}
            disabled={
              data.nomProduit === "" ||
              (data.typeProduit !== "Dispositif médical" &&
                data.substanceActive1 === "") ||
              isNbReportsLoading ||
              isNbReportsVigicareLoading
            }
          >
            <Trans>Suivant</Trans>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

import moment from 'moment';
import 'moment/locale/fr';

export function emailContactFR(dept) {
    if (dept === "02" || dept === "60" || dept === "80") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-amiens.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-amiens.fr</a>)}
    else if (dept === "49" || dept === "53" || dept === "72") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-angers.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-angers.fr</a>)}
    else if (dept === "25" || dept === "39" || dept === "70" || dept === "90") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-besancon.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-besancon.fr</a>)}
    else if (dept === "24" || dept === "33" || dept === "40" || dept === "47" || dept === "64" || dept === "971" || dept === "972" || dept === "973" || dept === "974" || dept === "976") {return (<a data-rel="external" href="mailto:pharmacovigilance@u-bordeaux.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@u-bordeaux.fr</a>)}
    else if (dept === "29" || dept === "56") {return (<a data-rel="external" href="mailto:crpv.brest@chu-brest.fr?subject=Déclaration Médicament" className="mailAdressEndForm">crpv.brest@chu-brest.fr</a>)}
    else if (dept === "14" || dept === "50" || dept === "61") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-caen.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-caen.fr</a>)}
    else if (dept === "03" || dept === "15" || dept === "43" || dept === "63" ) {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-clermontferrand.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-clermontferrand.fr</a>)}
    else if (dept === "21" || dept === "58" || dept === "71" || dept === "89") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-dijon.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-dijon.fr</a>)}
    else if (dept === "38") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-grenoble.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-grenoble.fr</a>)}
    else if (dept === "59" || dept === "62") {return (<a data-rel="external" href="mailto:pharmacovigilance@chru-lille.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chru-lille.fr</a>)}
    else if (dept === "19" || dept === "23" || dept === "87") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-limoges.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-limoges.fr</a>)}
    else if (dept === "01" || dept === "07" || dept === "26" || dept === "69" || dept === "73" || dept === "74") {return (<a data-rel="external" href="mailto:centre.pharmacovigilance@chu-lyon.fr?subject=Déclaration Médicament" className="mailAdressEndForm">centre.pharmacovigilance@chu-lyon.fr</a>)}
    else if (dept === "04" || dept === "2A" || dept === "2B" || dept === "20" || dept === "13" || dept === "84") {return (<a data-rel="external" href="mailto:pharmacovigilance@ap-hm.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@ap-hm.fr</a>)}
    else if (dept === "11" || dept === "30" || dept === "34" || dept === "48" || dept === "66") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-montpellier.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-montpellier.fr</a>)}
    else if (dept === "54" || dept === "55" || dept === "57" || dept === "88") {return (<a data-rel="external" href="mailto:crpv@chru-nancy.fr?subject=Déclaration Médicament" className="mailAdressEndForm">crpv@chru-nancy.fr</a>)}
    else if (dept === "44" || dept === "85" ) {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-nantes.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-nantes.fr</a>)}
    else if (dept === "06" || dept === "05" || dept === "83") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-nice.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-nice.fr</a>)}
    else if (dept === "92") {return (<a data-rel="external" href="mailto:crpv.hegp.egp@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">crpv.hegp.egp@aphp.fr</a>)}
    else if (dept === "77" || dept === "94") {return (<a data-rel="external" href="mailto:hmn-pharmacovigilance@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">hmn-pharmacovigilance@aphp.fr</a>)}
    else if (dept === "78" || dept === "95") {return (<a data-rel="external" href="mailto:pharmacovigilance.fwd.lrb@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance.fwd.lrb@aphp.fr</a>)}
    else if (dept === "93") {return (<a data-rel="external" href="mailto:pharmacovigilance.huep@sat.aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance.huep@sat.aphp.fr</a>)}
    else if (dept === "91") {return (<a data-rel="external" href="mailto:pharmacovigilance.hupc@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance.hupc@aphp.fr</a>)}
    else if (dept === "16" || dept === "17" || dept === "79" || dept === "86") {return (<a data-rel="external" href="mailto:pharmaco.clin@chu-poitiers.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmaco.clin@chu-poitiers.fr</a>)}
    else if (dept === "08" || dept === "10" || dept === "51" || dept === "52") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-reims.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-reims.fr</a>)}
    else if (dept === "22" || dept ===  "35") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-rennes.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-rennes.fr</a>)}
    else if (dept === "27" || dept ===  "76") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-rouen.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-rouen.fr</a>)}
    else if (dept === "42" ) {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-st-etienne.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-st-etienne.fr</a>)}
    else if (dept === "67" || dept === "68") {return (<a data-rel="external" href="mailto:pharmacovigilance@chru-strasbourg.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chru-strasbourg.fr</a>)}
    else if (dept === "09" || dept === "12" || dept === "31" || dept === "32" || dept === "46" || dept === "65" || dept === "81" || dept === "82") {return (<a data-rel="external" href="mailto:pharmacovigilance@chu-toulouse.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance@chu-toulouse.fr</a>)}
    else if (dept === "18" || dept === "28" || dept === "36" || dept === "37" || dept === "37" || dept === "41" || dept === "45") {return (<a data-rel="external" href="mailto:crpv@chu-tours.fr?subject=Déclaration Médicament" className="mailAdressEndForm">crpv@chu-tours.fr</a>)}
    else if (dept === "75") {
        return (
            <>
                Paris 1-14-15-16 : <a data-rel="external" href="mailto:crpv.hegp.egp@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">crpv.hegp.egp@aphp.fr</a>
                Paris 2-9-10-17-18-19 : <a data-rel="external" href="mailto:pharmacovigilance.fwd.lrb@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance.fwd.lrb@aphp.fr</a>
                Paris 5-8-13 : <a data-rel="external" href="mailto:pharmaco.vigilance.psl@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmaco.vigilance.psl@aphp.fr</a>
                Paris 3-4-11-12-20 : <a data-rel="external" href="mailto:pharmacovigilance.huep@sat.aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance.huep@sat.aphp.fr</a>
                Paris 6-7 : <a data-rel="external" href="mailto:pharmacovigilance.hupc@aphp.fr?subject=Déclaration Médicament" className="mailAdressEndForm">pharmacovigilance.hupc@aphp.fr</a>
            </>
        )
    } else { return <p className="mailAdressEndForm">Adresse introuvable - Erreur de saisie</p>}
}

export const dptsFr = [
    { code: "01", d: "Ain" },
    { code: "02", d: "Aisne" },
    { code: "03", d: "Allier" },
    { code: "04", d: "Alpes-de-Haute-Provence" },
    { code: "05", d: "Hautes-Alpes" },
    { code: "06", d: "Alpes-Maritimes" },
    { code: "07", d: "Ardèche" },
    { code: "08", d: "Ardennes" },
    { code: "09", d: "Ariège" },
    { code: "10", d: "Aube" },
    { code: "11", d: "Aude" },
    { code: "12", d: "Aveyron" },
    { code: "13", d: "Bouches-du-Rhône" },
    { code: "14", d: "Calvados" },
    { code: "15", d: "Cantal" },
    { code: "16", d: "Charente" },
    { code: "17", d: "Charente-Maritime" },
    { code: "18", d: "Cher" },
    { code: "19", d: "Corrèze" },
    { code: "21", d: "Côte-d'Or" },
    { code: "22", d: "Côtes-d'Armor" },
    { code: "23", d: "Creuse" },
    { code: "24", d: "Dordogne" },
    { code: "25", d: "Doubs" },
    { code: "26", d: "Drôme" },
    { code: "27", d: "Eure" },
    { code: "28", d: "Eure-et-Loir" },
    { code: "29", d: "Finistère" },
    { code: "2A", d: "Corse-du-Sud" },
    { code: "2B", d: "Haute-Corse" },
    { code: "30", d: "Gard" },
    { code: "31", d: "Haute-Garonne" },
    { code: "32", d: "Gers" },
    { code: "33", d: "Gironde" },
    { code: "34", d: "Hérault" },
    { code: "35", d: "Ille-et-Vilaine" },
    { code: "36", d: "Indre" },
    { code: "37", d: "Indre-et-Loire" },
    { code: "38", d: "Isère" },
    { code: "39", d: "Jura" },
    { code: "40", d: "Landes" },
    { code: "41", d: "Loir-et-Cher" },
    { code: "42", d: "Loire" },
    { code: "43", d: "Haute-Loire" },
    { code: "44", d: "Loire-Atlantique" },
    { code: "45", d: "Loiret" },
    { code: "46", d: "Lot" },
    { code: "47", d: "Lot-et-Garonne" },
    { code: "48", d: "Lozère" },
    { code: "49", d: "Maine-et-Loire" },
    { code: "50", d: "Manche" },
    { code: "51", d: "Marne" },
    { code: "52", d: "Haute-Marne" },
    { code: "53", d: "Mayenne" },
    { code: "54", d: "Meurthe-et-Moselle" },
    { code: "55", d: "Meuse" },
    { code: "56", d: "Morbihan" },
    { code: "57", d: "Moselle" },
    { code: "58", d: "Nièvre" },
    { code: "59", d: "Nord" },
    { code: "60", d: "Oise" },
    { code: "61", d: "Orne" },
    { code: "62", d: "Pas-de-Calais" },
    { code: "63", d: "Puy-de-Dôme" },
    { code: "64", d: "Pyrénées-Atlantiques" },
    { code: "65", d:" Hautes-Pyrénées" },
    { code: "66", d: "Pyrénées-Orientales" },
    { code: "67", d: "Bas-Rhin" },
    { code: "68", d: "Haut-Rhin" },
    { code: "69", d: "Rhône" },
    { code: "70", d: "Haute-Saône" },
    { code: "71", d: "Saône-et-Loire" },
    { code: "72", d: "Sarthe" },
    { code: "73", d: "Savoie" },
    { code: "74", d: "Haute-Savoie" },
    { code: "75", d: "Paris" },
    { code: "76", d: "Seine-Maritime" },
    { code: "77", d: "Seine-et-Marne" },
    { code: "78", d: "Yvelines" },
    { code: "79", d: "Deux-Sèvres" },
    { code: "80", d: "Somme" },
    { code: "81", d: "Tarn" },
    { code: "82", d: "Tarn-et-Garonne" },
    { code: "83", d: "Var" },
    { code: "84", d: "Vaucluse" },
    { code: "85", d: "Vendée" },
    { code: "86", d: "Vienne" },
    { code: "87", d: "Haute-Vienne" },
    { code: "88", d: "Vosges" },
    { code: "89", d: "Yonne" },
    { code: "90", d: "Territoire de Belfort" },
    { code: "91", d: "Essonne" },
    { code: "92", d: "Hauts-de-Seine" },
    { code: "93", d: "Seine-Saint-Denis" },
    { code: "94", d: "Val-de-Marne" },
    { code: "95", d: "Val-d'Oise" },
    { code: "971", d: "Guadeloupe" },
    { code: "972", d: "Martinique" },
    { code: "973", d: "Guyane" },
    { code: "974", d: "La Réunion" },
    { code: "976", d: "Mayotte" },
]

export const effectsListFR = [
    'Problème hématologique : Anémie, Carence en fer, Baisse du taux de fer...',
    'Problème cardiaque : Modification du rythme cardiaque, Palpitation, Tachycardie, Trouble cardiaque ...',
    'Problème congénitale, génétique ou familial : Anomalie du developpement du fœtus, Malformation congénitale, Anomalie génétique, Malformation fœtale, Maladie génétique ...',
    'Problème oreille : Surdité, Baisse de l\'audition, Dysfonctionnement de l\'oreille interne, Perte d\'équilibre, Vertiges, Otite, Bourdonnement, Acouphene, Tympans douloureux ...',
    'Problème endocrinien : Dysfonctionnement thyroïdien, Hyperthyroïdie, Hypothyroïdie, Troubles des glandes surrénales et corticosurrénales, Maladie de Cushing, Maladie d\'Addison Acromégalie, Dysfonctionnement de la parathyroide Hyperparathyroïdie, Hypoparathyroïdie, Troubles de la puberté et de la fonction de reproduction, Diabète, Trouble endocrinien (autre)...',
    'Problème œil : Trouble de la vision, Baisse de la vue, Conjonctivite, Paupières rouges douloureuses ou enflées, irritation occulaire, oeil rouge...',
    'Problème digestif : Troubles digestifs, Vomissement, Nausées, Constipation, Diarrhées, Brulures d\'estomac, Remontées acides, Crampes intestinales, Balonnements ...',
    'Problème général : Douleurs, Fatigue, Maux de tete, Migraines, Céphalées...',
    'Problème hépatobiliaire : Selles jaunes, Variation des transaminases (ASAT, ALAT), Douleur au foie, hépatique, Douleur à la vésicule biliaire...',
    'Problème système immunitaire : Allergies, Rougeurs, Œdème, Sensation de chaleur, Urticaire, Démangeaison, Crise d\'asthme, Poussées d\'eczema, Oedeme de Quincke, Choc anaphylactique ...',
    'Infection bactérienne ou virale : Septicémies, Infection urinaire ou génitale, Herpes, Grippe, Covid-19, Infections virales, Bacteriennes...',
    'Erreur dans la prise de produit, Mauvaise voie d\'administration, Empoisonnement : Mauvaise utilisation du produit, Mésusage, Surdosage, Prise trop importante de produit...',
    'Blessure, fracture suite à la prise du produit : Blessures de tous types, Fractures, Entorses, Lésions ligamentaires',
    'Variations des constantes biologiques suites à divers examens (radio, prise de sang, test antigenique etc…) : résultats d\'investigation (ex: sars-cov2 antigène, augmentation marqueur tumoral, progesterone augmenté, test fonction hepatique, transmainase, vitamine D...)',
    'Désordre métabolique ou nutritionnel : Troubles du métabolisme, Diabète (type 1, type 2, insipide, gestationnel, etc..), Dénutrition, déshydratation, hypocalcémie, Hypercholestorolémie, hypertyglycéridémie...',
    'Désordre musculosquelettique : Douleur, Pathologie articulaire (arthrose, polyarthrite rhumatoïde, arthrite psoriasique,goutte, spondylarthrite ankylosante etc..), Douleurs, fragilité osseuses (ostéoporose, ostéoporose, l\'ostéopénie, fractures dues, etc.. ), Douleurs musculaires et tendineuses (tendinites, courbatures, etc..), Trouble de la mobilité et de la dextérité (difficulité à se déplacer, à mobiliser les extrémités...)',
    'Tumeur bénigne ou maligne ou non spécifié : Kyste, Polypes, Excroissances de peau ou autres tumeurs bénignes, Tumeurs malignes ou non spécifiques',
    'Problème système nerveux : AVC (accident vasculaire cerebral), Paralysie (faciale ou autres), Fourmillement, engourdissement, Douleurs nevralgiques, encéphalite, Troubles neurologiques (Epilepsie, etc..)...',
    'Conséquences chez femme enceinte, peri et postpartum : Grossesse non désirée, non prévue (altération de la contraception), Troubles péri ou post-partum, Déclenchement accouchement, accouchement prématuré, bébé prématuré, fausse couche...',
    'Problème qualité du produit : Altération de l\'odeur, de la couleur, de l\'aspect général, de la stérilité...',
    'Problème ordre psychologique : Angoisse, Stress, Anxiété, Dépression, Troubles dépressifs, Cauchemar, Agressivité, Hallucination, Claustrophobie, Bipolarité, Autisme...',
    'Problème de l\'appareil urinaire : Trouble urinaire (glomérulonéphrite, kyste rénal,protéinurie,pollakiurie, dysurie, etc…), Infection urinaire, présence de sang dans les urines, douleurs à la miction, Douleurs rénales, calculs rénaux, coliques néphrétiques...',
    'Problème de l\'appareil reproducteur et poitrine : Dérèglement menstruel, règles douleureuses, douleurs, tensions mammaires, douleurs périnéales, Leucorrhées, pertes vaginales inhabituelles, spotting, sécheresse vaginale, SOPK, kystes ovariens, douleurs ovariennes..',
    'Probème respiratoire, pulmonaire : Douleurs, difficultés respiratoires, essouflements, pression au niveau de la cage thoracique, Anomalies pleurales, infiltrations pulmonaires...',
    'Problème de peau et tissus (acné, eczéma, erythème-rougeur, ecchymose-bleus, infection aux ongles, peau sèche..) : Rougeurs, sècheresse, désquamation, prurit, démangeaisons, éruption cutanées, Pathologies cutanées (rosacée, mychoses, pytiriasis versicolor, acné, eczéma, psoriasis, pétéchies (petites tâches rouges)...), Escarres, Abcès, Verrues, Pathologies de l\'ongle (mychoses, panaris, ongles incarnés, etc...)',
    'Atteinte de la qualité de vie : Eviction sociale de tous types, Problèmes de peau, de mobilité, de vue, Absentéisme, Diminution des capacités pour conduire',
    'Chirurgie et procédures médicales : Hospitalisation, Soins, Chirurgies de tous types',
    'Problème vasculaire : Angiopathie, Hemangiome, Hypotension orthostatique, Phlébites, Varicosités, Varices, Mauvaise circulation lymphatique ou veineuse, Oedème des membres inférieurs, Picotements, Fourmillments dans les membres inférieurs, Pâleur des extrémités...', 
];

export const effectsListEN = [
    'Haematologic disorders',
    'Cardiac disorders',
    'Congenital, familial and genetic disorders',
    'Ear and labyrinth disorders',
    'Endocrine disorders',
    'Eye disorders',
    'Gastrointestinal disorders',
    'General disorders and administration site conditions',
    'Hepatobiliary disorders',
    'Immune system disorders',
    'Infections and infestations',
    'Poisoning and procedural complications',
    'Injury, fracture',
    'Investigations',
    'Metabolism and nutrition disorders',
    'Musculoskeletal and connective tissue disorders',
    'Neoplasms benign, malignant and unspecified',
    'Nervous system disorders',
    'Pregnancy, puerperium and perinatal conditions',
    'Product issues',
    'Psychiatric disorders',
    'Renal and urinary disorders',
    'Reproductive system and breast disorders',
    'Respiratory, thoracic and mediastinal disorders',
    'Skin and subcutaneous tissue disorders',
    'Social circumstances',
    'Surgical and medical procedures',
    'Vascular disorders', 
]





export const history = [
    {
        type: 'Effet indésirable',
        produit: 'HUMEX RHUME',
        date: moment().format().toString(),
        lien: 'Lien probable',
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        date: moment().format().toString(),
        lien: null,
    },
    {
        type: 'Effet indésirable',
        produit: 'DOLIPRANE',
        date: moment().subtract(1, 'days').format().toString(),
        lien: 'Lien certain'
    },
    {
        type: 'Effet indésirable',
        produit: 'IBUPROFEN',
        date: moment().subtract(3, 'days').format().toString(),
        lien: 'Lien douteux',
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        date: moment().subtract(6, 'days').format().toString(),
        lien: null,
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        date: moment().subtract(6, 'days').format().toString(),
        lien: null,
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        date: moment().subtract(6, 'days').format().toString(),
        lien: null,
    },
]

export const historyDeclaration = [
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        patient: 'M. AV E',
        date: moment().format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        patient: 'Mme DA E',
        date: moment().format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'DOLIPRANE',
        patient: 'Mme AS D',
        date: moment().subtract(1, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'IBUPROFEN',
        patient: 'Mme GA C',
        date: moment().subtract(3, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        patient: 'M. MV X',
        date: moment().subtract(6, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'IBUPROFEN',
        patient: 'Mme GA C',
        date: moment().subtract(3, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        patient: 'M. MV X',
        date: moment().subtract(6, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'IBUPROFEN',
        patient: 'Mme GA C',
        date: moment().subtract(3, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        patient: 'M. MV X',
        date: moment().subtract(6, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'IBUPROFEN',
        patient: 'Mme GA C',
        date: moment().subtract(3, 'days').format().toString(),
    },
    {
        type: 'Déclaration en ligne',
        produit: 'HUMEX RHUME',
        patient: 'M. MV X',
        date: moment().subtract(6, 'days').format().toString(),
    },
]


export const dataProductSample = {
    ageDistribution: [
        452,
        6113,
        12421,
        8154,
        52319,
        35600,
        14628,
        15541,
        41953,
    ],
    sexDistribution:{
        m: 63773, f: 107284, unknown: 16124,
    },
    reportedEffects: {
        labels: [
            "Problème de peau et tissus",
            "Erreur dans la prise de produit",
            "Problème général",
            "Problème système nerveux",
            "Problème ordre psychologique",
        ],
        nbADRS: [
            53675,
            43263,
            39822,
            19897,
            17050,
        ]
    }
}


export const historySearch = [
    {
        produit: 'HUMEX RHUME',
        voieAdmission: 'gélules et comprimés',
        ratio: '0.72',
        nbEffets: '25',
        dataProductSample,
    },
    {
        produit: 'PARACETAMOL',
        voieAdmission: 'poudre, gélules et comprimés',
        ratio: '0.42',
        nbEffets: '187 181',
        dataProductSample,
    },
    {
        produit: 'IBUPROFEN',
        voieAdmission: 'gélules et comprimés',
        ratio: '0.49',
        nbEffets: '91',
        dataProductSample,
    },
]

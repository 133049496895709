import React from 'react';
import { Grid, Button, Box, TextField } from '@mui/material';

export default class Commentaires extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    commentaires: props.data.commentaires,
                },
            };
        } else {
            this.state = {
                data: {
                    commentaires: ''
                }
            }
        }

        this.handleChange = this.handleChange.bind(this);

        window.scrollTo(0, 0);
    }
       

    handleChange(ev) {
        if (ev.target.value !== undefined) this.setState({ data: { commentaires: ev.target.value }})
    }

    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
            <Grid>
                    <p className="pageTitle">Commentaires éventuels</p>
                    <Grid className="lineVectors">
                        <hr className="lineVector" style={{width}} />
                        <hr className="lineVectorFull" />
                    </Grid>
                    <div className='inputForm'>
                        <TextField multiline rows={6} value={data.commentaires} label="Autres informations et commentaires" onChange={this.handleChange}  />
                    </div>
                    <Box className="bottomBtnForm">
                        <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{data.commentaires === "" ? "PASSER" : "CONFIRMER"}</Button>
                    </Box>
            </Grid>
        </>
    }
}
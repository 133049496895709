import React from 'react';
import { Trans } from 'react-i18next';
    
import { Grid, Button, TextField, Radio, Checkbox, Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default class Emetteur extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data : {
                    date: props.data.date,
                    nom: props.data.nom,
                    prenom: props.data.prenom,
                    adresse: props.data.adresse,
                    codePostal: props.data.codePostal,
                    commune: props.data.commune,
                    email: props.data.email,
                    telephone: props.data.telephone,
                    fax: props.data.fax,
                    patient: props.data.patient,
                    etablissement: props.data.etablissement,
                    association: props.data.association,
                    signalement: props.data.signalement,
                },
                errorIncomplete: false,
            };
        } else {
            this.state = {
                data : {
                    date: null,
                    nom: '',
                    prenom: '',
                    adresse: '',
                    codePostal: '',
                    commune: '',
                    email: '',
                    telephone: '',
                    fax: '',
                    patient: false,
                    etablissement: false,
                    association: false,
                    signalement: null,
                },
                errorIncomplete: false,
            };
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        window.scrollTo(0, 0);
    }

    handleChangeDate(ev) {
        this.setState({ data: { ...this.state.data, date: ev } });
    }

    handleChange(ev) {
        const { data } = this.state;
        switch (ev.target.id) {
            case "nom" : this.setState({ data: { ...data, nom: ev.target.value  } }); break;
            case "prenom" : this.setState({ data: { ...data, prenom: ev.target.value } }); break;
            case "adresse" : this.setState({ data: { ...data, adresse: ev.target.value }  }); break;
            case "codePostal" : this.setState({ data: { ...data, codePostal: ev.target.value } }); break;
            case "commune" : this.setState({ data: { ...data, commune: ev.target.value  } }); break;
            case "email" : this.setState({ data: { ...data, email: ev.target.value } }); break;
            case "telephone" : this.setState({ data: { ...data, telephone: ev.target.value } }); break;
            case "fax" : this.setState({ data: { ...data, fax: ev.target.value } }); break;
            case "patient" : this.setState({ data: { ...data, patient: !data.patient, etablissement: false, association: false } }); break;
            case "etablissement" : this.setState({ data: { ...data, etablissement: !data.etablissement, patient: false, association: false } }); break;
            case "association" : this.setState({ data: { ...data, association: !data.association, etablissement: false, patient: false } }); break;
            case "true" : this.setState({ data: { ...data, signalement: true } }); break;
            case "false" : this.setState({ data: { ...data, signalement: false } }); break;
            default: break;
        }
    }

    onSubmit() {
        const { data } = this.state;
        if (data.date !== null && data.date.isValid() 
        && data.date.isBefore() && data.nom.match(/^[A-Za-z]{2,}$/) !== null
        && data.prenom.match(/^[A-Za-z]{2,}$/) !== null && data.codePostal !== "" 
        && data.adresse !== "" && data.email !== "" 
        && data.telephone !== "" && data.commune !== ""
        && data.signalement !== null && (data.patient || data.etablissement || data.association)) {
            this.props.handler(this.state.data);
        } else {
            window.scrollTo(0, 0);
            this.setState({ errorIncomplete: true });
        }
    }

    render() {
        const { data, errorIncomplete } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
        <p className="pageTitle">Émetteur du signalement</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}} />
                <hr className="lineVectorFull" />
            </div>
            {errorIncomplete ? <Box className='errorText'>Champs manquants ou incorrects</Box> : null}

            <div className='inputForm'>
                <DesktopDatePicker id="date" inputFormat="DD/MM/YYYY" value={data.date} onChange={this.handleChangeDate} label="Date d'envoi du signalement *" renderInput={(params) => {return <TextField {...params} error={errorIncomplete && (data.date === null || (!data.date.isValid() || !data.date.isBefore()))} />}} />
                <TextField fullWidth id="nom" type="text" value={data.nom} onChange={this.handleChange} label="Nom * (minimum 2 lettres)" error={errorIncomplete && data.nom.match(/^[A-Za-z]{2,}$/) === null} />
                <TextField fullWidth id="prenom" type="text" value={data.prenom} onChange={this.handleChange} label="Prénom * (minimum 2 lettres)" error={errorIncomplete && data.prenom.match(/^[A-Za-z]{2,}$/) === null} />
                <TextField fullWidth id="adresse" type="text" value={data.adresse} onChange={this.handleChange} label="Adresse *" error={errorIncomplete && data.adresse === ""} />
                
                <div className="inputSmallRow">
                    <TextField  id="codePostal" className="inputSmall" type="number" value={data.codePostal} onChange={this.handleChange} label="Code Postal *" error={errorIncomplete && data.codePostal === ""} />
                    <TextField  id="commune" className="inputSmall" type="text" value={data.commune} onChange={this.handleChange} label="Commune *" error={errorIncomplete && data.commune === ""} />
                </div>
                <TextField fullWidth id="email" type="text" value={data.email} onChange={this.handleChange} label="Email *" error={errorIncomplete && data.email === ""} />
                <TextField fullWidth id="telephone" type="text" value={data.telephone} onChange={this.handleChange} label="Téléphone *" error={errorIncomplete && data.telephone === ""} />
                <TextField fullWidth id="fax" type="text" value={data.fax} onChange={this.handleChange} label="Fax" />

                <Grid>
                    <p className="robotoFontB">Vous êtes *</p>
                    {errorIncomplete && !data.patient && !data.etablissement && !data.association ? <Box className='errorText'>Donnée manquante</Box> : null}
                    <Grid className="formRow">
                        <label className='robotoFont' style={{ cursor: 'pointer' }}>
                            <Checkbox id="patient" checked={data.patient} onChange={this.handleChange} />
                            {' Un patient'}
                        </label>
                    </Grid>
                    <Grid className="formRow">
                        <label className='robotoFont' style={{ cursor: 'pointer' }}>
                            <Checkbox id="etablissement" checked={data.etablissement} onChange={this.handleChange} />
                            {' Établissement de santé'}
                        </label>
                    </Grid>
                    <Grid className="formRow">
                        <label className='robotoFont' style={{ cursor: 'pointer' }}>
                            <Checkbox id="association" checked={data.association} onChange={this.handleChange} />
                            {' Association distribuant DM à domicile'}
                        </label>
                    </Grid>
                    <p className="robotoFontB">L'émetteur du signalement est-il le correspondant matériovigilance ? *</p>
                    {errorIncomplete && data.signalement === null ? <Box className='errorText'>Donnée manquante</Box> : null}
                    <Grid className='inputRadioRow'>
                        <label className='robotoFont' style={{ cursor: 'pointer' }}>
                            <Radio id="true" checked={data.signalement === true} onChange={this.handleChange} color={errorIncomplete && data.signalement === null ? 'error' : 'primary'} />
                            {'  Oui'}
                        </label>     
                        <label className='robotoFont' style={{ cursor: 'pointer' }}>
                            <Radio id="false" checked={data.signalement === false} onChange={this.handleChange} />
                            {'  Non'}
                        </label>   
                    </Grid>
                </Grid>
            </div>
            <p className="requiredMessage">
                <Trans>* Champs obligatoires</Trans>
            </p>
            <Grid textAlign={'end'} marginBottom="24px">
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{"SUIVANT"}</Button>
            </Grid>
        </>
    }
}
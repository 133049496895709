import React from 'react';
import { Grid, TextField, Box, Button } from '@mui/material';
    
export default class LotMedicament extends React.Component {
    constructor (props) {
        super(props);
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data: {
                    numLot: props.data.numLot,
                    service: props.data.service,
                    pharmacie: props.data.pharmacie,
                }
            }
        } else {
            this.state = {
                data: {
                    numLot: '',
                    service: '',
                    pharmacie: '',
                }
            };
        }

        this.handleChange = this.handleChange.bind(this);
 
        window.scrollTo(0, 0);
    }
    handleChange(ev) {
        switch (ev.target.id) {
            case "numLot" : this.setState({ data: { ...this.state.data, numLot: ev.target.value } }); break;
            case "service" : this.setState({ data: { ...this.state.data, service: ev.target.value } }); break;
            case "pharmacie" : this.setState({ data: { ...this.state.data, pharmacie: ev.target.value } }); break;
            default: break;
        }
    }

    onSubmit() {
        this.props.handler(this.state.data);
    }

    render() {
        
        const { data } = this.state;
        const { page } = this.props;
        const width = page.toString() + "%";
        return <>
            <p className="pageTitle">{'Médicament dérivé du sang ou vaccin'}</p>
            <div className="lineVectors">
                <hr className="lineVector" style={{width}}  />
                <hr className="lineVectorFull" />
            </div>
            <form className='inputForm'>
                <Grid>
                    <p className='robotoFont'>En cas d'administration de médicament(s) biologique(s) par exemple <b>médicament dérivé du sang ou vaccin</b>, indiquer leurs numéros de lot : </p>
                    <TextField fullWidth id="numLot" type="text" value={data.numLot} onChange={this.handleChange} label="Numéro de lot" />
                </Grid>
                <Grid>
                    <p className='robotoFont'>Service hospitalier dans lequel le produit a été administré : </p>
                    <TextField fullWidth id="service" type="text" value={data.service} onChange={this.handleChange} label="Service hospitalier" />
                </Grid>
                <Grid>
                    <p className='robotoFont'>Pharmacie qui a délivré le produit :</p>
                    <TextField fullWidth id="pharmacie" type="text" value={data.pharmacie} onChange={this.handleChange} label="Pharmacie" />
                </Grid>
            </form>
            
            <Box className="bottomBtnForm">
                <Button variant="contained" color="blue" onClick={() => this.onSubmit()}>{data.numLot === '' ? "PASSER" : "CONFIRMER"}</Button>
            </Box>        
        </>
    }
}
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Box, ThemeProvider } from '@mui/material';
import { Theme } from '../../styles/styles';
import { DeutschFlag, EnglishFlag, FrenchFlag, ItalianFlag, SpanishFlag, VigicareLogo, VigicareLogoBlack, VigicareLogoWhiteXL } from '../../styles/Icons';

import i18n from 'i18next';
import { withTranslation, Trans } from "react-i18next";  
import Popup from 'reactjs-popup';
import LanguagePopup from '../home/pages/Language';

export default class NotFound extends React.Component {
    constructor() {
        super();
        this.state = {
            displayLanguage: false,
        }
        this.handlerDisplay = this.handlerDisplay.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    handlerDisplay() {
        this.setState({ displayLanguage: !this.state.displayLanguage })
    }

    closePopup() { this.setState({ displayLanguage: false }); }

    displayFlag(lng) {
        switch (lng) {
            case "fr": return <FrenchFlag />;
            case "en": return <EnglishFlag />;
            case "sp": return <SpanishFlag />;
            case "de": return <DeutschFlag />;
            case "it": return <ItalianFlag />;
            default: return <FrenchFlag />;
        }
    }

    render() {
        const { displayLanguage } = this.state;
        return <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
            <header className='headerApp'>
                <div className='headerHome'>
                    <Link to='/'>
                        <VigicareLogo />
                    </Link>
                    <Button variant='contained' color='white' onClick={this.handlerDisplay} sx={{ margin:'0em 0em 0em 1em', paddingLeft: '-5px', paddingRight: '-5px', boxShadow: "none" }}>{this.displayFlag(i18n.language)}</Button>
                </div>
                    
            </header>
            <div className='mainHome'>
                <div>
                    <div className='accueilDisplayLogo'>
                        <VigicareLogoWhiteXL />
                        <VigicareLogoBlack />
                    </div>
                    
                    <p>La page que vous recherchez est introuvable.</p>
                </div>
                    {displayLanguage === true ? 
                        <Popup open={displayLanguage} position="center" onClose={() => this.closePopup()} contentStyle={{minWidth: '60%'}}>
                            <LanguagePopup handler={this.handlerDisplay} />
                        </Popup>
                    : <></>}

                    <Box className="bottomBtn">
                        <Link to='/' className='linkButton'>
                            <Button variant="contained" color="blue">{"Retour Accueil"}</Button>
                        </Link>
                        
                    </Box>
            </div>
        </Grid>
    </ThemeProvider>
    }
}